<!--
 * @Description: 平台交易页面
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-23 22:31:47
-->
<template>
  <!-- 大屏容器 开始 -->
  <div class="container"
       flex="dir:top box:first">
    <!-- 容器上部 开始 -->
    <div class="main-top"
         flex="dir:left box:mean">
      <MainTopLeft class="main-top-left"></MainTopLeft>
      <MainTopMain class="main-top-main"></MainTopMain>
      <MainTopRight class="main-top-right"></MainTopRight>
    </div>
    <!-- 容器上部 结束 -->
    <!-- 容器下部 开始 -->
    <div class="main-bottom"
         flex="dir:top box:last">
      <!-- 容器下上 开始 -->
      <div flex="box:justify"
           class="main-bottom-top">
        <div flex="dir:top box:last"
             class="main-bottom-top-left">
          <div flex="dir:top box:first"
               class="main-bottom-top-left-top">
            <div class="main-bottom-top-left-top-title title link"
                 @click="goUrl('http://shop.lztl.cn/')">商城/农资销售·</div>
            <MainBottomTopLeftTop class="main-bottom-top-left-top-main"></MainBottomTopLeftTop>
          </div>
          <div flex="dir:top box:first"
               class="main-bottom-top-left-bottom">
            <div class="main-bottom-top-left-bottom-title title link"
                 @click="goUrl('http://shop.lztl.cn/shop/index.php?act=search&op=index&cate_id=6276')">
              农机/农产品销售·</div>
            <MainBottomTopLeftBottom class="main-bottom-top-left-bottom-main"></MainBottomTopLeftBottom>
          </div>
        </div>
        <div flex="dir:top box:first"
             class="main-bottom-top-center">
          <div class="main-bottom-top-center-title title">重点农产品期货走势</div>
          <MainBottomTopCenterMain class="main-bottom-top-center-main"></MainBottomTopCenterMain>
        </div>
        <div flex="dir:top box:last"
             class="main-bottom-top-right">
          <div flex="dir:top box:first"
               class="main-bottom-top-right-top">
            <div class="main-bottom-top-right-top-title title">农、畜产品产量走势</div>
            <MainBottomTopRightTop class="main-bottom-top-right-top-main"></MainBottomTopRightTop>
          </div>
          <div flex="dir:top box:first"
               class="main-bottom-top-right-bottom">
            <div class="main-bottom-top-right-bottom-title title">农、畜产品价格走势</div>
            <MainBottomTopRightBottom class="main-bottom-top-right-bottom-main"></MainBottomTopRightBottom>
          </div>
        </div>
      </div>
      <!-- 容器下上 结束 -->
      <!-- 容器下下 开始 -->
      <div flex="box:justify"
           class="main-bottom-bottom">
        <div flex="dir:top box:first"
             class="main-bottom-bottom-left">
          <div class="main-bottom-bottom-left-title title">农资销售TOP3</div>
          <MainBottomBottomLeft class="main-bottom-bottom-left-main"></MainBottomBottomLeft>
        </div>
        <div flex="dir:left box:mean"
             class="main-bottom-bottom-center">
          <div flex="dir:top box:first"
               class="main-bottom-bottom-center-left">
            <div class="main-bottom-bottom-center-left-title title">农机服务销售TOP3</div>
            <MainBottomBottomCenterLeft class="main-bottom-bottom-center-left-main"></MainBottomBottomCenterLeft>
          </div>
          <div flex="dir:top box:first"
               class="main-bottom-bottom-center-right">
            <div class="main-bottom-bottom-center-right-title title">
              农产品销售TOP3</div>
            <MainBottomBottomCenterRight class="main-bottom-bottom-center-right-main"></MainBottomBottomCenterRight>
          </div>
        </div>
        <div flex="dir:top box:first"
             class="main-bottom-bottom-right">
          <div class="main-bottom-bottom-right-title title">保险销售TOP3</div>
          <MainBottomBottomRight class="main-bottom-bottom-right-main"></MainBottomBottomRight>
        </div>
      </div>
      <!-- 容器下下 结束 -->
    </div>
    <!-- 容器下部 结束 -->
  </div>
  <!-- 大屏容器 结束 -->
</template>

<script>
import MainTopLeft from './components/main-top-left'
import MainTopMain from './components/main-top-main'
import MainTopRight from './components/main-top-right'
import MainBottomTopLeftTop from './components/main-bottom-top-left-top'
import MainBottomTopLeftBottom from './components/main-bottom-top-left-bottom'
import MainBottomTopCenterMain from './components/main-bottom-top-center-main'
import MainBottomTopRightTop from './components/main-bottom-top-right-top'
import MainBottomTopRightBottom from './components/main-bottom-top-right-bottom'
import MainBottomBottomLeft from './components/main-bottom-bottom-left'
import MainBottomBottomCenterLeft from './components/main-bottom-bottom-center-left'
import MainBottomBottomCenterRight from './components/main-bottom-bottom-center-right'
import MainBottomBottomRight from './components/main-bottom-bottom-right'

export default {
  name: 'MainContainer1',
  props: {
    menuIndex: {
      default: 1,
      require: true
    }
  },
  components: {
    MainTopLeft,
    MainTopMain,
    MainTopRight,
    MainBottomTopLeftTop,
    MainBottomTopLeftBottom,
    MainBottomTopCenterMain,
    MainBottomTopRightTop,
    MainBottomTopRightBottom,
    MainBottomBottomLeft,
    MainBottomBottomCenterLeft,
    MainBottomBottomCenterRight,
    MainBottomBottomRight
  },
  data () {
    return {
    }
  },
  methods: {
    goUrl (url) {
      window.open(
        url,
        '_blank'
      )
    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
// 注册样式
@import "~@/assets/style/main-view31.scss";
</style>
