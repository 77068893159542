<!--
 * @Description: 土地利用中上
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-21 00:16:42
-->
<template>
  <!-- 地图区域 开始 -->
  <dv-border-box-13
    :color="['rgb(14,71,136)']"
    style="width: 1216px; padding: 15px"
  >
    <div
      id="kelMap"
      ref="kelMap"
      style="height: 100%; width: 100%; position: relative"
      class="map"
    ></div>
  </dv-border-box-13>
  <!-- 地图区域 结束 -->
</template>

<script>
import { _FindPlaceByDomain, _LoadPlaceWithInfo } from '@/api/mods/land'

export default {
  name: 'MainTop',
  data () {
    return {
      tMap: null,
      placeObj: [],
      threePlaces: null,
      objPage: {
        page: 0,
        size: 999999,
        domainCode: 'lztl-oper',
        name: '652928'
      }
    }
  },
  mounted () {
    /* eslint-disable no-undef */
    this.infoWin = new T.InfoWindow()
    this.initOverall()
    this.threeBand()
  },
  methods: {
    initOverall () {
      // 初始化地图
      /* eslint-disable no-undef */
      this.tMap = new T.Map('kelMap', {
        center: new T.LngLat(80.36, 40.63),
        zoom: 12
      })
      // this.tMap.setStyle('indigo')
      this.tMap.setMapType(TMAP_HYBRID_MAP)
      document.getElementsByClassName('tdt-control-copyright tdt-control')[0].style.display = 'none'
      this.threeBand()
    },
    // 获取地块信息并添加到地图上
    threeBand () {
      this.placeObj = []
      _FindPlaceByDomain(this.objPage).then(res => {
        this.threePlaces = res.data.content
        for (const p in this.threePlaces) {
          const places = []
          for (const i in this.threePlaces[p].geoInfo) {
            places.push(new T.LngLat(this.threePlaces[p].geoInfo[i].lng, this.threePlaces[p].geoInfo[i].lat))
          }
          let pg = null
          pg = new T.Polygon(places, {
            color: '#2DBBB6',
            weight: 1.2,
            opacity: 0.8,
            fillColor: '#218784',
            fillOpacity: 0.3,
            lineStyle: 'solid'
          })
          this.placeObj.push(pg)
          this.tMap.addOverLay(pg)
          this.bdMarkerInfo(pg, this.threePlaces[p]) // 区域对象点击事件
        }
      })
    },
    // 添加地块说明
    bdMarkerInfo (pg, place) {
      const _this = this
      pg.addEventListener('click', function () {
        _LoadPlaceWithInfo({ code: place.code }).then(res => {
          const pl = res.data
          let cs = '<div style="font-size: 15px">名称：' + pl.name + '</div>'
          cs += '<div style="font-size: 15px">编号：' + pl.code + '</div>'
          cs += '<div style="font-size: 15px">联系电话：' + place.ownerTel + '</div>'
          cs += '<div style="font-size: 15px">面积：' + pl.areaSquareMu + '</div>'
          cs += '<div style="font-size: 15px">地址：' + pl.areaName + '</div>'
          _this.infoWin.setContent(cs)
          pg.openInfoWindow(_this.infoWin)
        })
      })
    },
    oncheck (e) {
      this.tMap.clearOverLays()
      if (e === 'gbznt') {
        this.threeBand()
        this.tMap.panTo(new T.LngLat(80.36, 40.63), [11])
      }
      if (e === 'mhfb') {
        const bd = new T.LngLatBounds(new T.LngLat(79.15525, 40.01081), new T.LngLat(80.99437, 40.92571))
        const fbimg = new T.ImageOverlay('/image/main-view1/cottonDistri.png', bd, {
          opacity: 1,
          alt: ''
        })
        this.tMap.addOverLay(fbimg)
        this.tMap.panTo(new T.LngLat(80.36, 40.63), [10])
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
