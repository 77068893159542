/*
 * @Description: 中上 大鹏点位数据
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
export default {
  valveLnglat: [ // 闸门点位
    { lng: 80.39986, lat: 40.63508, name: '一体化远程控制闸门', code: 'ZM0001', group: 'krl_zham', groupName: '闸门', valveShape: '板闸', valveHight: '600', valveType: '自动化闸门', valveUse: '节制闸', holeCount: '1', rateOfFlow: '0', rateOfFlowMax: '0' },
    { lng: 80.32016, lat: 40.60546, name: '一体化远程控制闸门', code: 'ZM0002', group: 'krl_zham', groupName: '闸门', valveShape: '板闸', valveHight: '600', valveType: '自动化闸门', valveUse: '节制闸', holeCount: '1', rateOfFlow: '0', rateOfFlowMax: '0' },
    { lng: 80.35557, lat: 40.63354, name: '一体化远程控制闸门', code: 'ZM0003', group: 'krl_zham', groupName: '闸门', valveShape: '板闸', valveHight: '600', valveType: '自动化闸门', valveUse: '节制闸', holeCount: '1', rateOfFlow: '0', rateOfFlowMax: '0' },
    { lng: 80.33612, lat: 40.63483, name: '一体化远程控制闸门', code: 'ZM0004', group: 'krl_zham', groupName: '闸门', valveShape: '板闸', valveHight: '600', valveType: '自动化闸门', valveUse: '节制闸', holeCount: '1', rateOfFlow: '0', rateOfFlowMax: '0' },
    { lng: 80.34496, lat: 40.6342, name: '一体化远程控制闸门', code: 'ZM0005', group: 'krl_zham', groupName: '闸门', valveShape: '板闸', valveHight: '600', valveType: '自动化闸门', valveUse: '节制闸', holeCount: '1', rateOfFlow: '0', rateOfFlowMax: '0' },
    { lng: 80.35251, lat: 40.61777, name: '一体化远程控制闸门', code: 'ZM0006', group: 'krl_zham', groupName: '闸门', valveShape: '板闸', valveHight: '600', valveType: '自动化闸门', valveUse: '节制闸', holeCount: '1', rateOfFlow: '0', rateOfFlowMax: '0' },
    { lng: 80.31547, lat: 40.63212, name: '一体化远程控制闸门', code: 'ZM0007', group: 'krl_zham', groupName: '闸门', valveShape: '板闸', valveHight: '600', valveType: '自动化闸门', valveUse: '节制闸', holeCount: '1', rateOfFlow: '0', rateOfFlowMax: '0' },
    { lng: 80.35144, lat: 40.61918, name: '一体化远程控制闸门', code: 'ZM0008', group: 'krl_zham', groupName: '闸门', valveShape: '板闸', valveHight: '600', valveType: '自动化闸门', valveUse: '节制闸', holeCount: '1', rateOfFlow: '0', rateOfFlowMax: '0' },
    { lng: 80.32921, lat: 40.60714, name: '一体化远程控制闸门', code: 'ZM0009', group: 'krl_zham', groupName: '闸门', valveShape: '板闸', valveHight: '600', valveType: '自动化闸门', valveUse: '节制闸', holeCount: '1', rateOfFlow: '0', rateOfFlowMax: '0' },
    { lng: 80.41912, lat: 40.63763, name: '一体化远程控制闸门', code: 'ZM00010', group: 'krl_zham', groupName: '闸门', valveShape: '板闸', valveHight: '600', valveType: '自动化闸门', valveUse: '节制闸', holeCount: '1', rateOfFlow: '0', rateOfFlowMax: '0' }
  ],
  meteorological: [ // 气象站点位
    { lng: 80.35578, lat: 40.60201, name: '固定式气象站', code: 'QX0001', group: 'krl_qix', groupName: '气象站', temperature: '14', humidity: '86', kPa: '905' },
    { lng: 80.37985, lat: 40.65802, name: '固定式气象站', code: 'QX0002', group: 'krl_qix', groupName: '气象站', temperature: '14', humidity: '86', kPa: '905' },
    { lng: 80.28532, lat: 40.60342, name: '固定式气象站', code: 'QX0003', group: 'krl_qix', groupName: '气象站', temperature: '14', humidity: '86', kPa: '905' },
    { lng: 80.35556, lat: 40.61906, name: '固定式气象站', code: 'QX0004', group: 'krl_qix', groupName: '气象站', temperature: '14', humidity: '86', kPa: '905' },
    { lng: 80.35642, lat: 40.6311, name: '固定式气象站', code: 'QX0005', group: 'krl_qix', groupName: '气象站', temperature: '14', humidity: '86', kPa: '905' },
    { lng: 80.27943, lat: 40.65025, name: '固定式气象站', code: 'QX0006', group: 'krl_qix', groupName: '气象站', temperature: '14', humidity: '86', kPa: '905' },
    { lng: 80.33582, lat: 40.61521, name: '固定式气象站', code: 'QX0007', group: 'krl_qix', groupName: '气象站', temperature: '14', humidity: '86', kPa: '905' },
    { lng: 80.37977, lat: 40.63071, name: '固定式气象站', code: 'QX0008', group: 'krl_qix', groupName: '气象站', temperature: '14', humidity: '86', kPa: '905' },
    { lng: 80.30123, lat: 40.64572, name: '固定式气象站', code: 'QX0009', group: 'krl_qix', groupName: '气象站', temperature: '14', humidity: '86', kPa: '905' },
    { lng: 80.37479, lat: 40.60368, name: '固定式气象站', code: 'QX00010', group: 'krl_qix', groupName: '气象站', temperature: '14', humidity: '86', kPa: '905' }
  ],
  voyagesLnglat: [ // 水肥机点位
    { lng: 80.30896, lat: 40.61814, name: '一体化远程控制闸门', code: 'QX0001', group: 'krl_sfj', groupName: '水肥机' },
    { lng: 80.32776, lat: 40.63725, name: '一体化远程控制闸门', code: 'QX0002', group: 'krl_sfj', groupName: '水肥机' },
    { lng: 80.29205, lat: 40.61675, name: '一体化远程控制闸门', code: 'QX0003', group: 'krl_sfj', groupName: '水肥机' },
    { lng: 80.32938, lat: 40.65304, name: '一体化远程控制闸门', code: 'QX0004', group: 'krl_sfj', groupName: '水肥机' },
    { lng: 80.40166, lat: 40.63637, name: '一体化远程控制闸门', code: 'QX0005', group: 'krl_sfj', groupName: '水肥机' },
    { lng: 80.44603, lat: 40.63802, name: '一体化远程控制闸门', code: 'QX0006', group: 'krl_sfj', groupName: '水肥机' },
    { lng: 80.34826, lat: 40.64932, name: '一体化远程控制闸门', code: 'QX0001', group: 'krl_sfj', groupName: '水肥机' },
    { lng: 80.29118, lat: 40.65739, name: '一体化远程控制闸门', code: 'QX0008', group: 'krl_sfj', groupName: '水肥机' },
    { lng: 80.33806, lat: 40.61496, name: '一体化远程控制闸门', code: 'QX0009', group: 'krl_sfj', groupName: '水肥机' },
    { lng: 80.39165, lat: 40.63943, name: '一体化远程控制闸门', code: 'QX00010', group: 'krl_sfj', groupName: '水肥机' }
  ],
  vegetLnglat: [ // 大棚点位
    { lng: 80.27254, lat: 40.63365, name: '阿瓦提乡', category: '油麦菜、毛白菜等', mu: '5-10亩' },
    { lng: 80.28943, lat: 40.6343, name: '英下乡大棚1', category: '辣椒、黄瓜、油麦菜等', mu: '5-10亩' },
    { lng: 80.34591, lat: 40.610529, name: '英下乡大棚2', category: '辣椒、韭菜、茼蒿等', mu: '5-10亩' },
    { lng: 80.33248, lat: 40.630751, name: '恰尔巴格乡大棚1', category: '辣椒、黄瓜、油麦菜', mu: '5-10亩' },
    { lng: 80.42462, lat: 40.6508, name: '恰尔巴格乡大棚2', category: '韭菜、茼蒿', mu: '5-10亩' },
    { lng: 80.306491, lat: 40.62112, name: '阿克东村大棚1', category: '油麦菜、菠菜', mu: '5-10亩' },
    { lng: 80.38718, lat: 40.64988, name: '阿克东村大棚2', category: '菠菜、毛白菜', mu: '5-10亩' }
  ]
}
