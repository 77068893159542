/*
 * @Description: 中左 轮播表配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
export default {
  topData: [
    ['', '机手/位', '农机/辆', '合作社/个', '农机合作社/个', '农机补贴/万']
  ],
  data: [
    ['2016', '9756', '14201', '423', '7', '1200'],
    ['2017', '9800', '15795', '585', '19', '1400'],
    ['2018', '10050', '16889', '645', '23', '1000'],
    ['2019', '10419', '17568', '640', '34', '1500'],
    ['2020', '15006', '15443', '612', '54', '4500'],
    ['2021', '15469', '15629', '616', '64', '2449.89'],
    ['2022', '15938', '15939', '617', '69', '1990']
  ],

  columnWidth: ['100', '120', '120', '150', '200', '190']
}
