export default {
  sourceList: [{
    number: [312500, 93750],
    content: '{nt} 亩 {nt} 万吨',
    style: {
      fontSize: 24
    }
  }, {
    number: [100000, 10],
    content: '{nt} 亩 {nt} 万吨',
    style: {
      fontSize: 24
    }
  }]
}
