/*
 * @Description: 左上 圆环图配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2023-01-09 16:58:40
 */
// 顶部信息[年份 棉花种植 林果 粮食 特种]
export const optionData = [
  [2018, 1520400, 153800, 310980, 28753],
  [2019, 1580000, 163600, 308290, 29000],
  [2020, 1470100, 234890, 326540, 29639],
  [2021, 1420600, 262600, 428346, 30159],
  [2022, 1450300, 356820, 571590, 31500]
]
// optionConfig1、optionConfig2、optionConfig3、optionConfig4 默认填写第一年数据展示
export const optionConfig1 = {
  title: {
    text: '棉花种植',
    subtext: optionData[0][1],
    x: 'center',
    y: 50,
    itemGap: 10,
    textStyle: {
      color: '#B7E1FF',
      fontWeight: 'normal',
      fontFamily: '微软雅黑',
      fontSize: 18
    },
    subtextStyle: {
      color: '#B7E1FF',
      fontWeight: 'bolder',
      fontSize: 18,
      fontFamily: '微软雅黑'
    }
  },
  series: [{
    type: 'pie',
    center: ['50%', '50%'],
    radius: [40, 50],
    x: '0%',
    tooltip: {
      show: false
    },
    data: [{
      value: optionData[0][1],
      itemStyle: {
        color: 'rgba(0,153,255,0.8)'
      },
      hoverAnimation: false,
      label: {
        show: false,
        position: 'center',
        textStyle: {
          fontFamily: '微软雅黑',
          fontWeight: 'bolder',
          color: '#B7E1FF',
          fontSize: 18
        }
      },
      labelLine: {
        show: false
      }
    },
    // 控制进度百分率 data[0].value + data[1].value = 总量
    {
      value: 1500000 - optionData[0][1],
      itemStyle: {
        color: 'rgba(0,153,255,0.1)'
      },
      hoverAnimation: false,
      label: {
        show: false,
        position: 'center',
        padding: 20
      },
      labelLine: {
        show: false
      }
    }
    ]
  },
  {
    type: 'pie',
    center: ['50%', '50%'],
    radius: [55, 60],
    x: '0%',
    hoverAnimation: false,
    data: [{
      value: 100,
      itemStyle: {
        color: 'rgba(0,153,255,0.3)'
      },
      label: {
        show: false
      },
      labelLine: {
        show: false
      }
    }]
  }
  ]
}
export const optionConfig2 = {
  title: {
    text: '林果种植',
    subtext: optionData[0][2],
    x: 'center',
    y: 50,
    itemGap: 10,
    textStyle: {
      color: '#B7E1FF',
      fontWeight: 'normal',
      fontFamily: '微软雅黑',
      fontSize: 18
    },
    subtextStyle: {
      color: '#B7E1FF',
      fontWeight: 'bolder',
      fontSize: 18,
      fontFamily: '微软雅黑'
    }
  },
  series: [{
    type: 'pie',
    center: ['50%', '50%'],
    radius: [40, 50],
    x: '0%',
    tooltip: {
      show: false
    },
    data: [{
      value: optionData[0][2],
      itemStyle: {
        color: 'rgba(0,153,255,0.8)'
      },
      hoverAnimation: false,
      label: {
        show: false,
        position: 'center'
      },
      labelLine: {
        show: false
      }
    },
    {
      value: 1500000 - optionData[0][2],
      itemStyle: {
        color: 'rgba(0,153,255,0.1)'
      },
      hoverAnimation: false,
      label: {
        show: false,
        position: 'center',
        padding: 20,
        textStyle: {
          fontFamily: '微软雅黑',
          fontSize: 18,
          color: '#B7E1FF'
        }
      },
      labelLine: {
        show: false
      }
    }
    ]
  },
  {
    type: 'pie',
    center: ['50%', '50%'],
    radius: [55, 60],
    x: '0%',
    hoverAnimation: false,
    data: [{
      value: 100,
      itemStyle: {
        color: 'rgba(0,153,255,0.3)'
      },
      label: {
        show: false
      },
      labelLine: {
        show: false
      }
    }]
  }
  ]
}

export const optionConfig3 = {
  title: {
    text: '粮食种植',
    subtext: optionData[0][3],
    x: 'center',
    y: 50,
    itemGap: 10,
    textStyle: {
      color: '#B7E1FF',
      fontWeight: 'normal',
      fontFamily: '微软雅黑',
      fontSize: 18
    },
    subtextStyle: {
      color: '#B7E1FF',
      fontWeight: 'bolder',
      fontSize: 18,
      fontFamily: '微软雅黑'
    }
  },
  series: [{
    type: 'pie',
    center: ['50%', '50%'],
    radius: [40, 50],
    x: '0%',
    tooltip: {
      show: false
    },
    data: [{
      value: optionData[0][3],
      itemStyle: {
        color: 'rgba(0,153,255,0.8)'
      },
      hoverAnimation: false,
      label: {
        show: false,
        position: 'center',
        textStyle: {
          fontFamily: '微软雅黑',
          fontWeight: 'bolder',
          color: '#B7E1FF',
          fontSize: 18
        }
      },
      labelLine: {
        show: false
      }
    },
    {
      value: 200000 - optionData[0][3],
      itemStyle: {
        color: 'rgba(0,153,255,0.1)'
      },
      hoverAnimation: false,
      label: {
        show: false,
        position: 'center',
        padding: 20,
        textStyle: {
          fontFamily: '微软雅黑',
          fontSize: 18,
          color: '#B7E1FF'
        }
      },
      labelLine: {
        show: false
      }
    }
    ]
  },
  {
    type: 'pie',
    center: ['50%', '50%'],
    radius: [55, 60],
    x: '0%',
    hoverAnimation: false,
    data: [{
      value: 100,
      itemStyle: {
        color: 'rgba(0,153,255,0.3)'
      },
      label: {
        show: false
      },
      labelLine: {
        show: false
      }
    }]
  }
  ]
}
export const optionConfig4 = {
  title: {
    text: '特色种植',
    subtext: optionData[0][4],
    x: 'center',
    y: 50,
    itemGap: 10,
    textStyle: {
      color: '#B7E1FF',
      fontWeight: 'normal',
      fontFamily: '微软雅黑',
      fontSize: 18
    },
    subtextStyle: {
      color: '#B7E1FF',
      fontWeight: 'bolder',
      fontSize: 18,
      fontFamily: '微软雅黑'
    }
  },
  series: [{
    type: 'pie',
    center: ['50%', '50%'],
    radius: [40, 50],
    x: '0%',
    tooltip: {
      show: false
    },
    data: [{
      value: optionData[0][4],
      itemStyle: {
        color: 'rgba(0,153,255,0.8)'
      },
      hoverAnimation: false,
      label: {
        show: false,
        position: 'center',
        textStyle: {
          fontFamily: '微软雅黑',
          fontWeight: 'bolder',
          color: '#B7E1FF',
          fontSize: 18
        }
      },
      labelLine: {
        show: false
      }
    },
    {
      value: 200000 - optionData[0][4],
      itemStyle: {
        color: 'rgba(0,153,255,0.1)'
      },
      hoverAnimation: false,
      label: {
        show: false,
        position: 'center',
        padding: 20,
        textStyle: {
          fontFamily: '微软雅黑',
          fontSize: 18,
          color: '#B7E1FF'
        }
      },
      labelLine: {
        show: false
      }
    }
    ]
  },
  {
    type: 'pie',
    center: ['50%', '50%'],
    radius: [55, 60],
    x: '0%',
    hoverAnimation: false,
    data: [{
      value: 100,
      itemStyle: {
        color: 'rgba(0,153,255,0.3)'
      },
      label: {
        show: false
      },
      labelLine: {
        show: false
      }
    }]
  }
  ]
}
export default {
  optionData,
  optionConfig1,
  optionConfig2,
  optionConfig3,
  optionConfig4
}
