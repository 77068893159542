/*
 * @Description: 中上 描述数据
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
export const optionData = {
  currentDescription: '阿瓦提县是全国优质棉生产基地、著名的中国棉城、中国长绒棉之乡，粮食、水果基地。行政区域面积13018平方千米，辖6镇2乡3个农林牧场，在全地区率先建设符合县域实际的农业生产、经营、管理服务全过程"智慧农业"平台，加快农业现代化步伐，推动现代农业高质量发展。',
  descriptionList: [
    '阿瓦提县是全国优质棉生产基地、著名的中国棉城、中国长绒棉之乡，粮食、水果基地。行政区域面积13018平方千米，辖6镇2乡3个农林牧场，在全地区率先建设符合县域实际的农业生产、经营、管理服务全过程"智慧农业"平台，加快农业现代化步伐，推动现代农业高质量发展。'
  ]
}

export default {
  optionData
}
