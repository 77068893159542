<!--
 * @Description: 侧右下
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-23 19:04:05
-->
<template>
  <div flex="dir:left">
    <!-- 左侧圆环图标签 开始 -->
    <div flex="dir:top box:mean" class="cutline">
      <div flex="dir:left" class="chart-tip">
        <span>棉花</span>
        <div style="background-color: rgb(0, 149, 255)"></div>
      </div>
      <div flex="dir:left" class="chart-tip">
        <span>香梨</span>
        <div style="background-color: rgb(246, 0, 234)"></div>
      </div>
      <div flex="dir:left" class="chart-tip">
        <span>肉类</span>
        <div style="background-color: rgb(255, 221, 0)"></div>
      </div>
    </div>
    <!-- 左侧圆环图标签 结束 -->
    <!-- 圆环图 开始 -->
    <dv-active-ring-chart :config="option.config" class="chart-annulus"/>
    <!-- 圆环图 结束 -->
  </div>
</template>

<script>
import option from './option'

export default {
  name: 'AsideRightBottom',
  data () {
    return {
      option
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.cutline{
  width: 25%; height: 50%;margin-top: 0;
}
.chart-tip div {
  width: 36px;
  height: 18px;
  margin: 3.5px;
}
.chart-tip span {
  width: 40%;
}
.chart-annulus{
  width: 75%; height: 100%;margin-top: 0;margin-right: 5%;
}
</style>
