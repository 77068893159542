<!--
 * @Description: 数据一张图
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-23 22:28:46
-->
<template>
  <!-- 大屏容器 开始 -->
  <div class="container"
       flex="box:justify">
    <!-- 大屏左侧 开始 -->
    <div class="aside-left"
         flex="dir:top box:last"
         flex-box="0">
      <!-- 大屏左上 开始 -->
      <div class="aside-left-top">
        <div class="aside-left-top-container"
             flex="dir:top box:first">
          <div class="aside-left-top-container-title title">农业种植数据</div>
          <div class="aside-left-top-container-top">
            <aside-left-top-top></aside-left-top-top>
          </div>
          <div class="aside-left-top-container-bottom">
            <aside-left-top-bottom></aside-left-top-bottom>
          </div>
        </div>
      </div>
      <!-- 大屏左上 结束 -->
      <!-- 大屏左下 开始 -->
      <div class="aside-left-bottom">
        <div class="aside-left-bottom-container"
             flex="dir:top box:first">
          <div class="aside-left-bottom-container-title title">
            林果种植数据
          </div>
          <div class="aside-left-bottom-container-main">
            <aside-left-bottom></aside-left-bottom>
          </div>
        </div>
      </div>
      <!-- 大屏左下 结束 -->
    </div>
    <!-- 大屏左侧 结束 -->
    <!-- 大屏主体 开始 -->
    <div class="main"
         flex="dir:top box:last"
         flex-box="10">
      <keep-alive>
        <component :is="currentMainView"></component>
      </keep-alive>
    </div>
    <!-- 大屏主体 结束 -->
    <!-- 大屏右侧 开始 -->
    <div class="aside-right"
         flex="dir:top box:last"
         flex-box="0">
      <!-- 大屏右上 开始 -->
      <div class="aside-right-top">
        <div class="aside-right-top-container"
             flex="dir:top box:first">
          <div class="aside-right-top-container-title title">畜牧存栏统计
          </div>
          <div class="aside-right-top-container-top">
            <aside-right-top-top></aside-right-top-top>
          </div>
          <div class="aside-right-top-container-bottom">
            <aside-right-top-bottom></aside-right-top-bottom>
          </div>
        </div>
      </div>
      <!-- 大屏右上 结束 -->
      <!-- 大屏右下 开始 -->
      <div class="aside-right-bottom">
        <div class="aside-right-bottom-container"
             flex="dir:top box:first">
          <div class="aside-right-bottom-container-title title">农机补贴汇总</div>
          <div class="aside-right-bottom-container-main">
            <aside-right-bottom></aside-right-bottom>
          </div>
        </div>
      </div>
      <!-- 大屏右下 结束 -->
    </div>
    <!-- 大屏右侧 结束 -->
  </div>
  <!-- 大屏容器 结束 -->
</template>

<script>
import MainContainer1 from './components/main-container1/index.vue'
import MainContainer2 from './components/main-container2/index.vue'
import MainContainer3 from './components/main-container3/index.vue'
import MainContainer4 from './components/main-container4/index.vue'
import MainContainer5 from './components/main-container5/index.vue'
import MainContainer6 from './components/main-container6/index.vue'
import AsideLeftTopTop from './components/aside-left-top-top/index.vue'
import AsideLeftTopBottom from './components/aside-left-top-bottom/index.vue'
import AsideLeftBottom from './components/aside-left-bottom/index.vue'
import AsideRightTopTop from './components/aside-right-top-top/index.vue'
import AsideRightTopBottom from './components/aside-right-top-bottom/index.vue'
import AsideRightBottom from './components/aside-right-bottom/index.vue'

export default {
  name: 'MainView1',
  props: {
    menuIndex: {
      default: 1,
      require: true
    }
  },
  components: {
    AsideLeftTopTop,
    AsideLeftTopBottom,
    AsideLeftBottom,
    AsideRightTopTop,
    AsideRightTopBottom,
    AsideRightBottom,
    MainContainer1,
    MainContainer2,
    MainContainer3,
    MainContainer4,
    MainContainer5,
    MainContainer6
  },
  data () {
    return {
      currentMainView: 'MainContainer1',
      showMindMap: false
    }
  },
  methods: {
    /**
     * goUrl
     * @description 跳转地址
     */
    goUrl () {
      window.open(
        'http://kel.kenyte.com/data-view/cropGrowth',
        '_blank'
      )
    }
  },
  watch: {
    /**
     * menuIndex
     * @description 监听父组件传递的数据
     */
    menuIndex (val) { // 表单状态
      this.currentMainView = 'MainContainer' + val
    }
  }
}
</script>

<style lang="scss"  scoped>
// 注册样式
@import "~@/assets/style/main-view1.scss";
</style>
