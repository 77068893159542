<!--
 * @Description: 数据一张图右上上
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-15 13:19:38
-->
<template>
  <!-- 轮播表 开始 -->
  <div class="chart">
    <dv-scroll-board :config="option.configTop" class="scroll-board" />
    <dv-scroll-board :config="option.config" class="scroll-board-config" />
    <dv-scroll-board :config="option.configNum" class="scroll-board" />
  </div>
  <!-- 轮播表 结束 -->
</template>

<script>
import option from './option'
export default {
  name: 'AsideRightTopTop',
  data () {
    return {
      option
    }
  },
  mounted () {
    this.tableData()
  },
  methods: {
    /**
     * tableData
     * @description 轮播表样式设置
     */
    tableData () {
      this.option.configTop = {
        data: this.dataUtil(this.option.topData),
        rowNum: 1,
        evenRowBGC: ';border:1px solid #069;', // 偶数行背景色
        columnWidth: [83, 244, 244] // 列宽
      }
      this.option.config = {
        data: this.dataUtil(this.option.data),
        rowNum: 3,
        oddRowBGC: ';border:1px solid #069;', // 奇数行背景色
        evenRowBGC: ';border:1px solid #069;', // 偶数行背景色
        columnWidth: [83, 244, 244] // 列宽
      }
      this.option.configNum = {
        header: this.option.header,
        headerHeight: 50,
        align: [1, 1, 1],
        headerBGC: ';border:1px solid #069',
        columnWidth: [83, 244, 244] // 列宽
      }
    },

    /**
     * dataUtil
     * @description 轮播表数据填充加样式
     */
    dataUtil (dataInfo) {
      const tableData = []
      dataInfo.forEach(data => { // lines是边界经纬度组成的数组
        tableData.push([
          '<span style="font-size: 18px;color: #B7E2FF;" >' + data[0] + '</span>',
          '<span style="font-size: 18px;color: #B7E2FF;" >' + data[1] + '</span>',
          '<span style="font-size: 18px;color: #B7E2FF;" >' + data[2] + '</span>'
        ])
      })
      return tableData
    }
  }
}
</script>

<style scoped lang="scss">
.scroll-board{
  width: 100%; height: 41px
}
.scroll-board-config{
  width: 100%; height: 60%
}
.chart {
  width: 99%;
  height: 100%;
  margin-right: 1%
}
</style>
