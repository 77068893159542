/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2023-01-09 11:54:09
 * @LastEditors: Pancras
 * @LastEditTime: 2023-01-09 17:10:04
 */
export default {
  topData: [
    ['监测时间', '监测作物', '监测项目']
  ],
  data: [
    ['2022.1.23', '棉花', '土壤墒情'],
    ['2022.2.23', '棉花', '虫情监测'],
    ['2022.3.23', '香梨', '气象监测'],
    ['2022.4.23', '香梨', '土壤墒情'],
    ['2022.5.23', '香梨', '虫情监测'],
    ['2022.6.23', '棉花', '气象监测'],
    ['2022.7.23', '棉花', '土壤墒情']
  ],
  header: ['<span style="font-size: 18px;color: #B7E2FF;" >合计</span>',
    '<span style="font-size: 18px;color: #B7E2FF;" >445316</span>',
    '<span style="font-size: 18px;color: #B7E2FF;" >428441</span>'],
  configTop: null,
  config: null,
  configNum: null
}
