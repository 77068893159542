<!--
 * @Description: 畜牧养殖中上
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-17 17:04:38
-->
<template>
  <!-- 地图 开始 -->
  <dv-border-box-13
    :color="['rgb(14,71,136)']"
    style="width: 1216px; padding: 15px"
  >
    <div
      id="kelMap"
      ref="kelMap"
      style="height: 100%; width: 100%; position: relative"
      class="gray"
    ></div>
  </dv-border-box-13>
  <!-- 地图 结束 -->
</template>

<script>
import option from './option'
export default {
  name: 'MainTop',
  data () {
    return {
      option,
      iconUrl: null
    }
  },
  mounted () {
    // 初始化点位提示信息窗口
    /* eslint-disable no-undef */
    this.infoWin = new T.InfoWindow()
    // 初始化地图
    this.initMap()
  },
  methods: {
    /**
     * initMap
     * @description 初始化地图
     */
    initMap () {
      /* eslint-disable no-undef */
      this.tMap = new T.Map('kelMap', {
        center: new T.LngLat(80.37, 40.53),
        zoom: 10
      })
      this.tMap.setMapType(TMAP_HYBRID_MAP)
      // 移除天地图logo
      document.getElementsByClassName('tdt-control-copyright tdt-control')[0].style.display = 'none'
      // 执行画多点方法
      this.doDrawPoints()
    },

    /**
     * doDrawPoints
     * @description 画多点方法
     */
    doDrawPoints () {
      // 循环点位信息
      for (const animal in option.animalList) {
        if (option.animalList[animal].series === '羊') {
          this.iconUrl = '/image/main-container14/sheep.png'
        } else if (option.animalList[animal].series === '鸡') {
          this.iconUrl = '/image/main-container14/chicken.png'
        } else if (option.animalList[animal].series === '牛') {
          this.iconUrl = '/image/main-container14/cow.png'
        } else if (option.animalList[animal].series === '水产养殖') {
          this.iconUrl = '/image/main-container14/fish.png'
        } else if (option.animalList[animal].series === '猪') {
          this.iconUrl = '/image/main-container14/pig.png'
        } else if (option.animalList[animal].series === '鹅') {
          this.iconUrl = '/image/main-container14/goose.png'
        } else {
          this.iconUrl = '/image/main-container14/others.png'
        }
        // 创建点位提示信息
        this.marker = new T.Marker(
          new T.LngLat(option.animalList[animal].lng, option.animalList[animal].lat),
          {
            icon: new T.Icon({
              iconUrl: this.iconUrl,
              iconSize: new T.Point(43, 45)
            })
          }
        )
        // 地图添加点位提示弹窗
        this.tMap.addOverLay(this.marker)
        this.MarkerAddInfo(this.marker, option.animalList[animal])
      }
    },
    // 设置点位信息弹窗内容
    MarkerAddInfo (mk, animal) {
      const _this = this
      // 信息弹窗添加点击监听，并设置弹窗内容
      mk.addEventListener('click', function () {
        let content = '<div style="font-size: 18px">组织机构：' + animal.name + '</div>'
        content += '<div style="font-size: 18px">畜别：' + animal.series + '</div>'
        content += '<div style="font-size: 18px">手机号码：' + animal.phoneNum + '</div>'
        content += '<div style="font-size: 18px">地址：' + animal.address + '</div>'
        _this.infoWin.setContent(content)
        // 打开弹窗
        mk.openInfoWindow(_this.infoWin)
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
