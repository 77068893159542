/*
 * @Description: 中下左 折线图配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
const differenceData = []
const visibityData = []
const xAxisData = ['2016年', '2017年', '2018年', '2019年', '2020年', '2021年']

const projectProfitData = [16, 15, 56, 42, 80, 99]
const patentProfitData = [20, 23, 53, 36, 45, 55]

for (var i = 0; i < projectProfitData.length; i++) {
  differenceData.push(projectProfitData[i] - patentProfitData[i])
  visibityData.push(patentProfitData[i])
}

export default {
  // 添加折线图动态加载
  animationDuration: 10000,
  title: { show: false },
  tooltip: {
    trigger: 'axis',
    backgroundColor: 'rgba(255,255,255,0.2)',
    // 自定义显示
    formatter: function (params) {
      return params[0].name + '<br/>' +
        params[0].seriesName + ' : ' + params[0].value + '<br/>' +
        params[1].seriesName + ' : ' + params[1].value + '<br/>' +
        params[2].seriesName + ' : ' + params[2].value + '<br/>' +
        params[3].seriesName + ' : ' + params[3].value + '<br/>' +
        '<br/>'
    },
    textStyle: {
      color: '#FFF',
      fontSize: 18
    }
  },
  toolbox: { show: false },
  legend: {
    top: 'top',
    textStyle: {
      color: '#B7E2FF',
      fontSize: 18,
      fontFamily: '微软雅黑'
    },
    data: ['项目收益', '专利收益']
  },
  grid: {
    top: '20%',
    left: '10%',
    right: '5%',
    bottom: '10%'
  },
  xAxis: {
    max: 5,
    data: xAxisData,
    axisLabel: {
      interval: 0,
      textStyle: { // X轴文字
        color: '#B7E1FF',
        fontSize: 18
      }
    },
    axisLine: { // X轴线
      lineStyle: {
        color: '#09F'
      }
    },
    axisTick: {
      lineStyle: {
        color: '#09F'
      }
    }
  },
  yAxis: {
    name: '万元',
    nameTextStyle: {
      padding: [0, 0, 0, -45], // 四个数字分别为上右下左与原位置距离
      fontSize: 13.5,
      color: '#B7E1FF'
    },
    inverse: false,
    splitArea: { show: false }, // Y轴横向背景
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: {
      textStyle: { // Y轴字体
        color: '#B7E1FF',
        fontSize: 18,
        fontFamily: 'Arial'
      }
    },
    splitLine: { // Y轴横向轴线
      lineStyle: {
        color: '#09F'
      }
    }
  },
  series: [ // 数据
    {
      name: '项目收益',
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 7.5,
      showAllSymbol: true,
      color: 'blue',
      data: projectProfitData
    },
    {
      name: '专利收益',
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 7.5,
      showAllSymbol: true,
      color: '#F90',
      itemStyle: {
        normal: {
          lineStyle: {
            width: 2
          }
        }
      },
      data: patentProfitData
    },
    // 线隐藏后设置不可见线 不会因缺少数据变动
    {
      name: '不可见',
      type: 'bar',
      stack: '1',
      barWidth: 1,
      itemStyle: {
        normal: {
          color: 'rgba(0,0,0,0)'
        },
        emphasis: {
          color: 'rgba(0,0,0,0)'
        }
      },
      data: visibityData
    },
    // 两线连接线
    {
      name: '变化',
      type: 'bar',
      stack: '1',
      barWidth: 1,
      color: '#B7E1FF',
      data: differenceData
    }
  ]
}
