<!--
 * @Description: 土地利用中右
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-16 19:51:46
-->
<template>
  <!-- 排名表 开始 -->
  <dv-scroll-ranking-board :config="option"/>
  <!-- 排名表 结束 -->
</template>

<script>
import option from './option'
export default {
  name: 'MainRight',
  data () {
    return {
      option
    }
  },
  mounted () {
  },
  methods: {
  }
}

</script>
<style scoped lang="scss">

</style>
<style>
.dv-scroll-ranking-board .ranking-info .rank {
  width: 30px;
  font-size: 18px;
  color: #B7E2FF;
}
.dv-scroll-ranking-board .ranking-value {
  font-size: 18px;
  color: #B7E2FF;
}
.dv-scroll-ranking-board .ranking-column {
  border-bottom: 2px solid rgba(19, 112, 251, 0.5);
  margin-top: 5px;
}
.dv-scroll-ranking-board .ranking-column .inside-column {
   position: relative;
   height: 20px;
   background-color: #1370fb;
   margin-bottom: 2px;
   border-radius: 1px;
   overflow: hidden;
}
</style>
