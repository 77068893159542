/*
 * @Description: 中上 畜牧场等点位数据
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
export default {
  animalList: [
    {
      lng: 80.15231512,
      lat: 40.37433358,
      series: '牛',
      phoneNum: '13579133383',
      name: '万头牛养殖基地',
      address: '新疆维吾尔自治区/阿克苏地区/阿瓦提县/塔木托克拉克镇'
    },
    {
      lng: 80.14227045,
      lat: 40.37505451,
      series: '牛',
      phoneNum: '15001613125',
      name: '万头牛养殖基地一期',
      address: '新疆维吾尔自治区/阿克苏地区/阿瓦提县/塔木托克拉克镇'
    },
    {
      lng: 80.09082347,
      lat: 40.47282577,
      series: '牛',
      phoneNum: '13779410737',
      name: '集中养殖基地',
      address: '新疆维吾尔自治区/阿克苏地区/阿瓦提县/英艾日克镇吐热村'
    },
    {
      lng: 80.19137885,
      lat: 40.50170922,
      series: '羊',
      phoneNum: '15599966609',
      name: '肉羊养殖基地',
      address: '新疆维吾尔自治区/阿克苏地区/阿瓦提县/拜什艾日克镇库木艾日克一大队'
    },
    {
      lng: 80.27083678,
      lat: 40.42249184,
      series: '牛',
      phoneNum: '15001613125',
      name: '牛基地',
      address: '新疆维吾尔自治区/阿克苏地区/阿瓦提县/拜什艾日克镇仓村'
    },
    {
      lng: 80.28443313,
      lat: 40.39235797,
      series: '鸡',
      phoneNum: '13199747896',
      name: '豪瀚蛋禽养殖场',
      address: '新疆维吾尔自治区/阿克苏地区/阿瓦提县/巴格托格拉克乡卡尔库杰克村'
    },
    {
      lng: 80.21137968,
      lat: 40.30454098,
      series: '牛',
      phoneNum: '13899263536',
      name: '荣兴畜牧养殖农民专业合作社',
      address: '新疆维吾尔自治区/阿克苏地区/阿瓦提县/阿依巴格镇托万克库拉斯村'
    },
    {
      lng: 80.19331546,
      lat: 40.34398221,
      series: '鸡',
      phoneNum: '15001613125',
      name: '恒通家禽养殖场',
      address: '新疆维吾尔自治区/阿克苏地区/阿瓦提县/阿依巴格镇十二大队'
    },
    {
      lng: 80.19448448,
      lat: 40.35598541,
      series: '牛',
      phoneNum: '15001613125',
      name: '万头牛养殖基地',
      address: '新疆维吾尔自治区/阿克苏地区/阿瓦提县/阿依巴格镇玉斯屯克依莱克村3组'
    },
    {
      lng: 80.15231512,
      lat: 40.37433358,
      series: '牛',
      phoneNum: '13579133383',
      name: '万头牛养殖基地',
      address: '-',
      coordinator: '阿米兰•那曼'
    },
    {
      lng: 80.397955,
      lat: 40.652380,
      series: '鸡',
      holder: '谭钧',
      phoneNum: '133****5072',
      name: '莲花社区',
      address: '-',
      nums: '20000+',
      outNum: '-'
    },
    {
      lng: 80.332794,
      lat: 40.624115,
      series: '鸡',
      holder: '董德印',
      phoneNum: '137****0097',
      name: '文化社区',
      address: '-',
      nums: '61000+',
      outNum: '-'
    },
    {
      lng: 80.313748,
      lat: 40.645761,
      series: '鸡',
      holder: '刘旭',
      phoneNum: '158****9118',
      name: '其兰巴克村',
      address: '-',
      nums: '10000+',
      outNum: '-'
    },
    {
      lng: 80.313717,
      lat: 40.645578,
      series: '鸡',
      holder: '姚廷军',
      phoneNum: '158****6382',
      name: '其兰巴克村',
      address: '-',
      nums: '18000+',
      outNum: '-'
    },
    {
      lng: 80.377099,
      lat: 40.623453,
      series: '鸡',
      holder: '王东明',
      phoneNum: '138****3562',
      name: '其兰巴克村',
      address: '-',
      nums: '20000+',
      outNum: '-'
    },
    {
      lng: 80.394375,
      lat: 40.613298,
      series: '鸡',
      holder: '李晓龙',
      phoneNum: '136****8015',
      name: '下阔什巴格村',
      address: '-',
      nums: '12000+',
      outNum: '-',
      coordinator: '排祖拉•沙木沙克'
    },
    {
      lng: 80.33038,
      lat: 40.682461,
      series: '鸡',
      holder: '图拉古丽•克热木',
      phoneNum: '136****8015',
      name: '下阔什巴格村',
      address: '-',
      nums: '11900+',
      outNum: '-',
      coordinator: '排祖拉•沙木沙克'
    },
    {
      lng: 80.305757,
      lat: 40.61395,
      series: '鸡',
      holder: '郭锦霞',
      phoneNum: '181****3330',
      name: '下阔什巴格村',
      address: '-',
      nums: '70000+',
      outNum: '-',
      coordinator: '排祖拉•沙木沙克'
    },
    {
      lng: 80.350132,
      lat: 40.641309,
      series: '鸡',
      holder: '东德印1',
      phoneNum: '137****0097',
      name: '团结村',
      address: '-',
      nums: '200000+',
      outNum: '-',
      coordinator: '热依木•艾买日'
    },
    // 鸽
    {
      lng: 80.3155244,
      lat: 40.6871127,
      series: '鸽',
      holder: '赵俊梅',
      phoneNum: '158****6588',
      name: '西尼尔镇',
      address: '-',
      nums: '43250+',
      outNum: '-',
      coordinator: '热依木•艾买日'
    },
    // 牛
    {
      lng: 80.296825,
      lat: 40.6672921,
      series: '牛',
      holder: '阿布都拉·木沙-库尔勒碧禾澜牛羊养殖专业合作社',
      phoneNum: '199****8599',
      coordinator: '阿巴斯•司马义',
      name: '艾兰巴格村',
      address: '-',
      nums: '105+',
      outNum: '-'
    },
    {
      lng: '80.2989272',
      lat: '40.5703047',
      series: '牛',
      holder: '努尔艾合买提·艾白布拉-新疆努祖尔养殖专业合作社',
      phoneNum: '150****2999',
      coordinator: '阿曼古丽•哈斯木',
      name: '上牙克托格拉克村',
      address: '-',
      nums: '833+',
      outNum: '-'
    },
    {
      lng: '80.2991916',
      lat: '40.5722102',
      series: '牛',
      holder: '艾则孜·阿布都热音-新疆艾孜孜养殖专业合作社',
      phoneNum: '180****8088',
      coordinator: '阿曼古丽•哈斯木',
      name: '上牙克托格拉克村',
      address: '-',
      nums: '85+',
      outNum: '-'
    },
    {
      lng: '80.3001461',
      lat: '40.5728948',
      series: '牛',
      holder: '木沙•艾山',
      phoneNum: '191****8884',
      coordinator: '阿曼古丽•哈斯木',
      name: '上牙克托格拉克村',
      address: '-',
      nums: '68+',
      outNum: '-'
    },
    {
      lng: '80.3145531',
      lat: '40.640435',
      series: '牛',
      holder: '依达也提•阿布力米提',
      phoneNum: '176****7676',
      coordinator: '阿曼古丽•哈斯木',
      name: '托布力其村',
      address: '-',
      nums: '72+',
      outNum: '-'
    },
    {
      lng: '80.325861',
      lat: '40.7158396',
      series: '牛',
      holder: '依达也提•阿布力米提',
      phoneNum: '180****6499',
      coordinator: '阿娜尔古丽•开日木',
      name: '第一村',
      address: '-',
      nums: '73+',
      outNum: '-'
    },
    {
      lng: '80.3112471',
      lat: '40.5814897',
      series: '牛',
      holder: '马强',
      phoneNum: '133****7998',
      coordinator: '阿娜古丽·亚克甫',
      name: '上阔什巴格村',
      address: '-',
      nums: '77+',
      outNum: '-'
    },
    {
      lng: '80.3004224',
      lat: '40.668071',
      series: '牛',
      holder: '阿布力米提•麦买提',
      phoneNum: '133****5552',
      coordinator: '阿孜古丽•吉乃都拉',
      name: '铁克其村',
      address: '-',
      nums: '52+',
      outNum: '-'
    },
    {
      lng: '80.3004224',
      lat: '40.668071',
      series: '牛',
      holder: '吾斯曼•艾尼瓦尔',
      phoneNum: '130****3302',
      coordinator: '阿孜古丽•吉乃都拉',
      name: '艾兰巴格村',
      address: '-',
      nums: '146+',
      outNum: '-'
    },
    {
      lng: '80.297381',
      lat: '40.644449',
      series: '牛',
      holder: '阿不力孜•赛买提',
      phoneNum: '130****5596',
      coordinator: '阿孜古丽•吉乃都拉',
      name: '上恰其村',
      address: '-',
      nums: '80+',
      outNum: '-'
    },
    {
      lng: '80.2965228',
      lat: '40.5702396',
      series: '牛',
      holder: '吐尔地•依明',
      phoneNum: '189****0987',
      coordinator: '阿孜古丽•吉乃都拉',
      name: '中恰其社区',
      address: '-',
      nums: '57+',
      outNum: '-'
    },
    {
      lng: '80.3004224',
      lat: '40.668072',
      series: '牛',
      holder: '艾合买提•巴拉提',
      phoneNum: '181****5559',
      coordinator: '阿孜古丽•吉乃都拉',
      name: '下恰其社区',
      address: '-',
      nums: '78+',
      outNum: '-'
    },
    {
      lng: '80.3004224',
      lat: '40.668072',
      series: '牛',
      holder: '拜克力阿洪•麦麦提',
      phoneNum: '191****5971',
      coordinator: '阿孜古丽•吉乃都拉',
      name: '阿克塔什社区',
      address: '-',
      nums: '53+',
      outNum: '-'
    },
    {
      lng: '80.2970416',
      lat: '40.644965',
      series: '牛',
      holder: '买买提•依米提',
      phoneNum: '176****1477',
      coordinator: '阿孜古丽•吉乃都拉',
      name: '艾兰巴格社区',
      address: '-',
      nums: '154+',
      outNum: '-'
    },
    {
      lng: '80.404224',
      lat: '40.668071',
      series: '牛',
      holder: '木沙•亚生',
      phoneNum: '130****0831',
      coordinator: '阿孜古丽•吉乃都拉',
      name: '艾兰巴格社区',
      address: '-',
      nums: '71+',
      outNum: '-'
    },
    {
      lng: '80.2965548',
      lat: '40.644965',
      series: '牛',
      holder: '艾山•麦木提明',
      phoneNum: '151****9335',
      coordinator: '阿孜古丽•吉乃都拉',
      name: '海勒派艾日克社区',
      address: '-',
      nums: '70+',
      outNum: '-'
    },
    {
      name: '下和什力克村',
      holder: '塔力甫•莫依东',
      phoneNum: '157****6664',
      series: '牛',
      nums: '65',
      lng: '80.273034',
      lat: '40.737523',
      coordinator: '艾孜热提艾力·赛福丁'
    },
    {
      name: '下和什力克村',
      holder: '买买提•热合木托拉',
      phoneNum: '152****2575',
      series: '牛',
      nums: '75',
      lng: '80.293034',
      lat: '40.637624'
    },
    {
      name: '下和什力克村',
      holder: '喀伍力•吾普尔',
      phoneNum: '135****8208',
      series: '牛',
      nums: '123',
      lng: '80.260755',
      lat: '40.616557'
    },
    {
      name: '下牙克托格拉克村',
      holder: '克热木库尔班',
      phoneNum: '187****2202',
      series: '牛',
      nums: '191',
      lng: '80.342227',
      lat: '40.605862'
    }
  ]

}
