<!--
 * @Description: 畜牧养殖中左
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-16 20:02:19
-->
<template>
  <div style="width: 100%; height: 100%" flex="dir:left box:mean">
    <!-- 饼图 开始 -->
    <v-chart class="chart" :option="option.config1" />
    <!-- 饼图 结束 -->
    <!-- 饼图 开始 -->
    <v-chart class="chart" :option="option.config2" />
    <!-- 饼图 结束 -->
  </div>
</template>

<script>
import option from './option'
export default {
  name: 'MainLeft',
  data () {
    return {
      option
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss"></style>
