<!--
 * @Description: 右上下
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-21 05:58:54
-->
<template>
  <!-- 柱状加折线图 开始 -->
  <v-chart :option="option" />
  <!-- 柱状加折线图 结束 -->
</template>

<script>
import option from './option'
export default {
  name: 'RightTopBottom',
  data () {
    return {
      option
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
</style>
