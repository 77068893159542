<!--
 * @Description: 智慧大屏首页
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2023-01-09 12:04:17
-->
<template>
  <!-- 大屏容器 开始 -->
  <div class="layout-container">
    <!-- 大屏内脑图按钮 开始  -->
    <div class="mindmap-button"
         v-if="!bannerVisible"
         @click="showMindMap=true">
      <img src="/image/help.png">
    </div>
    <!-- 大屏内脑图按钮 结束  -->
    <!--   遮罩层 开始  -->
    <div class="mask"
         v-if="showMindMap"
         @click="showMindMap=false"></div>
    <!-- 遮罩层 结束  -->
    <!-- 弹窗 开始  -->
    <dv-border-box-10 class="pop"
                      v-if="showMindMap">
      <div class="mind-map-cancel-button"
           @click="showMindMap=false">✖</div>
      <img class="mind-map-image"
           src="/image/mindmap.png">
    </dv-border-box-10>
    <!-- 弹窗 结束  -->
    <!-- 菜单区域 开始 -->
    <div v-if="!bannerVisible"
         class="main-container-menu">
      <!-- 一级菜单 开始 -->
      <div class="main-container-center-menu"
           @click="handleMainMenuClick">{{currentMainMenu.name}}</div>
      <!-- 一级菜单 结束 -->
      <!-- 一级菜单分组 开始 -->
      <div :class="`main-container-menu-group${currentMainMenu.index}`">
        <!-- 二级菜单 -->
        <div v-for="(menu,index) in currentMainMenu.subMenu"
             :key="index"
             :class="`main-container-menu-${menu.index} menu`"
             @click="handleSubMenuSelect(menu)">{{menu.name}}</div>
        <!-- 二级菜单 -->
      </div>
      <!-- 一级菜单分组 结束 -->
    </div>
    <!-- 菜单区域 结束 -->
    <!-- 一级菜单 Panel 开始 -->
    <div v-if="!bannerVisible"
         class="menu-panel"
         flex="box:mean"
         v-show="menuPanelVisibal">
      <div v-for="(menu,index) in menu"
           :key="index"
           class="menu-panel-menu menu"
           @click="handleMenuSelect(menu)">{{menu.name}}</div>
    </div>
    <!-- 一级菜单 Panel 结束 -->
    <!-- 一级菜单容器 开始 -->
    <keep-alive>
      <component v-if="!bannerVisible"
                 :is="currentView"
                 :menu-index="currentSubMenu.index">
      </component>
    </keep-alive>
    <!--    <div class="home-page">-->
    <!--      <el-carousel height="1080px" :interval="10000" v-if="bannerVisible">-->
    <!--        <el-carousel-item class="carousel-page" v-for="(item,i) in imgList"  :key="i" >-->
    <!--          <img :src="item">-->
    <!--          <div class="home-page-btn" @click="bannerVisible=false"></div>-->
    <!--          &lt;!&ndash; 首页脑图按钮 开始 &ndash;&gt;-->
    <!--          <div  class="mind-map-button" @click="showMindMap=true">-->
    <!--            <img src="/image/help1.png"></div>-->
    <!--          &lt;!&ndash; 首页脑图按钮 结束 &ndash;&gt;-->
    <!--        </el-carousel-item>-->
    <!--      </el-carousel>-->
    <!--    </div>-->
    <div class="home-page">
      <!--  走马灯
      <el-carousel height="1080px"
                   :interval="10000"
                   v-if="bannerVisible">
        <el-carousel-item class="carousel-page"
                          v-for="(item,i) in imgList"
                          :key="i"> -->
      <div height="1080px"
           :interval="10000"
           v-if="bannerVisible">
        <div class="carousel-page"
             v-for="(item,i) in imgList"
             :key="i">-->
          <img :src="item">
          <div class="home-page-input">
            <img class="mind-map-image"
                 src="/image/user.png">
            <input v-model="userName"
                   placeholder="请输入账号..." />
          </div>
          <div class="home-page-input2">
            <img class="mind-map-image"
                 src="/image/password.png">
            <input v-model="authCode"
                   type="password"
                   placeholder="请输入密码..." />
          </div>
          <div class="home-page-btn"
               @click="onLogin">
            登录
          </div>
          <!-- 首页脑图按钮 开始 -->
          <div class="mind-map-button"
               @click="showMindMap=true">
            <img src="/image/help1.png">
          </div>
          <!-- 首页脑图按钮 结束 -->
        </div>
      </div>
      <!--
      </el-carousel-item>
      </el-carousel> -->
    </div>
    <!-- 一级菜单容器 结束 -->
  </div>
  <!-- 大屏容器 结束 -->
</template>

<script>
import util from '@/libs/util.js' // 系统工具类
import menu from '././components/menu-data/menu' // 菜单配置
import { _SysLogin } from '@/api/mods/sys.login' // 登录接口
import MainView1 from './components/main-view1/index.vue' // 主菜单 1 容器
import MainView2 from './components/main-view2/index.vue' // 主菜单 2 容器
import MainView3 from './components/main-view3/index.vue' // 主菜单 3 容器
import MainView4 from './components/main-view4/index.vue' // 主菜单 4 容器
export default {
  name: 'App',
  components: {
    MainView1,
    MainView2,
    MainView3,
    MainView4
  },
  data () {
    return {
      token: null,
      userName: '', // 18160582116
      authCode: '', // 000000
      menu, // 菜单配置
      bannerVisible: true,
      currentMainMenu: menu[0], // 一级菜单
      currentSubMenu: menu[0].subMenu[0], // 二级菜单
      currentView: 'MainView1', // 当前主菜单组件名称
      menuPanelVisibal: false, // 一级菜单 Panel 显示状态
      showMindMap: false,
      // imgList: ['/image/homepage.jpg', '/image/homepage1.jpg', '/image/homepage2.jpg']
      imgList: ['/image/homepageNwe.jpg']
    }
  },
  created () {
    this.token = util.getToken()
    console.log(this.token)
    if (this.token) {
      this.bannerVisible = false
    }
    if (!this.token) {
      this.bannerVisible = true
    }
  },
  methods: {
    /**
     * onLogin
     * @description 登录
     */
    onLogin () {
      if (this.userName === '' || this.authCode === '') {
        this.$message({
          message: '账号或密码不能为空！',
          type: 'warning'
        })
      } else if (this.userName !== 'admin' || this.authCode !== '123456') {
        this.$message({
          message: '账号或密码错误！',
          type: 'warning'
        })
      } else {
        this.sysLogin()
        this.bannerVisible = false
      }
    },

    /**
     * handleMainMenuClick
     * @description 菜单切换
     */
    handleMainMenuClick () {
      this.menuPanelVisibal = !this.menuPanelVisibal
    },

    /**
     * handleMenuSelect
     * @description 菜单选择
     */
    handleMenuSelect (menu) {
      // 当前一级菜单
      this.currentMainMenu = menu
      // 动态组件名称
      this.currentView = 'MainView' + menu.index
      // 一级菜单显示状态
      this.menuPanelVisibal = !this.menuPanelVisibal
    },

    /**
     * handleSubMenuSelect
     * @description 菜单选择
     */
    handleSubMenuSelect (menu) {
      if (menu && menu.url) {
        window.open(menu.url, '_blank')
        this.currentSubMenu = 0
      } else {
        this.currentSubMenu = menu
      }
    },

    /**
     * sysLogin
     * @description 系统用户登录额
     */
    sysLogin () {
      // 登录
      _SysLogin().then(res => {
        // 设置 Token
        util.setToken(res.data.token)
      })
    }

  },
  beforeMount () {
    // 挂载前登录获取 Token
    this.sysLogin()
  }
}
</script>

<style lang="scss">
// 注册布局结构
@import "~@/assets/style/layout.scss";

/** 走马灯样式
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
} */

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-text-fill-color: #ededed !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  background-color: transparent;
  background-image: none;
  transition: background-color 50000s ease-in-out 0s; //背景色透明  生效时长  过渡效果  启用时延迟的时间
}
input {
  background-color: transparent;
}
</style>
