/*
 * @Description: 中左 圆环配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
export default {
  radius: '60%',
  activeRadius: '66%',
  lineWidth: '30',
  showOriginValue: true,
  digitalFlopStyle: {
    fontSize: 18,
    textColor: '#B7E1FF'
  },
  color: ['#00FF33', '#FFDD00', '#0095FF', '#F600EA', '#ff2a77', '#fcb28a'],
  data: [
    {
      name: '茄果菜类/亩',
      value: 2400
    },
    {
      name: '叶菜类/亩',
      value: 2339
    },
    {
      name: '根茎类/亩',
      value: 1.5
    },
    {
      name: '瓜菜类/亩',
      value: 240
    },
    {
      name: '食用菌/亩',
      value: 240
    },
    {
      name: '其他蔬菜/亩',
      value: 1429.5
    }
  ]
}
