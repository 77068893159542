<!--
 * @Description: 下左右内容区域
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-16 19:51:54
-->
<template>
  <div class="weather_data">
    <div class="weather_text text_one">
      <span>温度：3℃</span>
      <span>湿度：36%</span>
      <span>风向：东北风</span>
      <span>风速：2m/s</span>
    </div>
    <div class="weather_text text_two">
      <span>降雨量：0mm</span>
      <span>蒸发量：0.326mm/h</span>
      <span>气压：0.326MPa</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainBottonLeftRight',
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.weather_data {
  position: relative;
  display: inline-block;
  margin-left: 4%;
  font-size: 13.5px;
  /* background-color: yellow; */
  background-image: url(/image/main-view4/bottom_icons.png);
  background-size: 90% 80%;
  background-repeat: no-repeat;
}
.weather_text {
  color: #0efcff;
}
.weather_text span {
  position: absolute;
  top: 35%;
}
.weather_text span:nth-of-type(1) {
  left: -3%;
}
.weather_text span:nth-of-type(2) {
  left: 23%;
}
.weather_text span:nth-of-type(3) {
  left: 48%;
}
.weather_text span:nth-of-type(4) {
  left: 77%;
}
.text_two span {
  top: 85%;
}
.text_two span:nth-of-type(1) {
  left: 1%;
}
.text_two span:nth-of-type(2) {
  left: 28%;
}
.text_two span:nth-of-type(3) {
  left: 63%;
}
</style>
