<!--
 * @Description: 中上
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-23 23:32:38
-->
<template>
  <div flex="dir:left">
    <div class="top-left">
      <span class="title">科研项目行业占比</span>
      <div flex="dir:left"
           class="chart-annulus">
        <div flex="dir:top box:mean" class="cutline">
          <div flex="dir:left">
            <span>农业</span>
            <div style="background-color: rgb(0,149,255);"></div>
          </div>
          <div flex="dir:left">
            <span>软件</span>
            <div style="background-color: rgb(246,0,234);"></div>
          </div>
          <div flex="dir:left">
            <span>工业</span>
            <div style="background-color: rgb(255,221,0);"></div>
          </div>
          <div flex="dir:left">
            <span>制造业</span>
            <div style="background-color: rgb(0,255,255);"></div>
          </div>
          <div flex="dir:left">
            <span >其他</span>
            <div style="background-color: rgb(61, 231, 201);"></div>
          </div>
        </div>
        <dv-active-ring-chart :config="option.config"/>
      </div>
    </div>
    <div flex="dir:top">
      <div flex="dir:left box:main" class="chart-row">
        <div>
          <dv-border-box-3>
            <dv-digital-flop :config="option.configNum1"/>
            <span>科研成果</span>
          </dv-border-box-3>
        </div>
        <div>
          <dv-border-box-3>
            <dv-digital-flop :config="option.configNum2"/>
            <span>高校总数</span>
          </dv-border-box-3>
        </div>
        <div>
          <dv-border-box-3>
            <dv-digital-flop :config="option.configNum3"/>
            <span>已推广</span>
          </dv-border-box-3>
        </div>
        <div>
          <dv-border-box-3>
            <dv-digital-flop :config="option.configNum4"/>
            <span>已申报专利</span>
          </dv-border-box-3>
        </div>
        <div>
          <dv-border-box-3>
            <dv-digital-flop :config="option.configNum5"/>
            <span>专利申报中</span>
          </dv-border-box-3>
        </div>
      </div>
      <div flex="dir:top" class="chart-column">
        <span class="title">已申报专利高校排名</span>
        <div>
          <dv-capsule-chart :config="option.configColumn"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import option from './option'

export default {
  name: 'MainMenu1',
  data () {
    return {
      option
    }
  }
}
</script>

<style lang="scss">
.top-left{
  width:40%;height:100%;
}
.chart-annulus{
  width:100%;height:95%;
}
.dv-active-ring-chart{
  width:80%;height:80%;
}
.cutline{
  width:30%;height:50%;margin-top: 15%;
}
.cutline span{
  width: 50%;
}
.cutline div{
  width: 36px;height: 18px;margin: 4px;
}
.chart-row{
  height: 30%;margin-right: 3%;
}
.dv-border-box-3 .dv-digital-flop{
  width:100%;height:60%;margin-top: 10%;
}
.dv-border-box-3 span{
  width: 100%;
}
.chart-column{
  height: 65%;margin-top: 5%
}
.chart-column span{
  margin-bottom:11px;
}
.chart-column div{
  height: 95%;
}
.chart-column .dv-capsule-chart{
  width:95%;height:100%;
}
.label-total-tip {
  height: 100%;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin-left: 34px;
}

.dv-capsule-chart {
  font-size: 18px !important;
  line-height: 18px !important;
  .label-column {
    font-size: 18px !important;
    line-height: 18px !important;
    padding-right: 15px !important;
    margin-top: 4px;
  }
  .capsule-item {
    height: 18px !important;
    margin: 0px !important;
    border-radius: 7.5px !important;
    .capsule-item-column {
      height: 16.5px !important;
      border-radius: 7.5px !important;
    }
    .capsule-item-value {
      font-size: 15px !important;
      padding-left: 7.5px !important;
    }
  }
  .unit-label {
    height: 15px !important;
    font-size: 7.5px !important;
  }
  .unit-text {
    font-size: 15px !important;
    line-height: 15px !important;
    margin-left: 7.5px !important;
  }
}
</style>
