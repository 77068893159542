import { color } from 'echarts'
// eslint-disable-next-line camelcase
const class_xAxis = ['棉花', '粮食', '香梨', '农机', '农资', '农产品', '其他'] // 列标
// eslint-disable-next-line camelcase
const class_data1 = [38702, 3721, 2873, 3724, 3789, 38374, 8374] // 柱图1数据
export default {
  title: { show: false },
  toolbox: { show: false },
  legend: {
    top: 'top',
    textStyle: {
      color: '#B7E2FF',
      fontSize: 18,
      fontFamily: '微软雅黑'
    }
  },
  grid: {
    top: '15%',
    left: '15%',
    right: '5%',
    bottom: '10%'
  },
  xAxis: {
    type: 'category',
    boundaryGap: true,
    data: class_xAxis, // 数据
    axisLabel: {
      interval: 0,
      textStyle: {
        color: '#B7E1FF',
        fontSize: 18
      }
    },
    axisLine: {
      lineStyle: {
        color: '#09F'
      }
    }
  },
  yAxis: {
    inverse: false,
    name: '信息/条',
    nameTextStyle: {
      padding: [0, 0, 0, -80], // 四个数字分别为上右下左与原位置距离
      fontSize: 18,
      color: '#B7E1FF'
    },
    splitArea: { show: false },
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: {
      textStyle: {
        color: '#B7E1FF',
        fontSize: 18,
        fontFamily: 'Arial'
      }
    },
    splitLine: {
      lineStyle: {
        color: '#09F'
      }
    }
  },
  tooltip: {
    trigger: 'axis',
    backgroundColor: 'rgba(84,131,197,0.7)',
    borderColor: 'rgba(84,131,197,0.55)',
    textStyle: {
      color: '#ffffff',
      fontSize: 18
    }
  },
  // 提示框
  series: [
    {
      type: 'bar',
      stack: 'one',
      itemStyle:
      {
        normal: { color: color[1] }
      },
      barWidth: 30,
      data: class_data1
    }
  ]
}
