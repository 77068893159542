<!--
 * @Description: 金融服务页面
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-23 22:32:05
-->
<template>
  <!-- 大屏容器 开始 -->
  <div class="container"
       flex="dir:left box:justify">
    <!-- 左侧 开始 -->
    <div flex="dir:top box:justify"
         class="aside">
      <!-- 左侧上 开始 -->
      <div flex="dir:top box:first"
           class="aside-top">
        <div class="container-title title">农贷利率</div>
        <AsideLeftTop class="container-main"></AsideLeftTop>
      </div>
      <!-- 左侧上 结束 -->
      <div flex="dir:top box:first"
           class="aside-middle">
        <div class="container-title title">亩均授信额度</div>
        <AsideLeftMiddle class="container-main"></AsideLeftMiddle>
      </div>
      <!-- 左侧下 开始 -->
      <div flex="dir:top box:first"
           class="aside-bottom">
        <div class="container-title title">实际放款额度</div>
        <AsideLeftBottom class="container-main"></AsideLeftBottom>
      </div>
      <!-- 左侧下 结束 -->
    </div>
    <!-- 左侧结束 -->
    <!-- 中间 开始 -->
    <div flex="dir:top box:last"
         class="aside-center">
      <!-- 中间上 开始 -->
      <div flex="dir:top box:first"
           class="aside-center-top">
        <div class="container-title title">
          绿洲信用体系</div>
        <CenterTop class="container-main"></CenterTop>
      </div>
      <!-- 中间上 结束 -->
      <!-- 中间下 开始 -->
      <div flex="box:mean"
           class="aside-center-bottom">
        <!-- 中间下左 开始 -->
        <div flex="dir:top box:first"
             class="aside-center-bottom-left">
          <div class="container-title title">农业保险</div>
          <CenterBottomLeft class="container-main"></CenterBottomLeft>
        </div>
        <!-- 中间下左 结束 -->
        <!-- 中间下右 开始 -->
        <div flex="dir:top box:first"
             class="aside-center-bottom-right">
          <div class="container-title title">商业保险</div>
          <CenterBottomRight class="container-main"></CenterBottomRight>
        </div>
        <!-- 中间下右 结束 -->
      </div>
      <!-- 中间下 结束 -->
    </div>
    <!-- 中间 结束 -->
    <!-- 右侧 开始 -->
    <div flex="dir:top box:justify"
         class="aside-right">
      <!-- 左侧上 开始 -->
      <div flex="dir:top box:first"
           class="aside-right-top">
        <div class="container-title title">产量走势</div>
        <AsideRightTop class="container-main"></AsideRightTop>
      </div>
      <!-- 左侧上 结束 -->
      <div flex="dir:top box:first"
           class="aside-right-middle">
        <div class="container-title title">销量走势</div>
        <AsideRightMiddle class="container-main"></AsideRightMiddle>
      </div>
      <!-- 左侧下 开始 -->
      <div flex="dir:top box:first"
           class="aside-right-bottom">
        <div class="container-title title">销售额占比</div>
        <AsideRightBottom class="container-main"></AsideRightBottom>
      </div>
      <!-- 左侧下 结束 -->
    </div>
    <!-- 右侧 结束 -->
  </div>
  <!-- 大屏容器 结束 -->
</template>

<script>
import AsideLeftTop from './components/aside-left-top/index.vue'
import AsideLeftMiddle from './components/aside-left-middle/index.vue'
import AsideLeftBottom from './components/aside-left-bottom/index.vue'
import AsideRightTop from './components/aside-right-top/index.vue'
import AsideRightMiddle from './components/aside-right-middle/index.vue'
import AsideRightBottom from './components/aside-right-bottom/index.vue'
import CenterTop from './components/center-top/index.vue'
import CenterBottomLeft from './components/center-bottom-left/index.vue'
import CenterBottomRight from './components/center-bottom-right/index.vue'
export default {
  name: 'MainContainer3',
  components: {
    AsideLeftTop,
    AsideLeftMiddle,
    AsideLeftBottom,
    AsideRightTop,
    AsideRightMiddle,
    AsideRightBottom,
    CenterTop,
    CenterBottomLeft,
    CenterBottomRight
  },
  methods: {
    goUrl (url) {
      window.open(
        url,
        '_blank'
      )
    }
  }
}
</script>

<style lang="scss" scoped>
// 注册样式
@import "~@/assets/style/main-view33.scss";
</style>
