<!--
 * @Description: 下上左上
 * @Author: Pancras
 * @Date: 2022-01-21 06:38:44
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-21 07:01:03
-->
<template>
  <div flex="dir:left box:mean">
    <!-- 左圆环图 开始 -->
    <div flex="dir:top box:first">
      <span>销售占比</span>
      <dv-active-ring-chart :config="option.config1" />
    </div>
    <!-- 左圆环图 结束 -->
    <!-- 右圆环图 开始 -->
    <div flex="dir:top box:first">
      <span>分类销售占比</span>
      <dv-active-ring-chart :config="option.config2" />
    </div>
    <!-- 右圆环图 结束 -->
  </div>
</template>
<script>
import option from './option'
export default {
  name: 'MainBottomTopLeftTop',
  data () {
    return {
      option
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
