/*
 * @Description: 中上 折线图配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */

export default {
  align: 'center',
  headerHeight: 52.5,
  waitTime: 2000, // 轮播时间
  oddRowBGC: ';background-color:rgba(84,131,197,0.29);font-size: 18px;color: #B7E2FF;', // 奇数行背景色
  evenRowBGC: ';font-size: 18px;color: #B7E2FF;', // 偶数行背景色
  rowNum: 7, // 轮播表行数
  headerBGC: ';background-color:rgba(84,131,197,0.83);font-size: 18px;color: #B7E2FF;', // 表头背景色
  // columnWidth: [140, 280, 140, 140],
  header: ['姓名', '身份证', '告警', '信用变动', '当前信用', '信用等级'],
  data: [
    ['张达', '652121********1235', '<span style="color:#04a424;font-size: 18px;">正常</span>', '+1', '1002', '极好'],
    ['李斯吉', '647561********8461', '<span style="color:#ce1010;font-size: 18px;">异常</span>', '-5', '0', '较差'],
    ['安嘉生', '746378********1784', '<span style="color:#04a424;font-size: 18px;">正常</span>', '+76', '716', '良好'],
    ['马菊芳', '452311********2345', '<span style="color:#04a424;font-size: 18px;">正常</span>', '+23', '643', '良好'],
    ['王义', '78441********1235', '<span style="color:#04a424;font-size: 18px;">正常</span>', '+21', '8312', '极好'],
    ['刘晓丽', '648514********2242', '<span style="color:#ce1010;font-size: 18px;">异常</span>', '-12', '0', '较差'],
    ['李翠香', '653412********3142', '<span style="color:#04a424;font-size: 18px;">正常</span>', '+23', '562', '良好'],
    ['王艺', '654512********9632', '<span style="color:#04a424;font-size: 18px;">正常</span>', '+4', '122', '一般'],
    ['张加琪', '645453********1632', '<span style="color:#04a424;font-size: 18px;">正常</span>', '+67', '1232', '极好'],
    ['荀香莲', '631472********1323', '<span style="color:#04a424;font-size: 18px;">正常</span>', '+2', '2311', '极好'],
    ['潘雪翎', '652121********3576', '<span style="color:#04a424;font-size: 18px;">正常</span>', '+34', '3123', '极好'],
    ['菅茉', '651721********4673', '<span style="color:#ce1010;font-size: 18px;">异常</span>', '-9', '0', '较差'],
    ['劳华楚', '651758********8543', '<span style="color:#04a424;font-size: 18px;">正常</span>', '+54', '765', '良好'],
    ['朱辉文', '653457********1754', '<span style="color:#04a424;font-size: 18px;">正常</span>', '+87', '1289', '极好'],
    ['许紫兰', '658421********1758', '<span style="color:#ce1010;font-size: 18px;">异常</span>', '+4', '0', '较差'],
    ['王柏思', '659541********2527', '<span style="color:#04a424;font-size: 18px;">正常</span>', '+7', '234', '一般']
  ]
}
