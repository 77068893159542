/*
 * @Description: 左上图表1配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
export default {
  lineWidth: 45,
  radius: '70%',
  activeRadius: '80%',
  digitalFlopStyle: {
    fontSize: 18
  },
  showOriginValue: true,
  data: [{
    name: '链式挖掘机',
    value: 569
  },
  {
    name: '轮式装载机',
    value: 811
  },
  {
    name: '轮式挖掘机',
    value: 40
  },
  {
    name: '推土机',
    value: 38
  }
  ]
}
