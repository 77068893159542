/*
 * @Description: 菜单数据
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Hlc
 * @LastEditTime: 2022-06-06 14:18:45
 */
export default [
  {
    index: 1,
    name: '数据一张图',
    subMenu: [
      {
        index: 1,
        name: '十四五规划'
      },
      {
        index: 2,
        name: '土地利用'
      },
      {
        index: 3,
        name: '设施农业'
      },
      {
        index: 4,
        name: '畜牧养殖'
      },
      {
        index: 5,
        name: '林果种植'
      },
      {
        index: 6,
        name: '农机管理'
      }
    ]
  },
  {
    index: 2,
    name: '数字化管理',
    subMenu: [
      {
        index: 1,
        name: '自动化管理',
        url: 'http://zzjd.kenyte.com/login'
      },
      {
        index: 2,
        name: '信息化管理',
        url: 'http://yypt.kenyte.com/login'
      }
    ]
  },
  {
    index: 3,
    name: '产业化运营',
    subMenu: [
      {
        index: 1,
        name: '平台交易'
      },
      {
        index: 2,
        name: '品牌建设'
      },
      {
        index: 3,
        name: '金融服务'
      },
      {
        index: 4,
        name: '产学研'
      }
    ]
  },
  {
    index: 4,
    name: '智能化决策',
    subMenu: [
      {
        index: 1,
        name: '政务决策'
      },
      {
        index: 2,
        name: '农事决策'
      }
    ]
  }
]
