/*
 * @Description: 左上 轮播表配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
// eslint-disable-next-line camelcase
export default {
  align: 'center',
  headerHeight: 37.5,
  waitTime: 2000, // 轮播时间
  oddRowBGC: ';font-size: 18px;color: #B7E2FF;', // 奇数行背景色
  evenRowBGC: 'rgba(84,131,197,0.29);font-size: 18px;color: #B7E2FF;', // 偶数行背景色
  rowNum: 4, // 轮播表行数
  headerBGC: ';background-color:rgba(84,131,197,0.83);font-size: 18px;color: #B7E2FF;', // 表头背景色
  header: ['项目编号', '项目名称', '需求类别', '是否孵化'],
  columnWidth: [140, 280, 140, 140],
  data: [
    ['9823', '智能温室大棚基础设施建设', '硬件技术', '正在孵化'],
    ['5214', '库尔勒高标准农田建设数据分析', '数据分析', '正在孵化'],
    ['7847', '温室智能控制系统', '软件开发', '正在孵化'],
    ['7847', '温室智能控制系统', '软件开发', '已完成'],
    ['7847', '温室智能控制系统', '软件开发', '正在孵化'],
    ['7847', '温室智能控制系统', '软件开发', '已完成'],
    ['7847', '温室智能控制系统', '软件开发', '正在孵化'],
    ['7847', '温室智能控制系统', '软件开发', '正在孵化'],
    ['7847', '温室智能控制系统', '软件开发', '已完成'],
    ['7847', '温室智能控制系统', '软件开发', '正在孵化'],
    ['5849', '智慧农业服务平台数据安全问题', '安全保障', '正在孵化']
  ]
}
