<!--
 * @Description: 上中
 * @Author: Pancras
 * @Date: 2022-01-21 01:27:42
 * @LastEditors: Pancras
 * @LastEditTime: 2023-01-09 12:26:01
-->
<template>
  <div flex="box:mean">
    <!--  左一-->
    <div class="card"
         flex="box:first">
      <img class="img"
           src="/image/main-view3/goods.png" />
      <div class="card-main">
        <!-- <div class="card-main-title">在线商品</div>
        <div class="card-main-count">{{ option.countList[0] }}</div> -->
        <div class="card-main-title">在线农资</div>
        <div class="card-main-count">238个</div>
      </div>
    </div>
    <!--  左二-->
    <div class="card"
         flex="box:first cross:center">
      <img class="img2"
           src="/image/main-view3/hotgoods2.png" />
      <div class="card-main">
        <!-- <div class="card-main-title">热销商品</div>
        <div class="card-main-count">{{ option.countList[1] }}</div> -->
        <div class="card-main-title">在线农产品</div>
        <div class="card-main-count">5个</div>
      </div>
    </div>
  </div>
</template>

<script>
import option from './option'
export default {
  name: 'MainTopMain',
  data () {
    return {
      option
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  text-align: left;
  .img {
    width: 52.5px;
    height: 52.5px;
  }

  .img2 {
    width: 40px;
    height: 40px;
  }
  .card-main {
    margin-left: 22.5px;
    .card-main-title {
      font-size: 12px;
    }
    .card-main-count {
      font-size: 27px;
      color: rgb(216, 202, 6);
    }
  }
}
</style>
