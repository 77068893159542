<!--
 * @Description: 农机管理下右
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-16 20:03:20
-->
<template>
  <div style="width: 100%; height: 100%" flex="dir:left">
    <!-- 圆环图 开始 -->
    <dv-active-ring-chart :config="option" style="width: 70%; height: 100%" />
    <!-- 圆环图 结束 -->
    <!-- 圆环图标签 开始 -->
    <div class="label-tag" flex="dir:top box:mean">
      <div class="label-item" flex="dir:left">
        <span>{{option.data[0].name}}/辆</span>
        <div style="background-color: #32c5e9;"></div>
      </div>
      <div class="label-item" flex="dir:left">
        <span>{{option.data[1].name}}/辆</span>
        <div style="background-color: #37a2da;"></div>
      </div>
      <div class="label-item" flex="dir:left">
        <span>{{option.data[2].name}}/辆</span>
        <div style="background-color: #67e0e3;"></div>
      </div>
      <div class="label-item" flex="dir:left">
        <span>{{option.data[3].name}}/辆</span>
        <div style="background-color: rgb(159 230 184);"></div>
      </div>
    </div>
    <!-- 圆环图标签 结束 -->
  </div>
</template>

<script>
import option from './option'
export default {
  name: 'MainRight',
  data () {
    return {
      option
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.lable-tag {
  height: 22.5px;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.label-item div{
  margin-top: 5px;
  width: 36px;
  height: 18px;
  margin-left: 7.5px;
}
.label-item span{
  width: 120px;
}
</style>
