/*
 * @Description: 中左 轮播表配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2023-01-09 17:06:59
 */
export default {
  topData: [
    ['', '棉花/万亩', '小麦/万亩', '水稻/万亩', '玉米/万亩', '其他用地/万亩']
  ],
  data: [
    ['2022', '145.03', '28.59', '0.08', '27.5', '7.23'],
    ['2021', '142.06', '22.57', '0.98', '19.29', '7.64'],
    ['2020', '147.08', '19.97', '1.49', '15.34', '7.25'],
    ['2019', '158.1', '16.1', '0.59', '11.3', '1.84'],
    ['2018', '152.45', '18.3', '0', '5.38', '7.08'],
    ['2017', '138.31', '18.67', '1.43', '17.4', '9.25']
  ],
  configTop: null,
  config: null,
  columnWidth: ['80', '112', '112', '112', '112', '155']
}
