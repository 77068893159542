<!--
 * @Description: 设施农业中上
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-21 00:18:11
-->
<template>
  <!-- 地图区域 开始 -->
  <dv-border-box-13
    :color="['rgb(14,71,136)']"
    style="width: 1216px; padding: 15px"
  >
    <div
      id="kelMap"
      ref="kelMap"
      style="height: 100%; width: 100%; position: relative"
      class="map"
    ></div>
  </dv-border-box-13>
  <!-- 地图区域 结束 -->
</template>

<script>
import option from './option'
export default {
  name: 'MainTop',
  data () {
    return {
      option
    }
  },
  mounted () {
    /* eslint-disable no-undef */
    this.infoWin = new T.InfoWindow()
    this.initOverall()
  },
  methods: {
    /**
     * initOverall
     * @description 初始化地图
     */
    initOverall () {
      /* eslint-disable no-undef */
      this.tMap = new T.Map('kelMap', {
        center: new T.LngLat(80.36, 40.63),
        zoom: 13
      })
      this.tMap.setMapType(TMAP_HYBRID_MAP)
      document.getElementsByClassName('tdt-control-copyright tdt-control')[0].style.display = 'none'
      this.waterGate()
      this.meteorologicalStation()
      this.waterAndFertilizer()
      this.greenhouses()
    },

    /**
     * waterGate
     * @description 闸门点位绘制
     */
    waterGate () {
      const _this = this
      const icon = new T.Icon({
        iconUrl: 'https://zhny-002.oss-cn-beijing.aliyuncs.com/korla/icon/ssny_zm.png',
        iconSize: new T.Point(40, 50)
      })
      _this.option.valveLnglat.forEach(item => {
        let marker = new T.Marker(new T.LngLat(item.lng, item.lat), { icon: icon })
        let content = '<div style="font-size: 15px">名称：' + item.name + '</div>'
        content += '<div style="font-size: 15px">代码：ZM0001</div>'
        content += '<div style="font-size: 15px">分组代码：krl_zham</div>'
        content += '<div style="font-size: 15px">分组名称：闸门</div>'
        content += '<div style="font-size: 15px">闸门形式：板闸</div>'
        content += '<div style="font-size: 15px">闸门高度mm：600</div>'
        content += '<div style="font-size: 15px">闸门类型：自动化闸门</div>'
        content += '<div style="font-size: 15px">闸门用途：节制闸</div>'
        content += '<div style="font-size: 15px">孔数：1</div>'
        content += '<div style="font-size: 15px">设计流量m³/s：0</div>'
        content += '<div style="font-size: 15px">最大流量m³/s：0</div>'
        marker.addEventListener('click', function (e) {
          const point = e.lnglat
          marker = new T.Marker(point)// 创建标注
          const markerInfoWin = new T.InfoWindow(content, { offset: new T.Point(0, -30) }) // 创建信息窗口对象
          _this.tMap.openInfoWindow(markerInfoWin, point) // 开启信息窗口
        })
        _this.tMap.addOverLay(marker)
      })
    },

    /**
     * meteorologicalStation
     * @description 气象站点位绘制
     */
    meteorologicalStation () {
      const _this = this
      const icon = new T.Icon({
        iconUrl: 'https://zhny-002.oss-cn-beijing.aliyuncs.com/korla/icon/ssny_tq.png',
        iconSize: new T.Point(40, 50)
      })
      _this.option.meteorological.forEach(item => {
        let marker = new T.Marker(new T.LngLat(item.lng, item.lat), { icon: icon })
        let content = '<div style="font-size: 15px">名称：' + item.name + '</div>'
        content += '<div style="font-size: 15px">代码：' + item.code + '</div>'
        content += '<div style="font-size: 15px">分组代码：' + item.group + '</div>'
        content += '<div style="font-size: 15px">分组名称：' + item.groupName + '</div>'
        content += '<div style="font-size: 15px">环温°：' + item.temperature + ' </div>'
        content += '<div style="font-size: 15px">环湿%RH：' + item.humidity + ' </div>'
        content += '<div style="font-size: 15px">气压hPa：' + item.kPa + ' </div>'
        marker.addEventListener('click', function (e) {
          const point = e.lnglat
          marker = new T.Marker(point)// 创建标注
          const markerInfoWin = new T.InfoWindow(content, { offset: new T.Point(0, -30) }) // 创建信息窗口对象
          _this.tMap.openInfoWindow(markerInfoWin, point) // 开启信息窗口
        })
        _this.tMap.addOverLay(marker)
      })
    },

    /**
     * waterAndFertilizer
     * @description 水肥机点位绘制
     */
    waterAndFertilizer () {
      const _this = this
      const icon = new T.Icon({
        iconUrl: 'https://zhny-002.oss-cn-beijing.aliyuncs.com/korla/icon/ssny_sb.png',
        iconSize: new T.Point(40, 50)
      })
      _this.option.voyagesLnglat.forEach(item => {
        let marker = new T.Marker(new T.LngLat(item.lng, item.lat), { icon: icon })
        let content = '<div style="font-size: 15px">名称：' + item.name + '</div>'
        content += '<div style="font-size: 15px">代码：' + item.code + '</div>'
        content += '<div style="font-size: 15px">分组代码：' + item.group + '</div>'
        content += '<div style="font-size: 15px">分组名称：' + item.groupName + '</div>'
        marker.addEventListener('click', function (e) {
          const point = e.lnglat
          marker = new T.Marker(point)// 创建标注
          const markerInfoWin = new T.InfoWindow(content, { offset: new T.Point(0, -30) }) // 创建信息窗口对象
          _this.tMap.openInfoWindow(markerInfoWin, point) // 开启信息窗口
        })
        _this.tMap.addOverLay(marker)
      })
    },

    /**
     * greenhouses
     * @description 大棚点位绘制
     */
    greenhouses () {
      const _this = this
      const icon = new T.Icon({
        iconUrl: 'https://zhny-002.oss-cn-beijing.aliyuncs.com/korla/icon/ssny_dp.png',
        iconSize: new T.Point(40, 50)
      })
      _this.option.vegetLnglat.forEach(item => {
        let marker = new T.Marker(new T.LngLat(item.lng, item.lat), { icon: icon })
        let content = '<div style="font-size: 15px">名称：' + item.name + '</div>'
        content += '<div style="font-size: 15px">种植品类：' + item.category + '</div>'
        content += '<div style="font-size: 15px">种植面积：' + item.mu + '</div>'
        marker.addEventListener('click', function (e) {
          const point = e.lnglat
          marker = new T.Marker(point)// 创建标注
          const markerInfoWin = new T.InfoWindow(content, { offset: new T.Point(0, -30) }) // 创建信息窗口对象
          _this.tMap.openInfoWindow(markerInfoWin, point) // 开启信息窗口
        })
        _this.tMap.addOverLay(marker)
      })
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/style/main-view13.scss";
</style>
