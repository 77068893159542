/*
 * @Description: 左上下 折线图配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2023-01-09 17:00:22
 */

const differenceData = []
const visibityData = []
const xAxisData = ['2018年', '2019年', '2020年', '2021年', '2022年']

const yearYaoCaiData = [168, 123, 0, 369, 177]
const yearTianCaiData = [11685, 4127, 0, 1092, 727]
const yearLaJiaoData = [4658, 5134, 6255, 5524, 6452]
const yearZiRanData = [234, 265, 250, 299, 313]

for (var i = 0; i < yearZiRanData.length; i++) {
  differenceData.push(yearLaJiaoData[i] - yearZiRanData[i])
  visibityData.push(yearYaoCaiData[i])
}

export default {
  // 添加折线图动态加载
  animationDuration: 10000,
  title: { show: false },
  tooltip: {
    trigger: 'axis',
    backgroundColor: 'rgba(255,255,255,0.2)',
    // 自定义显示
    formatter: function (params) {
      return params[0].name + '<br/>' +
        params[0].seriesName + ' : ' + params[0].value + '<br/>' +
        params[1].seriesName + ' : ' + params[1].value + '<br/>' +
        params[2].seriesName + ' : ' + params[2].value + '<br/>' +
        params[3].seriesName + ' : ' + params[3].value + '<br/>' +
        '<br/>'
    },
    textStyle: {
      color: '#FFF',
      fontSize: 18
    }
  },
  toolbox: { show: false },
  legend: {
    top: 'top',
    textStyle: {
      color: '#B7E2FF',
      fontSize: 18,
      fontFamily: '微软雅黑'
    },
    data: ['甘草', '甜菜', '加工辣椒', '孜然(茴香)']
  },
  grid: {
    top: '25%',
    left: '15%',
    right: '5%',
    bottom: '12%'
  },
  xAxis: {
    data: xAxisData,
    axisLabel: {
      textStyle: { // X轴文字
        color: '#B7E1FF',
        fontSize: 18
      }
    },
    axisLine: { // X轴线
      lineStyle: {
        color: '#09F'
      }
    },
    axisTick: {
      lineStyle: {
        color: '#09F'
      }
    }
  },
  yAxis: {
    name: '产量/吨',
    nameTextStyle: {
      padding: [0, 0, 10, -80], // 四个数字分别为上右下左与原位置距离
      fontSize: 18,
      color: '#B7E1FF'
    },
    inverse: false,
    splitArea: { show: false }, // Y轴横向背景
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: {
      textStyle: { // Y轴字体
        color: '#B7E1FF',
        fontSize: 18,
        fontFamily: 'Arial'
      }
    },
    splitLine: { // Y轴横向轴线
      lineStyle: {
        color: '#09F'
      }
    }
  },
  series: [ // 数据
    {
      name: '甘草',
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 10,
      showAllSymbol: true,
      color: 'blue',
      data: yearYaoCaiData
    },
    {
      name: '甜菜',
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 10,
      showAllSymbol: true,
      color: 'red',
      itemStyle: {
        normal: {
          lineStyle: {
            width: 2
          }
        }
      },
      data: yearTianCaiData
    },
    {
      name: '加工辣椒',
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 10,
      showAllSymbol: true,
      color: '#00ff33',
      itemStyle: {
        normal: {
          lineStyle: {
            width: 2
          }
        }
      },
      data: yearLaJiaoData
    },
    {
      name: '孜然(茴香)',
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 10,
      showAllSymbol: true,
      color: '#F90',
      itemStyle: {
        normal: {
          lineStyle: {
            width: 2
          }
        }
      },
      data: yearZiRanData
    },
    // 线隐藏后设置不可见线 不会因缺少数据变动
    {
      name: '不可见',
      type: 'bar',
      stack: '1',
      barWidth: 1,
      itemStyle: {
        normal: {
          color: 'rgba(0,0,0,0)'
        },
        emphasis: {
          color: 'rgba(0,0,0,0)'
        }
      },
      data: visibityData
    },
    // 两线连接线
    {
      name: '变化',
      type: 'bar',
      stack: '1',
      barWidth: 1,
      color: '#B7E1FF',
      data: differenceData
    }
  ]
}
