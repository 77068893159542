<!--
 * @Description: 右下内容区域
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-22 12:24:05
-->
<template>
  <!-- 轮播表区域 开始 -->
  <div class="chart">
    <dv-scroll-board :config="option"/>
  </div>
  <!-- 轮播表区域 结束 -->
</template>

<script>
import option from './option'
export default {
  name: 'MainBottomRight',
  data () {
    return {
      option
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.chart{
  width: 100%;
  height: 100%;
  margin-left: 3%;
  margin-top: 2%;
}
</style>
