<!--
 * @Description: 中上
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-23 12:09:45
-->
<template>
  <!-- 折线图 开始 -->
  <div>
<!--    <dv-scroll-board :config="option.configTop" style="width: 100%; height: 60px" />-->
    <dv-scroll-board :config="option" style="width: 100%; height: 100%" />
  </div>
  <!-- 折线图 结束 -->
</template>

<script>
import option from './option'
export default {
  name: 'CenterTop',
  data () {
    return {
      option
    }
  },
  // mounted () {
  //   this.tableData()
  // },
  methods: {
    /**
     * tableData
     * @description 轮播表样式设置
     */
    // tableData () {
    //   this.option.configTop = {
    //     data: this.dataUtil(this.option.topData),
    //     rowNum: 1,
    //     evenRowBGC: ';' // 偶数行背景色
    //   }
    //   this.option.config = {
    //     data: this.dataUtil(this.option.data),
    //     rowNum: 6,
    //     oddRowBGC: 'rgba(84,131,197,0.37)', // 奇数行背景色
    //     evenRowBGC: 'rgba(14,32,45,0.37)' // 偶数行背景色
    //   }
    // }
    /**
     * dataUtil
     * @description 轮播表数据填充加样式
     */
    // dataUtil (dataInfo) {
    //   const tableData = []
    //   dataInfo.forEach(data => { // lines是边界经纬度组成的数组
    //     tableData.push([
    //       '<span style="font-size: 24px;color: #B7E2FF;" >' + data[0] + '</span>',
    //       '<span style="font-size: 24px;color: #B7E2FF;" >' + data[1] + '</span>',
    //       '<span style="font-size: 24px;color: #B7E2FF;" >' + data[2] + '</span>',
    //       '<span style="font-size: 24px;color: #B7E2FF;" >' + data[3] + '</span>',
    //       '<span style="font-size: 24px;color: #B7E2FF;" >' + data[4] + '</span>',
    //       '<span style="font-size: 24px;color: #B7E2FF;" >' + data[5] + '</span>'
    //     ])
    //   })
    //   return tableData
    // }
  }
}
</script>

<style scoped lang="scss">
.scroll-board-configTop{
  width: 100%; height: 37.5px
}
.scroll-board-config{
  width: 100%; height: 85%
}
</style>
