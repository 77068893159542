/*
 * @Description: 系统登录接口
 * @Author: Pancras
 * @Date: 2022-01-15 17:05:32
 * @LastEditors: Hlc
 * @LastEditTime: 2022-07-20 10:44:22
 */
import request from '@/api/request'
import qs from 'qs'

export function _SysLogin () {
  const data = {
    userName: '18160582116',
    authCode: 'Lztl@2022.'
  }
  return request({
    url: '/kwp/public/login',
    method: 'post',
    data: qs.stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
    }
  })
}
