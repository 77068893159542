/*
 * @Description: 左上
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
export default {
  topData: [
    ['', '年末存栏量/吨', '年末出栏量吨/万头']
  ],
  data: [
    ['牛', '6.68', '4.03'],
    ['马', '0.22', '0.03'],
    ['驴', '0.33', '0.11'],
    ['羊', '66.95', '95.86']
  ],
  header: ['<span style="font-size: 18px;color: #B7E2FF;" >合计</span>',
    '<span style="font-size: 18px;color: #B7E2FF;" >74.18</span>',
    '<span style="font-size: 18px;color: #B7E2FF;" >70.03</span>'],
  configTop: null,
  config: null,
  configNum: null
}
