<!--
 * @Description: 上中内容区域
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-22 12:22:12
-->
<template>
  <!-- 主体内容区域 开始 -->
  <div flex="box:last">
    <div class="main-top-left-main-left">
      <img src="/image/map41.png" />
    </div>
    <div class="main-top-left-main-right">
      <!-- 描述区域 开始 -->
      <div class="right-card-description">
        <dv-border-box-13 :color="['rgb(14,71,136)', 'rgb(14,71,136)']">
          <div class="right-card-content">
            走向智能化时代，智能决策成为人们争相追捧的新时代特征。人类社会的跨代发展是慢长的，当前阶段还不是真正意义上的信息智能化时代，存在着发展不平衡、不充分的方面或领域。在这样的背景下，自动化与智能化是同步推进的。而且，在很多时候，概念上的智能化，究其本质是自动化。同样，现在很多智能决策的提法，在本质上是自动化决策。当然，智能化是自动化发展的高级阶段。有必要对自动决策和智能决策做一个理性的区分，这样有利于分阶段、分步骤开展相关领域决策科技发展，以推动实现科学决策。<br />
          </div>
        </dv-border-box-13>
      </div>
      <!-- 描述区域 结束 -->
    </div>
  </div>
  <!-- 主体内容区域 结束 -->
</template>

<script>
export default {
  name: 'MainTopMain',
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.main-top-left-main-right {
  width: 457.5px !important;
  margin-left: 22.5px;
  .right-card-description {
    margin-top: 15px;
    height: 510px !important;
    .right-card-content {
      padding: 30px;
      font-size: 21px;
      text-align: left;
      text-indent: 2em;
    }
  }
}
</style>
