/*
 * @Description: 中上 畜牧场等点位数据
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
export default {
  radioMonths: [
    { month: '5月', content: '', size: 'large' },
    { month: '6月', content: '', size: 'large' },
    { month: '7月', content: '', size: 'large' },
    { month: '8月', content: '', size: 'large' },
    { month: '9月', content: '', size: 'large' }
  ],
  radioLnglat: [
    '/image/main-view1/may.png',
    '/image/main-view1/june.png',
    '/image/main-view1/july.png',
    '/image/main-view1/august.png',
    '/image/main-view1/september.png'
  ]
}
