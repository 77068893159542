/*
 * @Description: 左侧下 柱状加折线图配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
import { color } from 'echarts'

// eslint-disable-next-line camelcase
const plan_xAxis = ['香梨', '苹果', '红枣', '杏', '葡萄', '核桃']
// eslint-disable-next-line camelcase
const plan_data1 = [3, 1, 18, 1, 1, 18]
// eslint-disable-next-line camelcase
const plan_data2 = [2.26, 1.08, 17.09, 0.25, 0.69, 16.23]

export default {
  title: { show: false },
  toolbox: { show: false },
  legend: {
    top: 'top',
    textStyle: {
      color: '#B7E2FF',
      fontSize: 18,
      fontFamily: '微软雅黑'
    },
    data: ['计划种植亩数', '实际种植亩数']
  },
  grid: {
    top: '25%',
    left: '10%',
    right: '5%',
    bottom: '12%'
  },
  xAxis: {
    animationDuration: 20000,
    animationDurationUpdate: 20000,
    data: plan_xAxis,
    axisLabel: {
      textStyle: {
        color: '#B7E1FF',
        fontSize: 18
      }
    },
    axisLine: {
      lineStyle: {
        color: '#09F'
      }
    }
  },
  yAxis: {
    name: '万亩',
    nameTextStyle: {
      padding: [0, 0, 10, -45], // 四个数字分别为上右下左与原位置距离
      fontSize: 18,
      color: '#B7E1FF'
    },
    inverse: false,
    splitArea: { show: false }, // Y轴横向背景
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: {
      textStyle: { // Y轴字体
        color: '#B7E1FF',
        fontSize: 18,
        fontFamily: 'Arial'
      }
    },
    splitLine: { // Y轴横向轴线
      lineStyle: {
        color: '#09F'
      }
    }
  },
  tooltip: { // 提示框浮层
    trigger: 'axis',
    backgroundColor: 'rgba(84,131,197,0.7)',
    borderColor: 'rgba(84,131,197,0.55)',
    textStyle: {
      color: '#ffffff',
      fontSize: 18
    }
  },
  series: [
    {
      name: '计划种植亩数',
      type: 'bar',
      itemStyle:
        {
          normal: { color: color[1] },
          emphasis: { color: color[2] }
        },
      barWidth: 30,
      data: plan_data1
    },
    {
      name: '实际种植亩数',
      type: 'line', // 定义样式为折线图
      itemStyle: {
        normal: { // 折点样式
          color: '#F90',
          label: {
            show: true,
            position: 'top',
            textStyle: {
              color: 'rgba(84,131,197,0.83)',
              fontSize: 18
            }
          },
          lineStyle: { // 线样式
            color: '#F90',
            width: 4
          }
        },
        emphasis: { // 光标划过点样式
          color: '#FF0'
        }
      },
      symbolSize: 18,
      data: plan_data2
    }
  ]
}
