<!--
 * @Description: 十四五规划中上
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-17 17:05:40
-->
<template>
  <div class="top-main"
       flex="box:first">
    <!-- 左侧地图 开始 -->
    <div class="left-map">
      <!-- 文字图层 开始 -->
<!--      <img src="/image/wz.png"-->
<!--           class="img wz" />-->
      <!-- 文字图层 开始 -->
      <!-- 闪动涂层 开始 -->
<!--      <img v-show="tabIndex==1"-->
<!--           src="/image/yqgl.png"-->
<!--           class="img img-active" />-->
<!--      <img v-show="tabIndex==2"-->
<!--           src="/image/lygl.png"-->
<!--           class="img img-active" />-->
<!--      <img v-show="tabIndex==3"-->
<!--           src="/image/sdgl.png"-->
<!--           class="img img-active" />-->
<!--      <img v-show="tabIndex==4"-->
<!--           src="/image/sjdgl.png"-->
<!--           class="img img-active" />-->
      <!-- 闪动涂层 结束 -->
    </div>
    <!-- 左侧地图 结束 -->
    <!-- 右侧卡片 开始 -->
    <div class="right-card">
      <!-- 卡片 开始 -->
      <div flex="box:mean">
        <dv-decoration-11 class="right-card-button"
                          :color="['rgb(14,71,136)','rgb(14,71,136)']"
                          @click.native="handleSelectCard(1)">简介</dv-decoration-11>
      </div>
      <!-- 卡片 结束 -->
      <!-- 描述 开始 -->
      <div class="right-card-description">
        <dv-border-box-13 :color="['rgb(14,71,136)','rgb(14,71,136)']">
          <div class="right-card-content">
            {{option.optionData.currentDescription}}
          </div>
        </dv-border-box-13>
      </div>
      <!-- 描述 结束 -->
    </div>
    <!-- 右侧卡片结束 -->
  </div>
</template>

<script>
import option from './option'
export default {
  name: 'MainTop',
  data () {
    return {
      option,
      tabIndex: null// Tab 索引
    }
  },
  methods: {
    /**
     * handleSelectCard
     * @description 选择描述卡片
     */
    handleSelectCard (index) {
      this.tabIndex = index
      option.optionData.currentDescription = option.optionData.descriptionList[index - 1]
    }
  }
}
</script>

<style scoped lang="scss">
.top-main {
  margin-top: 45px;
  height: 60%;
  .left-map {
    position: relative;
    margin-top: -32px;
    margin-left: -37.5px;
    width: 685px;
    height: 505px;
    background-repeat: no-repeat;
    background-size: 685px 505px;
    background-image: url("/image/base-map.png");
    .img {
      width: 528px;
      height: 509px;
    }
    .wz {
      position: absolute;
      top: 0;
      left: 0;
    }
    .img-active {
      animation: twinkling 0.8s infinite ease-in-out;
    }
    @keyframes twinkling {
      0% {
        opacity: 0;
      }
      30% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }
  }
  .right-card {
    width: 610px;
    margin-left: -15px;
    .right-card-button {
      cursor: pointer;
      font-size: 21px;
      font-weight: bold;
      width: 110px;
      height: 60px;
    }
    .right-card-description {
      margin-top: 15px;
      height: 375px !important;
      width: 610px;
      .right-card-content {
        padding: 32px;
        font-size: 21px;
        text-align: left;
        text-indent: 2em;
      }
    }
  }
}
</style>
