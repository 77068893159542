/*
 * @Description: 中下右 柱图配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
import { color } from 'echarts'
// eslint-disable-next-line camelcase
const class_xAxis = ['2019年', '2020年', '2021年'] // 列标
// eslint-disable-next-line camelcase
const class_data1 = [131, 156, 174] // 柱图1数据
// eslint-disable-next-line camelcase
const class_data2 = [17, 18, 20] // 柱图2数据
// eslint-disable-next-line camelcase
const class_data3 = [16, 19, 21] // 柱图3数据
// eslint-disable-next-line camelcase
const class_data4 = [11, 16, 19] // 柱图4数据
// eslint-disable-next-line camelcase
const classType = ['太平洋', '平安', '中华联合', '人民'] // 图例
export default {
  title: { show: false },
  toolbox: { show: false },
  legend: {
    top: 'top',
    textStyle: {
      color: '#B7E2FF',
      fontSize: 15,
      fontFamily: '微软雅黑'
    }
  },
  grid: {
    top: '20%',
    left: '10%',
    right: '5%',
    bottom: '10%'
  },
  xAxis: {
    type: 'category',
    boundaryGap: true,
    data: class_xAxis, // 数据
    axisLabel: {
      interval: 0,
      textStyle: {
        color: '#B7E1FF',
        fontSize: 13.5
      }
    },
    axisLine: {
      lineStyle: {
        color: '#09F'
      }
    }
  },
  yAxis: {
    inverse: false,
    name: '元/亩',
    nameTextStyle: {
      padding: [0, 0, 0, -50], // 四个数字分别为上右下左与原位置距离
      fontSize: 13.5,
      color: '#B7E1FF'
    },
    splitArea: { show: false },
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: {
      textStyle: {
        color: '#B7E1FF',
        fontSize: 18,
        fontFamily: 'Arial'
      }
    },
    splitLine: {
      lineStyle: {
        color: '#09F'
      }
    }
  },
  tooltip: {
    trigger: 'axis',
    backgroundColor: 'rgba(84,131,197,0.7)',
    borderColor: 'rgba(84,131,197,0.55)',
    textStyle: {
      color: '#ffffff',
      fontSize: 18
    }
  }, // 提示框
  series: [
    {
      name: classType[0],
      type: 'bar',
      stack: 'one',
      itemStyle:
      {
        normal: { color: color[1] }
      },
      barWidth: 30,
      data: class_data1
    },
    {
      name: classType[1],
      type: 'bar',
      stack: 'two',
      barGap: '0',
      itemStyle:
      {
        normal: { color: color[1] }
      },
      barWidth: 30,
      data: class_data2
    },
    {
      name: classType[2],
      type: 'bar',
      stack: 'three',
      barGap: '0',
      itemStyle:
      {
        normal: { color: color[1] }
      },
      barWidth: 30,
      data: class_data3
    },
    {
      name: classType[3],
      type: 'bar',
      stack: 'four',
      barGap: '0',
      itemStyle:
      {
        normal: { color: color[1] }
      },
      barWidth: 30,
      data: class_data4
    }
  ]
}
