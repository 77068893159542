<!--
 * @Description: 下4内容区域
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-16 20:02:10
-->
<template>
  <!-- 轮播表区域 开始 -->
  <div class="chart">
    <dv-scroll-board :config="option"/>
  </div>
  <!-- 轮播表区域 结束 -->
</template>

<script>
import option from './option'
export default {
  name: 'MainBottom4',
  data () {
    return {
      option
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.chart{
  height: 99%;
}
</style>
