/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2023-01-09 11:54:09
 * @LastEditors: Pancras
 * @LastEditTime: 2023-01-09 12:32:06
 */
/**
 * dataUtil
 * @description 图表数据填充加样式
 */
function dataUtil (dataInfo) {
  const tableData = []
  dataInfo.forEach(data => {
    tableData.push([
      '<span style="font-size: 18px;color: #B7E2FF;" >' + data[0] + '</span>',
      '<span style="font-size: 18px;color: #B7E2FF;" >' + data[1] + '</span>',
      '<span style="font-size: 18px;color: #B7E2FF;" >' + data[2] + '</span>',
      '<span style="font-size: 18px;color: #B7E2FF;" >' + data[3] + '</span>'
    ])
  })
  return tableData
}

const topData = [
  ['年份', '宏福二胺/万吨', '天业地膜/万吨', 'J206-5/万吨']
]
const data = [
  ['2022', '54.33', '81.48', '54.33'],
  ['2022', '50.48', '78.20', '50.48'],
  ['2022', '44.45', '90.48', '44.45'],
  ['2022', '44.45', '90.48', '44.45']
]

export default {
  /**
   * tableData
   * @description 图表样式设置
   */
  tableData: [
    {
      data: dataUtil(topData),
      rowNum: 1,
      evenRowBGC: 'rgba(84,131,197,0.7)' // 偶数行背景色
    },
    {
      data: dataUtil(data),
      rowNum: 3,
      oddRowBGC: 'rgba(84,131,197,0.55)', // 奇数行背景色
      evenRowBGC: '' // 偶数行背景色
    }
  ]
}
