/*
 * @Description: 中右 锥形柱图配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
export default {
  data: [
    {
      name: '芹菜',
      value: 988
    },
    {
      name: '油菜',
      value: 1730
    },
    {
      name: '菠菜',
      value: 1170
    },
    {
      name: '黄瓜',
      value: 514
    },
    {
      name: '西红柿',
      value: 2910
    },
    {
      name: '生姜',
      value: 0.5
    },
    {
      name: '辣椒',
      value: 5058.5
    },
    {
      name: '黑木耳',
      value: 26.145
    },
    {
      name: '其他蔬菜',
      value: 5360
    }
  ],
  img: [
    '/image/other.png',
    '/image/lajiao.png',
    '/image/xihongshi.png',
    '/image/youcai.png',
    '/image/bocai.png',
    '/image/qincai.png',
    '/image/huanggua.png',
    '/image/muer.png',
    '/image/shengjiang.png'
  ],
  showValue: true,
  fontSize: 15,
  textColor: '#B7E2FF',
  unit: '交易量（万吨）'
}
