/*
 * @Description: 左上图表1配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
// eslint-disable-next-line camelcase
const config1 = {
  lineWidth: 37.5,
  radius: '50%',
  activeRadius: '60%',
  color: ['#ffdd00', '#84f538', '#0095ff', '#00ffff'],
  digitalFlopStyle: {
    fontSize: 18
  },
  showOriginValue: true,
  data: [{
    name: '种植',
    value: 39228
  },
  {
    name: '林果',
    value: 237289
  },
  {
    name: '畜牧',
    value: 29939
  },
  {
    name: '设施',
    value: 9123
  }
  ]
}
const config2 = {
  lineWidth: 37.5,
  radius: '50%',
  activeRadius: '60%',
  color: ['#a6b5ff', 'rgb(84,131,197)', '#00ffff', '#0095ff'],
  digitalFlopStyle: {
    fontSize: 18
  },
  showOriginValue: true,
  data: [{
    name: '生活',
    value: 27362
  },
  {
    name: '生产加工',
    value: 28362
  },
  {
    name: '防疫',
    value: 23372
  },
  {
    name: '供求信息',
    value: 23737
  }
  ]
}
export default {
  config1,
  config2
}
