<!--
 * @Description: 农机管理 上
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-17 19:10:30
-->
<template>
  <!-- 地图 开始 -->
  <dv-border-box-13 :color="['rgb(14,71,136)']"
                    style="width: 1216px; padding: 15px;">
    <div id="kelMap"
         ref="kelMap"
         style="height: 100%;width: 100%; position: relative; "
         class="gray"></div>
  </dv-border-box-13>
  <!-- 地图 结束 -->
</template>

<script>
import option from './option'
export default {
  name: 'MainTop',
  data () {
    return {
      option,
      iconUrl: null
    }
  },
  mounted () {
    // 初始化点位提示信息窗口
    /* eslint-disable no-undef */
    this.infoWin = new T.InfoWindow()
    // 初始化地图
    this.initMap()
  },
  methods: {
    /**
     * initMap
     * @description 初始化地图
     */
    initMap () {
      // 初始化地图
      /* eslint-disable no-undef */
      this.tMap = new T.Map('kelMap', {
        center: new T.LngLat(80.36, 40.65),
        zoom: 12
      })
      this.tMap.setMapType(TMAP_HYBRID_MAP)
      // 移除天地图logo
      document.getElementsByClassName('tdt-control-copyright tdt-control')[0].style.display = 'none'
      // 执行画多点方法
      this.doDrawPoints()
    },

    /**
     * doDrawPoints
     * @description 画多点方法
     */
    doDrawPoints () {
      // 循环点位信息
      for (const farmMachine in option.farmMachineList) {
        // 判断农机状态设置图标地址
        if (option.farmMachineList[farmMachine].code === '0') {
          // 绿色 不在线
          this.iconUrl = 'https://zhny-002.oss-cn-beijing.aliyuncs.com/korla/icon/nj_bzx.png'
        } else if (option.farmMachineList[farmMachine].code === '1') {
          // 红色 行驶
          this.iconUrl = 'https://zhny-002.oss-cn-beijing.aliyuncs.com/korla/icon/nj_zx.png'
        } else if (option.farmMachineList[farmMachine].code === '2') {
          // 黄色 停车
          this.iconUrl = 'https://zhny-002.oss-cn-beijing.aliyuncs.com/korla/icon/nj_tc.png'
        }
        // 创建点位提示信息
        this.marker = new T.Marker(
          new T.LngLat(option.farmMachineList[farmMachine].lng, option.farmMachineList[farmMachine].lat),
          {
            icon: new T.Icon({
              iconUrl: this.iconUrl,
              iconSize: new T.Point(40, 50)
            })
          }
        )
        // 地图添加点位提示弹窗
        this.tMap.addOverLay(this.marker)
        this.MarkerAddInfo(this.marker, option.farmMachineList[farmMachine])
      }
    },
    // 设置点位信息弹窗内容
    MarkerAddInfo (mk, farmMachine) {
      const _this = this
      // 信息弹窗添加点击监听，并设置弹窗内容
      mk.addEventListener('click', function () {
        let content = '<div style="font-size: 18px">终端设备号：' + farmMachine.tnumber + '</div>'
        content += '<div style="font-size: 18px">农机号牌：' + farmMachine.p25 + '</div>'
        content += '<div style="font-size: 18px">车主姓名：' + farmMachine.p8 + '</div>'
        content += '<div style="font-size: 18px">车主电话：' + farmMachine.p7 + '</div>'
        content += '<div style="font-size: 18px">车机类型：' + farmMachine.p1 + '</div>'
        content += '<div style="font-size: 18px">车辆用途：' + farmMachine.p2 + '</div>'
        content += '<div style="font-size: 18px">机具类型：' + farmMachine.p3 + '</div>'
        content += '<div style="font-size: 18px">今日作业面积(亩)：' + farmMachine.p16 + '</div>'
        content += '<div style="font-size: 18px">作业深度(厘米)：' + farmMachine.p17 + '</div>'
        content += '<div style="font-size: 18px">机具宽度(米)：' + farmMachine.p18 + '</div>'
        content += '<div style="font-size: 18px">达标深度(厘米)：' + farmMachine.p22 + '</div>'
        if (farmMachine.picTime != null) {
          content += '<div style="font-size: 15px">采集时间：' + farmMachine.picTime + '</div>'
          content += '<div><img src=' + farmMachine.pic + ' style="width: 100%;"/></div>'
        }
        _this.infoWin.setContent(content)
        // 打开弹窗
        mk.openInfoWindow(_this.infoWin)
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
