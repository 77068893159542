/*
 * @Description: 下上右上 折线图配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */

const differenceData = []
const visibityData = []
const xAxisData = ['2017年', '2018年', '2019年', '2020年', '2021年']

const cottonData = [5303.55, 5503.55, 6703.55, 5700.55, 6800.99]
const pearData = [1206.66, 3806.66, 3506.66, 5200, 7894]
const cowData = [1211, 2211, 4211, 5622.5, 6817.30]
const sheepData = [1655, 2055, 3055, 3755, 4781.3]

for (var i = 0; i < sheepData.length; i++) {
  differenceData.push(cottonData[i] - sheepData[i])
  visibityData.push(pearData[i])
}

export default {
  // 添加折线图动态加载
  animationDuration: 10000,
  title: { show: false },
  tooltip: {
    trigger: 'axis',
    backgroundColor: 'rgba(255,255,255,0.2)',
    // 自定义显示
    formatter: function (params) {
      return params[0].name + '<br/>' +
        params[0].seriesName + ' : ' + params[0].value + '<br/>' +
        params[1].seriesName + ' : ' + params[1].value + '<br/>' +
        params[2].seriesName + ' : ' + params[2].value + '<br/>' +
        params[3].seriesName + ' : ' + params[3].value + '<br/>' +
        '<br/>'
    },
    textStyle: {
      color: '#FFF',
      fontSize: 18
    }
  },
  toolbox: { show: false },
  legend: {
    top: 'top',
    textStyle: {
      color: '#B7E2FF',
      fontSize: 18,
      fontFamily: '微软雅黑'
    },
    data: ['棉花', '香梨', '牛', '羊']
  },
  grid: {
    top: '20%',
    left: '15%',
    right: '5%',
    bottom: '30%'
  },
  xAxis: {
    data: xAxisData,
    axisLabel: {
      textStyle: { // X轴文字
        color: '#B7E1FF',
        fontSize: 18
      }
    },
    axisLine: { // X轴线
      lineStyle: {
        color: '#09F'
      }
    },
    axisTick: {
      lineStyle: {
        color: '#09F'
      }
    }
  },
  yAxis: {
    name: '吨',
    nameTextStyle: {
      padding: [0, 0, -3, -40], // 四个数字分别为上右下左与原位置距离
      fontSize: 18,
      color: '#B7E1FF'
    },
    inverse: false,
    splitArea: { show: false }, // Y轴横向背景
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: {
      textStyle: { // Y轴字体
        color: '#B7E1FF',
        fontSize: 18,
        fontFamily: 'Arial'
      }
    },
    splitLine: { // Y轴横向轴线
      lineStyle: {
        color: '#09F'
      }
    }
  },
  series: [ // 数据
    {
      name: '棉花',
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 7.5,
      showAllSymbol: true,
      color: 'blue',
      data: cottonData
    },
    {
      name: '香梨',
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 7.5,
      showAllSymbol: true,
      color: 'red',
      itemStyle: {
        normal: {
          lineStyle: {
            width: 1
          }
        }
      },
      data: pearData
    },
    {
      name: '牛',
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 7.5,
      showAllSymbol: true,
      color: '#00ff33',
      itemStyle: {
        normal: {
          lineStyle: {
            width: 1
          }
        }
      },
      data: cowData
    },
    {
      name: '羊',
      type: 'line',
      smooth: true,
      symbol: 'circle',
      symbolSize: 7.5,
      showAllSymbol: true,
      color: '#F90',
      itemStyle: {
        normal: {
          lineStyle: {
            width: 1
          }
        }
      },
      data: sheepData
    },
    // 线隐藏后设置不可见线 不会因缺少数据变动
    {
      name: '不可见',
      type: 'bar',
      stack: '1',
      barWidth: 1,
      itemStyle: {
        normal: {
          color: 'rgba(0,0,0,0)'
        },
        emphasis: {
          color: 'rgba(0,0,0,0)'
        }
      },
      data: visibityData
    },
    // 两线连接线
    {
      name: '变化',
      type: 'bar',
      stack: '1',
      barWidth: 1,
      color: '#B7E1FF',
      data: differenceData
    }
  ]
}
