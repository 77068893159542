/*
 * @Description: Axios 请求封装
 * @Author: Pancras
 * @Date: 2022-01-15 17:05:38
 * @LastEditors: Hlc
 * @LastEditTime: 2022-05-30 19:58:00
 */
import axios from 'axios'
import util from '@/libs/util.js'

// 创建 Axios 实例
const service = axios.create({
  baseURL: 'http://zhny.kenyte.com',
  timeout: 10000
})

service.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

// 请求拦截
service.interceptors.request.use(

  config => {
    // 获取 Token
    const token = util.getToken()
    // 如果需要携带 token
    if (token) {
      config.headers.token = token
    }
    return config
  },
  error => {
    console.log(error)
  }
)

// 响应拦截
service.interceptors.response.use(
  response => {
    const res = response.data
    const { code } = res
    // 当 Code 不为 0，发生业务异常
    if (code !== 0) {
      return res
    }
    return res
  }
)

export default service
