<!--
 * @Description: 林果种植中上
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-17 17:04:49
-->
<template>
  <dv-border-box-13 :color="['rgb(14,71,136)']" style="width: 1216px; padding: 15px;">
    <div id="kelMap"
         ref="kelMap"
         class="gray map_style">
    </div>
    <div class="cropGrowth">
      <el-timeline class="timeline">
        <el-timeline-item class="timeline-item"
          v-for="(radio, index) in option.radioMonths"
          :key="index"
          :timestamp="radio.content"
          @click.native="getRadio(index)"
          :class="[radioActive == index ? 'active-time-item':'']"
          :size="radio.size">
          {{ radio.month }}
        </el-timeline-item>
      </el-timeline>
    </div>
  </dv-border-box-13>
</template>

<script>
import option from './option'
export default {
  name: 'MainTop',
  data () {
    return {
      option,
      tMap: null,
      radioActive: ''
    }
  },
  mounted () {
    // 初始化地图
    this.initMap()
  },
  methods: {
    /**
     * initMap
     * @description 初始化地图
     */
    initMap () {
      // 初始化地图
      /* eslint-disable no-undef */
      this.tMap = new T.Map('kelMap', {
        center: new T.LngLat(80.36, 40.63),
        zoom: 11
      })
      this.tMap.setMapType(TMAP_HYBRID_MAP)
      this.getRadio(0)
      // 移除天地图logo
      document.getElementsByClassName('tdt-control-copyright tdt-control')[0].style.display = 'none'
    },
    getRadio (index) {
      const bd = new T.LngLatBounds(new T.LngLat(80.25247, 40.6644), new T.LngLat(80.39982, 40.56526))
      this.fbimg = new T.ImageOverlay(option.radioLnglat[index], bd, {
        opacity: 1
      })
      this.tMap.addOverLay(this.fbimg)
      this.radioActive = index
    }
  }
}
</script>

<style scoped lang="scss">
.timeline{
  width: 90px;
  z-index: 3;
  margin-top: -38%;
}
.timeline-item{
  z-index: 3;
}

.map_style{
  z-index: 2;
  height: 100%;
  width: 100%;
  position: relative;
}
</style>
<style>
.cropGrowth .el-timeline-item__content {
  z-index: 3;
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size: 18px;
  margin-top: -4px;
}
.cropGrowth .el-timeline-item__node{
  left: -8px;
  width: 20px;
  height: 20px;
  background-color: rgb(255, 255, 255);
}
.cropGrowth .el-timeline-item__tail{
  position: absolute;
  left: 1px;
  height: 100%;
  border-left: 3px solid rgb(255, 255, 255);
}
.cropGrowth .active-time-item .el-timeline-item__node {
  left: -8px;
  width: 20px;
  height: 20px;
  background-color: rgb(1, 103, 175);
}
.cropGrowth .active-time-item  .el-timeline-item__wrapper{
  position: relative;
  width: 36px;
  height: 18px;
  padding: 12px;
  border-radius: 10%;
  margin-left: 30px;
  background-color: rgba(1, 103, 175);
}
.cropGrowth .active-time-item .el-timeline-item__content {
  color: #B7E2FF;
  margin-left: 0px;
  font-size: 18px;
  text-shadow: 0 0 black;
}
</style>
