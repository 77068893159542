/*
 * @Description: 下右轮播表配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
// eslint-disable-next-line camelcase
export default {
  align: 'center',
  waitTime: 1000, // 轮播时间
  oddRowBGC: ';font-size: 18px;color: #B7E2FF;', // 奇数行背景色
  evenRowBGC: ';font-size: 18px;color: #B7E2FF;', // 偶数行背景色
  rowNum: 8, // 轮播表行数
  // headerBGC: ';border:0px solid #069;font-size: 24px;color: #B7E2FF;', // 表头背景色
  // header: ['编号', '数据类型', '数据值', '时间'],
  columnWidth: [120, 200, 160, 240], // 每列宽度
  // 表格内容
  data: [
    ['u78', '传感器数据', '163.28', '2022年01月01日'],
    ['006', '无人机数据', '130.67', '2022年01月02日'],
    ['u78', '控制器数据', '158.28', '2022年01月03日'],
    ['s07', '无人机数据', '155.28', '2022年01月04日'],
    ['u78', '监视器数据', '123.28', '2022年01月05日'],
    ['s07', '控制器数据', '163.28', '2022年01月06日'],
    ['u78', '控制器数据', '163.28', '2022年01月07日'],
    ['u78', '监视器数据', '163.28', '2022年01月08日'],
    ['u78', '无人机数据', '135.65', '2022年01月09日'],
    ['s07', '监视器数据', '163.28', '2022年01月10日'],
    ['u78', '控制器数据', '127.25', '2022年01月11日'],
    ['u78', '无人机数据', '136.58', '2022年01月12日'],
    ['s07', '无人机数据', '163.28', '2022年01月13日'],
    ['u78', '控制器数据', '269.28', '2022年01月14日'],
    ['s07', '无人机数据', '163.28', '2022年01月15日'],
    ['u78', '控制器数据', '152.24', '2022年01月16日'],
    ['872', '监视器数据', '130.67', '2022年01月17日']
  ]
}
