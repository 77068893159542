/*
 * @Description: 左上 计数器配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-21 06:00:14
 */
const config1 = {
  number: [18939],
  content: '{nt} 台',
  style: {
    fontSize: 27
  }
}

const config2 = {
  number: [64],
  content: '{nt} 家',
  style: {
    fontSize: 27
  }
}

const config3 = {
  number: [1458],
  content: '{nt} 台',
  style: {
    fontSize: 27
  }
}

export default {
  config1,
  config2,
  config3
}
