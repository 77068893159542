/*
 * @Description: 程序入口
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-03-31 21:10:29
 */
import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import dataV from '@jiaminghi/data-view'
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import VueAwesomeSwiper from 'vue-awesome-swiper' // swiper组件
import 'video.js/dist/video-js.css'
import {
  CanvasRenderer
} from 'echarts/renderers'
import {
  PieChart
} from 'echarts/charts'
import {
  TitleComponent,
  GridComponent,
  TooltipComponent
} from 'echarts/components'
import 'flex.css'

use([
  CanvasRenderer,
  TitleComponent,
  GridComponent,
  TooltipComponent,
  PieChart
])
Vue.use(VueAwesomeSwiper)
Vue.use(dataV)
Vue.use(ElementUI)
Vue.config.productionTip = false

// 全局注册组件 Echarts
Vue.component('v-chart', ECharts)

new Vue({
  render: h => h(App)
}).$mount('#app')
