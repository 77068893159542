/*
 * @Description: 左上图表1配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
// eslint-disable-next-line camelcase

export default {
  tooltip: { // 提示框
    trigger: 'item',
    backgroundColor: 'rgba(84,131,197,0.7)',
    borderColor: 'rgba(84,131,197,0.55)',
    textStyle: {
      color: '#B7E1FF',
      fontSize: 18
    },
    formatter: '{a} <br/>{b} : {c}亩 ({d}%)'
  },
  legend: { // 图例
    left: 'left',
    top: 'center',
    textStyle: {
      color: '#B7E1FF',
      fontSize: 18
    },
    orient: 'vertical'
  },
  series: [ // 数据数组
    {
      name: '林果种植数据',
      type: 'pie', // 定义生成图表类型
      radius: ['40%', '80%'],
      center: ['55%', '50%'],
      itemStyle: { // 扇形分区样式
        normal: {
          label: { // 标线样式
            show: true,
            formatter: '{b} :{c}万亩',
            textStyle: {
              color: '#B7E1FF',
              fontSize: 15,
              fontWeight: '300'
            }
          },
          // 标线长度，宽度
          labelLine: {
            show: true,
            length: 7.5,
            lineStyle: {
              width: 2
            }
          }
        },
        emphasis: { // 光标划过时样式
          shadowBlur: 7.5,
          shadowOffsetX: 0,
          shadowColor: 'rgba(84,131,197,0.83)'
        }
      },
      emphasis: { // 光标划过时标线样式
        label: {
          show: true,
          fontSize: '18',
          fontWeight: 'bold'
        }
      },
      data: [
        { value: 2.26, name: '香梨' },
        { value: 1.08, name: '苹果' },
        { value: 0.25, name: '杏' },
        { value: 0.69, name: '葡萄' },
        { value: 16.23, name: '核桃' },
        { value: 17.09, name: '红枣' }
      ]
    }
  ]
}
