/*
 * @Description: 左上下 叠形柱状图
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
export default {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {
    textStyle: {
      color: '#B7E2FF',
      fontSize: 18,
      fontFamily: '微软雅黑'
    }
  },
  grid: {
    top: '10%',
    left: '23%',
    right: '5%',
    bottom: '10%'
  },
  xAxis: {
    type: 'value',
    axisLabel: {
      textStyle: { // Y轴字体
        color: '#B7E1FF',
        fontSize: 18,
        fontFamily: 'Arial'
      }
    }
  },
  yAxis: {
    name: '个',
    nameTextStyle: {
      padding: [0, 0, 0, -30], // 四个数字分别为上右下左与原位置距离
      fontSize: 16.5,
      color: '#B7E1FF'
    },
    axisLabel: {
      textStyle: { // Y轴字体
        color: '#B7E1FF',
        fontSize: 18,
        fontFamily: 'Arial'
      }
    },
    type: 'category',
    data: ['农机检测设备', '电磁阀', '土壤墒情仪', '虫情测报仪']
  },
  series: [
    {
      name: '工作中',
      type: 'bar',
      stack: 'total',
      label: {
        show: true
      },
      emphasis: {
        focus: 'series'
      },
      data: [2, 0, 68, 0]
    },
    {
      name: '总装备数',
      type: 'bar',
      stack: 'total',
      label: {
        show: true
      },
      emphasis: {
        focus: 'series'
      },
      data: [56, 100, 2, 70]
    }
  ]
}
