/*
 * @Description: 侧右中 折线图配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2023-01-09 17:17:44
 */
// 农机补贴
// eslint-disable-next-line camelcase
const class_xAxis = ['2016', '2017', '2018', '2019', '2020', '2021', '2022'] // 列标
// eslint-disable-next-line camelcase
const class_data = [11.21 * 0.69, 11.13 * 0.69, 11.56 * 0.69, 11.79 * 0.69, 12.5 * 0.69, 13.3 * 0.69, 13.4 * 0.69] // 柱图1数据
// eslint-disable-next-line camelcase
const class_data1 = [36.54 * 0.5, 36.97 * 0.5, 37.33 * 0.5, 38.15 * 0.5, 39.7 * 0.5, 40.26 * 0.5, 42.3 * 0.5] // 柱图2数据
// eslint-disable-next-line camelcase
const class_data2 = [1.17 * 6, 1.23 * 6, 1.11 * 6, 1.24 * 6, 1.44 * 6, 1.49 * 6, 2.55 * 6] // 柱图2数据
const classType = ['棉花', '香梨', '肉类'] // 图例
export default {
  animationDuration: 10000,
  title: { show: false },
  toolbox: { show: false },
  legend: {
    top: 'top',
    textStyle: {
      color: '#B7E2FF',
      fontSize: 15,
      fontFamily: '微软雅黑'
    },
    data: classType
  },
  grid: {
    top: '20%',
    left: '10%',
    right: '5%',
    bottom: '30%'
  },
  xAxis: {
    type: 'category',
    boundaryGap: true,
    data: class_xAxis, // 数据
    axisLabel: {
      interval: 0,
      textStyle: {
        color: '#B7E1FF',
        fontSize: 13.5
      }
    },
    axisLine: {
      lineStyle: {
        color: '#09F'
      }
    }
  },
  yAxis: {
    inverse: false,
    name: '亿元',
    nameTextStyle: {
      padding: [0, 0, 0, -50], // 四个数字分别为上右下左与原位置距离
      fontSize: 13.5,
      color: '#B7E1FF'
    },
    splitArea: { show: false },
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: {
      textStyle: {
        color: '#B7E1FF',
        fontSize: 18,
        fontFamily: 'Arial'
      }
    },
    splitLine: {
      lineStyle: {
        color: '#09F'
      }
    }
  },
  tooltip: {
    trigger: 'axis',
    backgroundColor: 'rgba(84,131,197,0.7)',
    borderColor: 'rgba(84,131,197,0.55)',
    textStyle: {
      color: '#ffffff',
      fontSize: 18
    }
  }, // 提示框
  series: [
    {
      name: classType[0],
      type: 'line',
      itemStyle: {
        normal: {
          color: '#0095ff',
          lineStyle: {
            color: '#0095ff',
            width: 3
          }
        },
        emphasis: {
          color: '#0095ff'
        }
      },
      symbolSize: 9,
      data: class_data
    },
    {
      name: classType[1],
      type: 'line',
      itemStyle: {
        normal: {
          color: '#84f538',
          lineStyle: {
            color: '#84f538',
            width: 3
          }
        },
        emphasis: {
          color: '#84f538'
        }
      },
      symbolSize: 9,
      data: class_data1
    },
    {
      name: classType[2],
      type: 'line',
      itemStyle: {
        normal: {
          color: '#ffdd00',
          lineStyle: {
            color: '#ffdd00',
            width: 3
          }
        },
        emphasis: {
          color: '#ffdd00',
          width: 2
        }
      },
      symbolSize: 9,
      data: class_data2
    }
  ]
}
