/*
 * @Description: 中下 圆环图配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
const config1 = {
  radius: '60%',
  activeRadius: '70%',
  lineWidth: 22.5,
  digitalFlopStyle: {
    fontSize: 18
  },
  color: ['rgb(18,104,133)', 'rgb(61, 231, 201)'],
  data: [
    {
      name: '关闭',
      value: 14
    },
    {
      name: '开启',
      value: 0
    }
  ],
  showOriginValue: true
}

const config2 = {
  radius: '60%',
  activeRadius: '70%',
  lineWidth: 22.5,
  digitalFlopStyle: {
    fontSize: 18
  },
  color: ['rgb(18,104,133)', 'rgb(61, 231, 201)'],
  data: [
    {
      name: '关闭',
      value: 66
    },
    {
      name: '开启',
      value: 0
    }
  ],
  showOriginValue: true
}

const config3 = {
  radius: '60%',
  activeRadius: '70%',
  lineWidth: 22.5,
  digitalFlopStyle: {
    fontSize: 18
  },
  color: ['rgb(18,104,133)', 'rgb(61, 231, 201)'],
  data: [
    {
      name: '关闭',
      value: 6
    },
    {
      name: '开启',
      value: 0
    }
  ],
  showOriginValue: true
}

const config4 = {
  radius: '60%',
  activeRadius: '70%',
  lineWidth: 22.5,
  digitalFlopStyle: {
    fontSize: 18
  },
  color: ['rgb(18,104,133)', 'rgb(61, 231, 201)'],
  data: [
    {
      name: '关闭',
      value: 6
    },
    {
      name: '开启',
      value: 0
    }
  ],
  showOriginValue: true
}

const config5 = {
  radius: '60%',
  activeRadius: '70%',
  lineWidth: 22.5,
  digitalFlopStyle: {
    fontSize: 18
  },
  color: ['rgb(18,104,133)', 'rgb(61, 231, 201)'],
  data: [
    {
      name: '关闭',
      value: 500
    },
    {
      name: '开启',
      value: 0
    }
  ],
  showOriginValue: true
}

export default {
  config1,
  config2,
  config3,
  config4,
  config5
}
