<!--
 * @Description: 上左
 * @Author: Pancras
 * @Date: 2022-01-21 01:27:42
 * @LastEditors: Pancras
 * @LastEditTime: 2023-01-09 13:17:28
-->
<template>
  <div flex="box:mean">
    <!--  左一-->
    <div class="card"
         flex="box:first">
      <img src="/image/main-view3/total.png" />
      <div class="card-main">
        <!-- <div class="card-main-title">总交易额</div>
        <div class="card-main-count">{{option.countList[1]}}</div> -->
        <div class="card-main-title">农产品交易额</div>
        <div class="card-main-count">2.2亿</div>
      </div>
    </div>
    <!--  左二-->
    <div class="card"
         flex="box:first">
      <img src="/image/main-view3/turnover.png" />
      <div class="card-main">
        <!-- <div class="card-main-title">总成交量</div>
        <div class="card-main-count" >{{option.countList[1]}}</div> -->
        <div class="card-main-title">农资交易额</div>
        <div class="card-main-count">9126万</div>
      </div>
    </div>
  </div>
</template>

<script>
import option from './option'

export default {
  name: 'MainTopLeft',
  data () {
    return {
      option
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  text-align: left;
  img {
    width: 52.5px;
    height: 52.5px;
  }
  .card-main {
    margin-left: 22.5px;
    .card-main-title {
      font-size: 12px;
    }
    .card-main-count {
      font-size: 27px;
      color: rgb(216, 202, 6);
    }
  }
}
</style>
