<!--
 * @Description: 右上上
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-21 05:59:46
-->
<template>
  <div flex="dir:left box:mean">
    <div flex="dir:top box:first">
      <div class="count-card-title">农产品溯源</div>
      <!-- 计数器 开始 -->
      <dv-digital-flop :config="sourceList[0]"/>
      <!-- 计数器 结束 -->
    </div>
    <div flex="dir:top box:first">
      <div class="count-card-title">畜牧产品溯源</div>
      <!-- 计数器 开始 -->
      <dv-digital-flop :config="sourceList[1]"/>
      <!-- 计数器 结束 -->
    </div>
  </div>
</template>

<script>
import option from './option'

export default {
  name: 'RightTopTop',
  data () {
    return {
      sourceList: option.sourceList
    }
  },
  mounted () {
  },
  methods: {
    /**
     * tableData
     * @description 图表样式设置
     */

  }
}
</script>

<style scoped lang="scss">
.count-card {
  // text-align: left;
  .count-card-title {
    font-size: 16.5px;
    height: 22.5px !important;
  }
}
</style>
