<!--
 * @Description: 十四五规划中左
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-16 19:51:32
-->
<template>
  <!-- 轮播表 开始 -->
  <dv-border-box-8 :dur="9">
    <dv-scroll-board :config="option.configTop" class="scroll-board-configTop" />
    <dv-scroll-board :config="option.config" class="scroll-board-config" />
  </dv-border-box-8>
  <!-- 轮播表 结束 -->
</template>

<script>
import option from './option'
export default {
  name: 'MainLeft',
  data () {
    return {
      option
    }
  },
  mounted () {
    this.tableData()
  },
  methods: {
    /**
     * tableData
     * @description 轮播表样式设置
     */
    tableData () {
      this.option.configTop = {
        data: this.dataUtil(this.option.topData),
        rowNum: 1,
        columnWidth: this.option.columnWidth,
        evenRowBGC: ';border:1px solid #069;' // 偶数行背景色
      }
      this.option.config = {
        data: this.dataUtil(this.option.data),
        rowNum: 5,
        columnWidth: this.option.columnWidth,
        oddRowBGC: ';border:1px solid #069;', // 奇数行背景色
        evenRowBGC: ';border:1px solid #069;' // 偶数行背景色
      }
    },

    /**
     * dataUtil
     * @description 轮播表数据填充加样式
     */
    dataUtil (dataInfo) {
      const tableData = []
      dataInfo.forEach(data => {
        tableData.push([
          '<span style="font-size: 18px;color: #B7E2FF;" >' + data[0] + '</span>',
          '<span style="font-size: 18px;color: #B7E2FF;" >' + data[1] + '</span>',
          '<span style="font-size: 18px;color: #B7E2FF;" >' + data[2] + '</span>',
          '<span style="font-size: 18px;color: #B7E2FF;" >' + data[3] + '</span>',
          '<span style="font-size: 18px;color: #B7E2FF;" >' + data[4] + '</span>',
          '<span style="font-size: 18px;color: #B7E2FF;" >' + data[5] + '</span>'
        ])
      })
      return tableData
    }
  }
}
</script>

<style scoped lang="scss">
.scroll-board-configTop{
  width: 100%; height: 40px
}
.scroll-board-config{
  width: 100%; height: 85%
}
</style>
