<!--
 * @Description: 数据一张图左上上
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-15 19:27:54
-->
<template>
  <div class="top-container">
    <div class="top-title">{{ topTitleYear }}年/亩</div>
    <div flex="box:mean">
      <div class="chart-container">
        <!-- 圆环图1 开始 -->
        <v-chart class="chart" :option="option.optionConfig1" />
        <!-- 圆环图1 结束 -->
      </div>
      <div class="chart-container">
        <!-- 圆环图2 开始 -->
        <v-chart class="chart" :option="option.optionConfig2" />
        <!-- 圆环图2 结束 -->
      </div>
      <div class="chart-container">
        <!-- 圆环图3 开始 -->
        <v-chart class="chart" :option="option.optionConfig3" />
        <!-- 圆环图3 结束 -->
      </div>
      <div class="chart-container">
        <!-- 圆环图4 开始 -->
        <v-chart class="chart" :option="option.optionConfig4" />
        <!-- 圆环图4 结束 -->
      </div>
    </div>
  </div>
</template>

<script>
import option from './option'
export default {
  name: 'AsideLeftTopTop',
  data () {
    return {
      intervalId: null,
      topTitleYear: option.optionData[0][0],
      option
    }
  },
  mounted () {
    this.dataRefreh()
  },
  methods: {
    /**
     * dataRefreh
     * @description 定时刷新数据函数
     */
    dataRefreh () {
      // 计时器正在进行中，退出函数
      if (this.intervalId != null) {
        return
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
        this.setTitleYear()
        this.replaceOption(option)
      }, 5000)
    },

    /**
     * clear
     * @description 停止定时器
     */
    clear () {
      clearInterval(this.intervalId) // 清除计时器
      this.intervalId = null // 设置为null
    },

    /**
     * setTitleYear
     * @description 设置标题年份
     */
    setTitleYear () {
      if (this.topTitleYear >= option.optionData[0][0] && this.topTitleYear < option.optionData[4][0]) {
        this.topTitleYear = this.topTitleYear + 1
      } else if (this.topTitleYear === option.optionData[4][0]) {
        this.topTitleYear = option.optionData[0][0]
      }
    },

    /**
     * replaceOption
     * @description 替换每年种植数据
     */
    replaceOption (option) {
      for (const key in option.optionData) {
        if (option.optionData[key][0] === this.topTitleYear) {
          option.optionConfig1.title.subtext = option.optionData[key][1]
          option.optionConfig2.title.subtext = option.optionData[key][2]
          option.optionConfig3.title.subtext = option.optionData[key][3]
          option.optionConfig4.title.subtext = option.optionData[key][4]
          // 圆环比例计算
          option.optionConfig1.series[0].data[0].value = option.optionData[key][1]
          option.optionConfig1.series[0].data[1].value = 1500000 - option.optionData[key][1]
          option.optionConfig2.series[0].data[0].value = option.optionData[key][2]
          option.optionConfig2.series[0].data[1].value = 1500000 - option.optionData[key][2]
          option.optionConfig3.series[0].data[0].value = option.optionData[key][3]
          option.optionConfig3.series[0].data[1].value = 200000 - option.optionData[key][3]
          option.optionConfig4.series[0].data[0].value = option.optionData[key][4]
          option.optionConfig4.series[0].data[1].value = 200000 - option.optionData[key][4]
        }
      }
    }
  },
  destroyed () {
    // 在页面销毁后，清除计时器
    this.clear()
  }
}
</script>

<style scoped lang="scss">
.top-container {
  width: 100%;
  height: 100%;
}
.chart {
  width: 140px;
  height: 140px;
  margin-top: 22.5px;
  margin-left: 4px;
}
.top-title {
  margin-top: 7.5px;
}
</style>
