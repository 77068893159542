<!--
 * @Description: 上3内容区域
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-16 20:02:10
-->
<template>
  <!-- 轮播表 开始 -->
  <div class="chart">
    <dv-scroll-board :config="option"/>
  </div>
  <!-- 轮播表 结束 -->
</template>

<script>
import option from './option'
export default {
  name: 'MainTop3',
  data () {
    return {
      option,
      config: null
    }
  },
  mounted () {
    this.tableData()
  },
  methods: {
    /**
     * tableData
     * @description 图表样式设置
     */
    tableData () {
      this.option.data = this.dataUtil(this.option.data)
      this.config = this.option
    },

    /**
     * dataUtil
     * @description 图表数据填充加样式
     */
    dataUtil (dataInfo) {
      const tableData = []
      dataInfo.forEach(data => { // lines是边界经纬度组成的数组
        if (data[2] === '预警') {
          tableData.push([
            data[0],
            data[1],
            '<span style="color: red;" >' + data[2] + '</span>'
          ])
        } else {
          tableData.push([
            data[0],
            data[1],
            '<span style="color: greenyellow;" >' + data[2] + '</span>'
          ])
        }
      })
      return tableData
    }
  }
}
</script>

<style scoped lang="scss">
.chart{
  height: 98%;
}
</style>
