<!--
 * @Description: 下上左下
 * @Author: Pancras
 * @Date: 2022-01-21 01:27:42
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-21 04:17:13
-->
<template>
  <div flex="dir:left box:mean">
    <!-- 左饼图区域 开始 -->
    <v-chart class="chart" :option="option.config1" />
    <!-- 左饼图区域 结束 -->
    <!-- 右饼图区域 开始 -->
    <v-chart class="chart" :option="option.config2" />
    <!-- 右饼图区域 结束 -->
  </div>
</template>

<script>
import option from './option'
export default {
  name: 'MainBottomTopLeftBottom',
  data () {
    return {
      option
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped  lang="scss">
.lable-tag {
  height: 22.5px;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.lable-item {
  margin: 5px;
  font-size: 18px;
}
</style>
