/*
 * @Description: 右下 柱状图配置右
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-21 06:00:14
 */
import { color } from 'echarts'
// 农机补贴
// eslint-disable-next-line camelcase
const class_xAxis = ['检测'] // 图例
// eslint-disable-next-line camelcase
const class_data = [86] // 柱图1数据
// eslint-disable-next-line camelcase
const class_data1 = [93] // 柱图2数据
// eslint-disable-next-line camelcase
const class_data2 = [75] // 柱图2数据
// eslint-disable-next-line camelcase
const class_data3 = [87] // 柱图2数据
// eslint-disable-next-line camelcase
const class_data4 = [91] // 柱图2数据
// eslint-disable-next-line camelcase
const classType = ['农药残留', '土壤检测', '肥料检测', '水检测', '农药检测']// 列标
export default {
  title: { show: false },
  toolbox: { show: false },
  grid: {
    top: '10%',
    left: '20%',
    right: '5%',
    bottom: '10%'
  },
  xAxis: {
    type: 'category',
    boundaryGap: true,
    data: class_xAxis, // 数据
    axisLabel: {
      interval: 0,
      textStyle: {
        color: '#B7E1FF',
        fontSize: 18
      }
    },
    axisLine: {
      lineStyle: {
        color: '#09F'
      }
    }
  },
  yAxis: {
    inverse: false,
    name: '数量',
    nameTextStyle: {
      padding: [0, 0, 0, -40], // 四个数字分别为上右下左与原位置距离
      fontSize: 13.5,
      color: '#B7E1FF'
    },
    splitArea: { show: false },
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: {
      textStyle: {
        color: '#B7E1FF',
        fontSize: 18,
        fontFamily: 'Arial'
      }
    },
    splitLine: {
      lineStyle: {
        color: '#09F'
      }
    }
  },
  tooltip: {
    trigger: 'axis',
    backgroundColor: 'rgba(84,131,197,0.7)',
    borderColor: 'rgba(84,131,197,0.55)',
    textStyle: {
      color: '#ffffff',
      fontSize: 18
    }
  }, // 提示框
  series: [
    {
      name: classType[0],
      type: 'bar',
      stack: 'one',
      itemStyle:
        {
          normal: { color: color[1] }
        },
      barWidth: 30,
      data: class_data
    },
    {
      name: classType[1],
      type: 'bar',
      stack: 'two',
      barGap: '0%',
      itemStyle:
        {
          normal: { color: color[1] }
        },
      barWidth: 30,
      data: class_data1
    },
    {
      name: classType[2],
      type: 'bar',
      stack: 'three',
      barGap: '0%',
      itemStyle:
        {
          normal: { color: color[1] }
        },
      barWidth: 30,
      data: class_data2
    },
    {
      name: classType[3],
      type: 'bar',
      stack: 'four',
      barGap: '0%',
      itemStyle:
        {
          normal: { color: color[1] }
        },
      barWidth: 30,
      data: class_data3
    },
    {
      name: classType[4],
      type: 'bar',
      stack: 'five',
      barGap: '0%',
      itemStyle:
        {
          normal: { color: color[1] }
        },
      barWidth: 30,
      data: class_data4
    }
  ]
}
