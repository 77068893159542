<!--
 * @Description: 下1内容区域
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-16 20:02:02
-->
<template>
  <!-- 柱状图 开始 -->
  <v-chart class="chart" :option="option" />
  <!-- 柱状图 结束 -->
</template>

<script>
import option from './option'
export default {
  name: 'MainBottom1',
  data () {
    return {
      option
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
</style>
