<!--
 * @Description: 中下右
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-23 19:55:19
-->
<template>
  <!-- 轮播图 开始 -->
  <div>
    <swiper :options="swiperOption">
      <!-- 轮播页签1 开始 -->
      <swiper-slide>
        <div flex="dir:left box:mean main:center cross:center">
          <div class="card-box" flex="dir:top cross:center">
            <div class="card-date">2021-5-27</div>
            <div class="title-top">新</div>
            <div class="card-school">新疆大学理工学院</div>
            <div class="team-head" flex="dir:left main:center box:mean">
              <img src="/image/main-view4/head.png" alt="" />
              <img src="/image/main-view4/head.png" alt="" />
              <img src="/image/main-view4/head.png" alt="" />
            </div>
            <div class="team-title">丰农团队</div>
            <div class="team-company">新疆绿洲驼铃农业发展有限公司</div>
          </div>
          <div class="card-box" flex="dir:top cross:center">
            <div class="card-date">2021-5-27</div>
            <div class="title-top">新</div>
            <div class="card-school">新疆农业大学</div>
            <div class="team-head" flex="dir:left main:center box:mean">
              <img src="/image/main-view4/head.png" alt="" />
              <img src="/image/main-view4/head.png" alt="" />
              <img src="/image/main-view4/head.png" alt="" />
            </div>
            <div class="team-title">智农团队</div>
            <div class="team-company">新疆绿洲驼铃农业发展有限公司</div>
          </div>
        </div>
      </swiper-slide>
      <!-- 轮播页签1 结束 -->
      <!-- 轮播页签2 开始 -->
      <swiper-slide>
        <div flex="dir:left box:mean main:center cross:center">
          <div class="card-box" flex="dir:top cross:center">
            <div class="card-date">2021-5-27</div>
            <div class="title-top">新</div>
            <div class="card-school">新疆大学理工学院</div>
            <div class="team-head" flex="dir:left main:center box:mean">
              <img src="/image/main-view4/head.png" alt="" />
              <img src="/image/main-view4/head.png" alt="" />
              <img src="/image/main-view4/head.png" alt="" />
            </div>
            <div class="team-title">聚丰团队</div>
            <div class="team-company">新疆绿洲驼铃农业发展有限公司</div>
          </div>
          <div class="card-box" flex="dir:top cross:center">
            <div class="card-date">2021-5-27</div>
            <div class="title-top">昌</div>
            <div class="card-school">昌吉学院</div>
            <div class="team-head" flex="dir:left main:center box:mean">
              <img src="/image/main-view4/head.png" alt="" />
              <img src="/image/main-view4/head.png" alt="" />
              <img src="/image/main-view4/head.png" alt="" />
            </div>
            <div class="team-title">长绒棉团队</div>
            <div class="team-company">新疆绿洲驼铃农业发展有限公司</div>
          </div>
        </div>
      </swiper-slide>
      <!-- 轮播页签2 结束 -->
    </swiper>
  </div>
  <!-- 轮播图 结束 -->
</template>

<script>
export default {
  name: 'CenterBottomRight',
  data () {
    return {
      swiperOption: { // swiper3
        autoplay: 3000,
        speed: 1000
      }
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.card-box {
  padding: 15px;
  margin: 0px 11px;
  height: 100%;
  border: 1px solid rgb(17, 87, 167);
  .card-date {
    width: 100%;
    font-size: 16.5px;
    color: rgb(36, 88, 160);
    text-align: left;
  }
  .title-top {
    text-align: center;
    margin-top: 15px;
    width: 52.5px;
    height: 52.5px;
    line-height: 52.5px;
    font-size: 22.5px;
    background-color: #046de6;
    border-radius: 50%;
  }
  .card-school {
    margin-top: 10px;
    font-size: 18px;
  }
  .team-head {
    margin-top: 10px;
  }
  .team-head img {
    width: 22.5px;
    height: 22.5px;
    margin: 0px 4px;
  }
  .team-title {
    font-size: 28px;
    margin-top: 10px;
  }
  .team-company {
    font-size: 15px;
    margin-top: 10px;
  }
}
</style>
