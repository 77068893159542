<!--
 * @Description: 主菜单1
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-23 22:32:18
-->
<template>
  <!-- 大屏容器 开始 -->
  <div class="container"
       flex="dir:top box:last">
    <!-- 上 开始 -->
    <div flex="box:justify"
         class="main-top">
      <!-- 上1 开始 -->
      <div flex="dir:top box:first"
           class="main-top-1">
        <div class="container-title title">综合数据</div>
        <MainTop1 class="container-main"></MainTop1>
      </div>
      <!-- 上1 结束 -->
      <!-- 上2 开始 -->
      <div flex="dir:top box:first"
           class="main-top-2">
        <div class="container-title title">相关信息检索</div>
        <MainTop2 class="container-main"></MainTop2>
      </div>
      <!-- 上2 结束 -->
      <!-- 上3 开始 -->
      <div flex="dir:top box:first"
           class="main-top-3">
        <div class="container-title title link"
             @click="goUrl('https://rp.mockplus.cn/run/DQ3yl5C0H72WJQ/rjyP0L_A_8An/IWlwEothF?nav=0&cps=hide&rps=collapse&ha=0&la=0&fc=0&out=0')">
          预警记录·</div>
        <MainTop3 class="container-main"></MainTop3>
      </div>
      <!-- 上3 结束 -->
    </div>
    <!-- 上 结束 -->
    <!-- 下 开始 -->
    <div flex="box:justify"
         class="main-bottom">
      <!-- 下1 开始 -->
      <div flex="dir:top box:first"
           class="main-bottom-1">
        <div class="container-title title">综合数据条目</div>
        <MainBottom1 class="container-main"></MainBottom1>
      </div>
      <!-- 下1 结束 -->
      <!-- 下2 开始 -->
      <div flex="dir:top box:first"
           class="main-bottom-2">
        <div class="container-title title">信息占比图</div>
        <MainBottom2 class="container-main"></MainBottom2>
      </div>
      <!-- 下2 结束 -->
      <!-- 下3 开始 -->
      <div flex="dir:top box:first"
           class="main-bottom-3">
        <div class="container-title title">决策意见生成</div>
        <MainBottom3 class="container-main"></MainBottom3>
      </div>
      <!-- 下3 结束 -->
      <!-- 下4 开始 -->
      <div flex="dir:top box:first"
           class="main-bottom-4">
        <div class="container-title title">调整记录</div>
        <MainBottom4 class="container-main"></MainBottom4>
      </div>
      <!-- 下4 结束 -->
    </div>
    <!-- 下 结束 -->
  </div>
  <!-- 大屏容器 结束 -->
</template>

<script>
import MainTop1 from './components/main-top1/index.vue'
import MainTop2 from './components/main-top2/index.vue'
import MainTop3 from './components/main-top3/index.vue'
import MainBottom1 from './components/main-bottom1/index.vue'
import MainBottom2 from './components/main-bottom2/index.vue'
import MainBottom3 from './components/main-bottom3/index.vue'
import MainBottom4 from './components/main-bottom4/index.vue'

export default {
  name: 'MainContainer2',
  components: {
    MainTop1,
    MainTop2,
    MainTop3,
    MainBottom1,
    MainBottom2,
    MainBottom3,
    MainBottom4
  },
  methods: {
    /**
     * goUrl
     * @description 链接跳转
     */
    goUrl (url) {
      window.open(
        url,
        '_blank'
      )
    }
  }
}
</script>

<style lang="scss" scoped>
// 注册样式
@import "~@/assets/style/main-view42.scss";
</style>
