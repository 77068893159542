/*
 * @Description: 左上图表1配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
// eslint-disable-next-line camelcase
export default {
  align: 'center',
  waitTime: 1000, // 轮播时间
  oddRowBGC: ';font-size: 18px;color: #B7E2FF;', // 奇数行背景色
  evenRowBGC: ';font-size: 18px;color: #B7E2FF;', // 偶数行背景色
  rowNum: 8, // 轮播表行数
  columnWidth: [260, 120, 120, 120, 120], // 列宽
  data: [
    ['2022年01月01日', '畜牧存栏', '21145', '———>', '21235'],
    ['2022年01月01日', '畜牧存栏', '21145', '———>', '21235'],
    ['2022年01月01日', '畜牧存栏', '21145', '———>', '21235'],
    ['2022年01月01日', '畜牧存栏', '21145', '———>', '21235'],
    ['2022年01月01日', '畜牧存栏', '21145', '———>', '21235'],
    ['2022年01月01日', '畜牧存栏', '21145', '———>', '21235'],
    ['2022年01月01日', '畜牧存栏', '21145', '———>', '21235'],
    ['2022年01月01日', '畜牧存栏', '21145', '———>', '21235'],
    ['2022年01月01日', '畜牧存栏', '21145', '———>', '21235'],
    ['2022年01月01日', '畜牧存栏', '21145', '———>', '21235']
  ]
}
