<!--
 * @Description: 中右
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-15 13:02:03
-->
<template>
  <div style="width: 100%; height: 100%" flex="dir:left">
    <!-- 锥形柱图 开始 -->
    <dv-conical-column-chart :config="option" style="width:100%;height:100%;" />
    <!-- 锥形柱图 结束 -->
    <!-- 右侧标签 开始 -->
    <div class="label-tag" flex="dir:top box:mean">
      <div class="label-item" flex="dir:left">
        <span>{{option.unit}}</span>
      </div>
    </div>
    <!-- 右侧标签 结束 -->
  </div>
</template>

<script>
import option from './option'
export default {
  name: 'MainRight',
  data () {
    return {
      option
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.label-item span{
  width:75px;
  font-size: 18px
}
</style>
