<!--
 * @Description: 畜牧养殖
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-21 00:07:08
-->
<template>
<!-- 主容器 开始 -->
  <div class="main-container"
       flex="dir:top box:first">
    <div class="main-container-main"
         flex="dir:top box:last">
         <!-- 上 开始 -->
      <div class="main-top">
        <div class="main-top-container">
          <main-top></main-top>
        </div>
      </div>
       <!-- 上 结束 -->
       <!-- 下 开始 -->
      <div class="main-bottom"
           flex="box:mean">
           <!-- 下左 开始 -->
        <div class="main-bottom-left-container"
             flex="dir:top box:first">
          <div class="main-bottom-left-container-title title">畜牧养殖数据</div>
          <div class="main-bottom-left-container-main">
            <main-left></main-left>
          </div>
        </div>
        <!-- 下左 结束 -->
        <!-- 下右 开始 -->
        <div class="main-bottom-right-container"
             flex="dir:top box:first">
          <div class="main-bottom-right-container-title title">畜牧交易数据</div>
          <div class="main-bottom-right-container-main">
            <main-right></main-right>
          </div>
        </div>
        <!-- 下右 结束 -->
      </div>
      <!-- 下 结束 -->
    </div>
  </div>
  <!-- 主容器 结束 -->
</template>

<script>
import MainTop from './components/main-top/index.vue'
import MainLeft from './components/main-left/index.vue'
import MainRight from './components/main-right/index.vue'
export default {
  name: 'MainContainer4',
  components: {
    MainTop,
    MainLeft,
    MainRight
  }
}
</script>

<style lang="scss"  scoped>
// 注册样式
@import "~@/assets/style/main-view1.scss";
</style>
