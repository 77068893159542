<!--
 * @Description: 上1内容区域
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-23 18:51:53
-->
<template>
  <div flex="dir:top box:mean">
    <div flex="main:center cross:center"
         class="top-card">信息总数
      <div class="top-count">9</div>
      <div class="top-count">8</div>
      <div class="top-count">3</div>
      <div class="top-count">4</div>
      <div class="top-count">8</div> 条
    </div>
    <div flex="box:mean"
         class="bottom-card">
      <div flex="dir:top box:first"
           class="card-main">
        <div class="card-main-title">棉花</div>
        <div class="card-main-count">38702条</div>
      </div>
      <div flex="dir:top box:first"
           class="card-main">
        <div class="card-main-title">粮食</div>
        <div class="card-main-count">3721条</div>
      </div>
      <div flex="dir:top box:first"
           class="card-main">
        <div class="card-main-title">香梨</div>
        <div class="card-main-count">2873条</div>
      </div>
      <div flex="dir:top box:first"
           class="card-main">
        <div class="card-main-title">农机</div>
        <div class="card-main-count">3724条</div>
      </div>
      <div flex="dir:top box:first"
           class="card-main">
        <div class="card-main-title">农资</div>
        <div class="card-main-count">3789条</div>
      </div>
      <div flex="dir:top box:first"
           class="card-main">
        <div class="card-main-title">农产品</div>
        <div class="card-main-count">38374条</div>
      </div>
      <div flex="dir:top box:first"
           class="card-main">
        <div class="card-main-title">其他</div>
        <div class="card-main-count">8374条</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainTop1',
  data () {
    return {
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.top-card {
  font-size: 27px;
  .top-count {
    display: inline-block;
    height: 52.5px;
    line-height: 52.5px;
    padding: 22.5px;
    margin: 0 11px;
    font-size: 43.5px;
    font-weight: bold;
    background: rgb(28, 52, 159);
  }
}
.bottom-card {
  padding: 0px 37.5px;
  font-size: 24px;

  .card-main {
    width: 75px;
    height: 112.5px;
    margin: 0px 15px;
    border: 2px solid rgb(28, 52, 159);
    .card-main-title {
      height: 37.5px !important;
      line-height: 37.5px;
      background: rgb(28, 52, 159);
    }
    .card-main-count {
      font-weight: bold;
      line-height: 75px;
    }
  }

  .card-main:nth-child(1) .card-main-count {
    color: rgb(120, 61, 209);
  }
  .card-main:nth-child(2) .card-main-count {
    color: rgb(132, 180, 80);
  }
  .card-main:nth-child(3) .card-main-count {
    color: rgb(80, 104, 211);
  }
  .card-main:nth-child(4) .card-main-count {
    color: rgb(209, 187, 43);
  }
  .card-main:nth-child(5) .card-main-count {
    color: rgb(224, 94, 82);
  }
  .card-main:nth-child(6) .card-main-count {
    color: rgb(44, 146, 219);
  }
  .card-main:nth-child(7) .card-main-count {
    color: rgb(202, 115, 44);
  }
}
</style>
