<!--
 * @Description: 产学研
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-22 21:08:04
-->
<template>
  <!-- 大屏容器 开始 -->
  <div class="container"
       flex="dir:left box:justify">
    <!-- 左侧 开始 -->
    <div flex="dir:top box:last"
         class="aside-left">
      <!-- 左侧上 开始 -->
      <div flex="dir:top box:first"
           class="aside-left-top">
        <div class="container-title title">项目列表</div>
        <AsideLeftTop class="container-main"></AsideLeftTop>
      </div>
      <!-- 左侧上 结束 -->
      <!-- 左侧下 开始 -->
      <div flex="dir:top box:first"
           class="aside-left-bottom">
        <div class="container-title title">专利列表</div>
        <AsideLeftBottom class="container-main"></AsideLeftBottom>
      </div>
      <!-- 左侧下 结束 -->
    </div>
    <!-- 左侧结束 -->
    <!-- 中间 开始 -->
    <div flex="dir:top box:last"
         class="aside-center">
      <!-- 中间上 开始 -->
      <div flex="dir:top box:first"
           class="aside-center-top">
        <div class="container-title title">科研项目数据</div>
        <CenterTop class="container-main"></CenterTop>
      </div>
      <!-- 中间上 结束 -->
      <!-- 中间下 开始 -->
      <div flex="box:mean"
           class="aside-center-bottom">
        <!-- 中间下左 开始 -->
        <div flex="dir:top box:first"
             class="aside-center-bottom-left">
          <div class="container-title title">项目/专利收益</div>
          <CenterBottomLeft class="container-main"></CenterBottomLeft>
        </div>
        <!-- 中间下左 结束 -->
        <!-- 中间下右 开始 -->
        <div flex="dir:top box:first"
             class="aside-center-bottom-right">
          <div class="container-title title">孵化成果</div>
          <CenterBottomRight class="container-main"></CenterBottomRight>
        </div>
        <!-- 中间下右 结束 -->
      </div>
      <!-- 中间下 结束 -->
    </div>
    <!-- 中间 结束 -->
    <!-- 右侧 开始 -->
    <div flex="dir:top box:first"
         class="aside-right">
      <div class="container-title title">高校科研成果列表</div>
      <AsideRight class="container-main"></AsideRight>
    </div>
    <!-- 右侧 结束 -->
  </div>
  <!-- 大屏容器 结束 -->
</template>

<script>
import AsideLeftTop from './components/aside-left-top/index.vue'
import AsideLeftBottom from './components/aside-left-bottom/index.vue'
import CenterTop from './components/center-top/index.vue'
import CenterBottomLeft from './components/center-bottom-left/index.vue'
import CenterBottomRight from './components/center-bottom-right/index.vue'
import AsideRight from './components/aside-right/index.vue'

export default {
  name: 'MainContainer4',
  components: {
    AsideLeftTop,
    AsideLeftBottom,
    CenterTop,
    CenterBottomLeft,
    CenterBottomRight,
    AsideRight
  }
}
</script>

<style lang="scss" scoped>
// 注册样式
@import "~@/assets/style/main-view34.scss";
</style>
