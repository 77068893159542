<!--
 * @Description: 十四五规划中右
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-16 19:51:46
-->
<template>
  <!-- 柱状加折线图 开始 -->
  <v-chart class="chart" :option="option.optionConfig" />
  <!-- 柱状加折线图 结束 -->
</template>

<script>
import option from './option'
export default {
  name: 'MainRight',
  data () {
    return {
      intervalId: null,
      option
    }
  },
  mounted () {
    this.dataRefreh()
  },
  methods: {
    /**
     * dataRefreh
     * @description 定时刷新数据函数
     */
    dataRefreh () {
      // 计时器正在进行中，退出函数
      if (this.intervalId != null) {
        return
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
        this.replaceOption(option.optionConfig, option.newShowData)
      }, 5000)
    },

    /**
     * clear
     * @description 停止定时器
     */
    clear () {
      clearInterval(this.intervalId) // 清除计时器
      this.intervalId = null // 设置为null
    },
    /**
     * replaceOption
     * @description 替换数据
     */
    replaceOption (optionConfig, newShowData) {
      const dataLength = optionConfig.xAxis.data.length
      if (dataLength !== 9) {
        optionConfig.xAxis.data.push(newShowData[dataLength].year)
        optionConfig.series[0].data.push(newShowData[dataLength].bar)
        optionConfig.series[1].data.push(newShowData[dataLength].line)
        return
      }
      if (dataLength === 9 && optionConfig.xAxis.data[8] === 2021) {
        optionConfig.xAxis.data.splice(1, dataLength)
        optionConfig.series[0].data.splice(1, dataLength)
        optionConfig.series[1].data.splice(1, dataLength)
      }
    }
  },
  destroyed () {
    // 在页面销毁后，清除计时器
    this.clear()
  }
}

</script>

<style scoped lang="scss"></style>
