<!--
 * @Description: 下左左内容区域
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-16 19:51:54
-->
<template>
  <div class="leftBottom">
    <img class="land_img" src="/image/main-view4/down2.png" alt=""/>
    <div class="land_data">
      <p>10cm 温度1℃湿度30%</p>
      <p>20cm 温度15℃湿度46%</p>
      <p>30cm 温度30℃湿度67%</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainBottonLeftLeft',
  data () {
    return {
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.land_img{
  width: 80%;
  height: 90%;
  margin-top: 2%;
  margin-right: 2%;
}
.leftBottom {
  display: inline-block;
  position: relative;
  font-size: 18px;
  color: #0EFCFF;
}
.leftBottom .land_data p {
  position: absolute;
  left: 36%;
  top: 7%;
}
.land_data p:nth-child(2) {
  top: 18%;
  left: 42%;
}
.land_data p:nth-child(3) {
  top: 28%;
  left: 53%;
}
</style>
