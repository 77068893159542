<!--
 * @Description: 农事决策页面
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-23 22:32:12
-->
<template>
  <!-- 布局容器 开始 -->
  <div class="container"
       flex="dir:top box:last">
    <!-- 上容器 开始 -->
    <div flex="box:justify"
         class="main-top">
      <!-- 上左内容容器 开始 -->
      <div flex="dir:top box:first"
           class="main-top-left">
        <div class="main-top-left-title title">棉花病虫害预警</div>
        <MainTopLeft class="main-top-left-main"></MainTopLeft>
      </div>
       <!-- 上左内容容器 结束 -->
       <!-- 上中内容容器 开始-->
      <MainTopMain class="main-top-main"></MainTopMain>
      <!-- 上中内容容器 结束-->
      <!-- 上右内容容器 开始 -->
      <div flex="dir:top box:last"
           class="main-top-right">
        <!-- 上右上内容容器 开始 -->
        <div flex="dir:top box:first"
             class="main-top-right-top">
          <div class="main-top-right-top-title title">硬件设备展示</div>
          <MainTopRightTop class="main-top-right-top-main"></MainTopRightTop>
        </div>
        <!-- 上右上内容容器 结束 -->
        <!-- 上右中内容容器 开始 -->
        <div flex="dir:top box:first"
             class="main-top-right-bottom">
          <div class="main-top-right-bottom-title title">灌溉数据</div>
          <MainTopRightBottom class="main-top-right-bottom-main"></MainTopRightBottom>
        </div>
        <!-- 上右中内容容器 结束 -->
      </div>
      <!-- 上右内容容器 结束 -->
    </div>
    <!-- 上容器 结束 -->
    <!-- 下容器 开始 -->
    <div flex="box:last"
         class="main-bottom">
      <div flex="box:justify"
           class="main-bottom-left">
        <!-- 下左左容器 开始 -->
        <div flex="dir:top box:first"
             class="main-bottom-left-left">
          <div class="main-bottom-left-left-title title">统计数据</div>
          <MainBottomLeftLeft class="main-bottom-left-left-main"></MainBottomLeftLeft>
        </div>
        <!-- 下左左容器 结束 -->
        <!-- 下中左容器 开始 -->
        <div flex="dir:top box:first"
             class="main-bottom-left-center">
          <div class="main-bottom-left-center-title title link"
               @click="goUrl('http://yypt.kenyte.com/plant/phaseMgr?scgtorUuid=d17ab5c8-61cb-48b7-93e2-f547c77e2a0d')">
            生长数据·</div>
          <MainBottomLeftCenter class="main-bottom-left-center-main"></MainBottomLeftCenter>
        </div>
        <!-- 下中左容器 结束 -->
        <!-- 下中右容器 开始 -->
        <div flex="dir:top box:first"
             class="main-bottom-left-right">
          <div class="main-bottom-left-right-title title">气象数据</div>
          <MainBottomLeftRight class="main-bottom-left-right-main"></MainBottomLeftRight>
        </div>
        <!-- 下中右容器 结束 -->
      </div>
      <!-- 下右容器 开始 -->
      <div flex="dir:top box:first"
           class="main-bottom-right">
        <div class="main-bottom-right-title title">数据日志</div>
        <MainBottomRight class="main-bottom-right-main"></MainBottomRight>
      </div>
      <!-- 下右容器结束 -->
    </div>
    <!-- 下容器 结束 -->
  </div>
  <!-- 布局容器 结束 -->
</template>

<script>
import MainTopLeft from './components/main-top-left/index.vue'
import MainTopMain from './components/main-top-main/index.vue'
import MainTopRightTop from './components/main-top-right-top/index.vue'
import MainTopRightBottom from './components/main-top-right-bottom/index.vue'
import MainBottomLeftLeft from './components/main-bottom-left-left/index.vue'
import MainBottomLeftCenter from './components/main-bottom-left-center/index.vue'
import MainBottomLeftRight from './components/main-bottom-left-right/index.vue'
import MainBottomRight from './components/main-bottom-right/index.vue'

export default {
  name: 'MainContainer1',
  components: {
    MainTopLeft,
    MainTopMain,
    MainTopRightTop,
    MainTopRightBottom,
    MainBottomLeftLeft,
    MainBottomLeftCenter,
    MainBottomLeftRight,
    MainBottomRight
  },
  methods: {
    /**
     * goUrl
     * @description 链接跳转
     */
    goUrl (url) {
      window.open(
        url,
        '_blank'
      )
    }
  }
}
</script>

<style scoped lang="scss">
// 注册样式
@import "~@/assets/style/main-view41.scss";
</style>
