/*
 * @Description: 中进度池配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
export default {
  localGradient: true, // 局部渐变
  value: 33, // 进度值
  borderWidth: 2, // 边框宽度
  borderRadius: 10, // 边框圆弧度
  borderGap: 5 // 边界间隙
}
