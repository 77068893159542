/*
 * @Description: 用一句话描述文件的作用
 * @Author: Pancras
 * @Date: 2022-03-31 20:49:29
 * @LastEditors: Pancras
 * @LastEditTime: 2022-03-31 20:50:25
 */
import request from '@/api/request'

// 摄像头 API 接口
export function loadPreviewURL () {
  return request({
    url: '/amic-adp/kdi/cameraPoint/findAll',
    method: 'get'
  })
}

export function loadPreviewURLByCode (cameraIndexCode) {
  return request({
    url: '/amic-adp/kdi/cameraPoint/getPreviewURL',
    method: 'get',
    params: {
      cameraIndexCode,
      streamType: 0,
      protocol: 'hls',
      streamform: 'ps',
      expand: 'transcode=0'
    }
  })
}
