const util = {}

// 获取 SessionStorage 对象
const SessionStorage = window.sessionStorage

/**
  * getToken
  * @description 从 Session 中获取用户 Token
  * @return {Object}   返回用户 Token
*/
util.getToken = () => {
  let token
  try {
    token = SessionStorage.getItem('token')
    return token
  } catch (ex) {
    token = undefined
    return token
  }
}

/**
  * setToken
  * @description 将 Token 存储至 Session
  * @return {Object}   返回用户 Token
*/
util.setToken = (token) => {
  SessionStorage.setItem('token', token)
}

/**
  * isNull
  * @description 自动识别对象类型并判断是否为空
  * @param {Object} val 需要判断的对象
  * @return {Boolean}   返回 true 或 false
  * @example 调用示例
  *  let isNull = util.isNull(user)
*/
util.isNull = (val) => {
  if (val instanceof Array) {
    if (val.length === 0) return true
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') return true
  } else {
    if (val === 'null' || val === null || val === 'undefined' || val === undefined || val === '') return true
    return false
  }
  return false
}

/**
  * looseEqual
  * @description 判断值是否相等(宽松)
  * @param {Objec}  value1
  * @param {Objec}  value2
  * @return {Boolean}
*/
util.looseEqual = (a, b) => {
  const isObjectA = util.isObject(a)
  const isObjectB = util.isObject(b)
  if (isObjectA && isObjectB) {
    return JSON.stringify(a) === JSON.stringify(b)
  } else if (!isObjectA && !isObjectB) {
    return String(a) === String(b)
  } else {
    return false
  }
}

/**
  * isObject
  * @description 判断是否是对象
  * @param { Object}  obj
  * @return {Boolean}
*/
util.isObject = (obj) => {
  return Object.prototype.toString.call(obj) === '[object Object]'
}

/**
  * arrayEquals
  * @description 判断数组是否相等
  * @param {Array}    arrayA
  * @param {Array}    arrayB
  * @return {Boolean}
*/
util.arrayEquals = (arrayA, arrayB) => {
  arrayA = arrayA || []
  arrayB = arrayB || []
  if (arrayA.length !== arrayB.length) {
    return false
  }
  for (let i = 0; i < arrayA.length; i++) {
    if (!util.looseEqual(arrayA[i], arrayB[i])) {
      return false
    }
  }
  return true
}

/**
  * isEqual
  * @description 判断值是否相等
  * @param {Objec}  value1
  * @param {Objec}  value2
  * @return {Boolean}
*/
util.isEqual = (value1, value2) => {
  if (Array.isArray(value1) && Array.isArray(value2)) {
    return util.arrayEquals(value1, value2)
  }
  return util.looseEqual(value1, value2)
}

export default util
