<!--
 * @Description: 土地利用页面
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-21 00:06:46
-->
<template>
  <!-- 主容器 开始 -->
  <div class="main-container" flex="dir:top box:first">
    <div class="main-container-main" flex="dir:top box:last">
      <!-- 中上 开始 -->
      <div class="main-top">
        <div class="main-top-container">
          <!-- 地图区域 开始 -->
          <main-top ref="sonTop"></main-top>
          <!-- 地图区域 结束 -->
          <!-- 选择框 开始 -->
          <div class="dv-map">
            <div class="dv-map-content">
              <div class="redio-panel">
                <input
                  @click="oncheck"
                  type="radio"
                  name="sex"
                  value="gbznt"
                  checked
                />
                高标准农田
              </div>
              <div class="redio-panel">
                <input @click="oncheck" type="radio" name="sex" value="mhfb" />
                棉花分布
              </div>
              <div class="redio-panel">
                <input
                  @click="oncheck"
                  type="radio"
                  name="sex"
                  disabled
                  value="tdjs"
                />
                退地减水
              </div>
            </div>
          </div>
          <!-- 选择框 结束 -->
        </div>
      </div>
      <!-- 中上 结束 -->
      <!-- 中下 开始 -->
      <div class="main-bottom" flex="box:mean">
        <!-- 中左 开始 -->
        <div class="main-bottom-left-container" flex="dir:top box:first">
          <div class="main-bottom-left-container-title title">土地利用数据</div>
          <div class="main-bottom-left-container-main">
            <main-left></main-left>
          </div>
        </div>
        <!-- 中左 结束 -->
        <!-- 中右 开始 -->
        <div class="main-bottom-right-container" flex="dir:top box:first">
          <div class="main-bottom-right-container-title title">
            土地流转数据
          </div>
          <div class="main-bottom-right-container-main">
            <main-right></main-right>
          </div>
        </div>
        <!-- 中右 结束 -->
      </div>
    </div>
    <!-- 中下 结束 -->
  </div>
  <!-- 主容器 开始 -->
</template>

<script>
import MainTop from './components/main-top/index.vue'
import MainLeft from './components/main-left/index.vue'
import MainRight from './components/main-right/index.vue'

export default {
  name: 'MainContainer2',
  components: {
    MainTop,
    MainLeft,
    MainRight
  },
  data () {
    return {
      radio: ''
    }
  },
  methods: {
    oncheck (e) {
      this.$refs.sonTop.oncheck(e.target.defaultValue)
    }
  }
}
</script>

<style lang="scss"  scoped>
// 注册样式
@import '~@/assets/style/main-view1.scss';

.dv-map {
  margin-right: 30px;
  margin-top: 15px;
}
.dv-map-content {
  opacity: 0.75;
  background-color: rgb(9, 36, 73);
  width: 150px;
  height: 165px;
  padding: 0px;
  text-align: left;
  .redio-panel {
    font-size: 18px;
    padding: 15px;
    border-bottom: 1px solid rgb(20, 51, 94);
    input {
      transform: scale(2, 2);
    }
  }
  .redio-panel:hover {
    background-color: rgb(16, 48, 94);
  }
}
</style>
