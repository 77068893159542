<!--
 * @Description: 下2内容区域
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-16 20:02:06
-->
<template>
  <div flex="dir:top">
    <!-- 圆环图主体区域 开始 -->
    <div flex="dir:left box:mean" style="height: 80%">
      <dv-active-ring-chart :config="option.config1" />
      <dv-active-ring-chart :config="option.config2" />
    </div>
    <!-- 圆环图主体区域 结束 -->
    <!-- 圆环图标签主体区域 开始 -->
    <div flex="dir:left">
      <div flex="dir:left" class="chart-tip">
        <span>种植</span>
        <div style="background-color: #ffdd00;"></div>
      </div>
      <div flex="dir:left" class="chart-tip">
        <span>林果</span>
        <div style="background-color: #84f538;"></div>
      </div>
      <div flex="dir:left" class="chart-tip">
        <span>畜牧</span>
        <div style="background-color: #0095ff;"></div>
      </div>
      <div flex="dir:left" class="chart-tip">
        <span>设施</span>
        <div style="background-color: #00ffff;"></div>
      </div>
      <div flex="dir:left" class="chart-tip">
        <span>生活</span>
        <div style="background-color: #a6b5ff;"></div>
      </div>
      <div flex="dir:left" class="chart-tip">
        <span>生产加工</span>
        <div style="background-color: rgb(84,131,197);"></div>
      </div>
      <div flex="dir:left" class="chart-tip">
        <span>防疫</span>
        <div style="background-color: #00ffff;"></div>
      </div>
      <div flex="dir:left" class="chart-tip">
        <span>供求信息</span>
        <div style="background-color: #0095ff;"></div>
      </div>
    </div>
    <!-- 圆环图标签主体区域 結束 -->
  </div>
</template>

<script>
import option from './option'
export default {
  name: 'MainBottom2',
  data () {
    return {
      option
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.chart-tip{
  margin: 5px;
}
.chart-tip span{
  width: 50%
}
.chart-tip div{
  width: 30px;
  height: 30px;
  margin-top: 7.5px
}
</style>
