/*
 * @Description: 左下 轮播表配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
// eslint-disable-next-line camelcase
export default {
  align: 'center',
  fontSize: 18,
  headerHeight: 37.5,
  waitTime: 2000, // 轮播时间
  oddRowBGC: ';font-size: 18px;color: #B7E2FF;', // 奇数行背景色
  evenRowBGC: 'rgba(84,131,197,0.29);font-size: 18px;color: #B7E2FF;', // 偶数行背景色
  rowNum: 8, // 轮播表行数
  headerBGC: ';background-color:rgba(84,131,197,0.83);font-size: 18px;color: #B7E2FF;', // 表头背景色
  header: ['专利编号', '专利名称', '获取时间'],
  columnWidth: [140, 400, 140],
  data: [
    ['1012', '数字行踪隐私保护与传染链自动追踪关键技术及应用研究', '2021-6-17'],
    ['1013', '库尔勒高标准农田建设数据分析', '2021-6-18'],
    ['1014', '数字行踪隐私保护与传染链自动追踪关键技术及应用研究', '2021-6-19'],
    ['1015', '数字行踪隐私保护与传染链自动追踪关键技术及应用研究', '2021-6-17'],
    ['1016', '库尔勒高标准农田建设数据分析', '2021-6-18'],
    ['1017', '数字行踪隐私保护与传染链自动追踪关键技术及应用研究', '2021-6-19'],
    ['1018', '数字行踪隐私保护与传染链自动追踪关键技术及应用研究', '2021-6-17'],
    ['1019', '库尔勒高标准农田建设数据分析', '2021-6-18'],
    ['1020', '数字行踪隐私保护与传染链自动追踪关键技术及应用研究', '2021-6-19']
  ]
}
