/*
 * @Description: 右 柱状加折线配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
// eslint-disable-next-line camelcase
import { color } from 'echarts'

// eslint-disable-next-line camelcase
const class_xAxis = ['羊毛', '蛋类', '奶类', '肉类'] // 列标
// eslint-disable-next-line camelcase
const class_data = [626.65, 5423, 14513, 30294] // 柱图1数据
// eslint-disable-next-line camelcase
const class_data2 = [626.65, 5423, 14513, 30294] // 线图数据
const classType = ['交易量（吨）', '价格走势（元/吨）'] // ['柱图图例', '线图图例']
export default {
  title: { show: false },
  toolbox: { show: false },
  legend: {
    top: 'top',
    textStyle: {
      color: '#B7E2FF',
      fontSize: 18,
      fontFamily: '微软雅黑'
    },
    data: classType
  },
  grid: {
    top: '25%',
    left: '15%',
    right: '5%',
    bottom: '12%'
  },
  xAxis: {
    type: 'category',
    boundaryGap: true,
    data: class_xAxis,
    axisLabel: {
      interval: 0,
      textStyle: {
        color: '#B7E1FF',
        fontSize: 18
      }
    },
    axisLine: {
      lineStyle: {
        color: '#09F'
      }
    }
  },
  yAxis: {
    inverse: false,
    name: '',
    nameTextStyle: {
      padding: [0, 0, 0, -50], // 四个数字分别为上右下左与原位置距离
      fontSize: 18,
      color: '#B7E1FF'
    },
    splitArea: { show: false }, // Y轴横向背景
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: {
      textStyle: { // Y轴字体
        color: '#B7E1FF',
        fontSize: 18,
        fontFamily: 'Arial'
      }
    },
    splitLine: { // Y轴横向轴线
      lineStyle: {
        color: '#09F'
      }
    }
  },
  tooltip: {
    trigger: 'axis',
    backgroundColor: 'rgba(84,131,197,0.7)',
    borderColor: 'rgba(84,131,197,0.55)',
    textStyle: {
      color: '#ffffff',
      fontSize: 18
    }
  },
  series: [
    {
      name: classType[0],
      type: 'bar',
      itemStyle:
        {
          normal: { color: color[1] },
          emphasis: { color: color[2] }
        },
      barWidth: 30,
      data: class_data
    },
    {
      name: classType[1],
      type: 'line',
      itemStyle: {
        normal: {
          color: '#F90',
          label: {
            show: true,
            position: 'top',
            textStyle: {
              color: '#CCC',
              fontSize: 18
            }
          },
          lineStyle: {
            color: '#F90',
            width: 3
          }
        },
        emphasis: {
          color: '#FF0'
        }
      },
      symbolSize: 18,
      data: class_data2
    }
  ]
}
