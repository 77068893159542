/*
 * @Description: 中右 排名轮播表配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
export default {
  data: [
    {
      name: '<span style="font-size: 18px;color: #B7E2FF;" >' + '阿依巴格乡' + '</span>',
      value: 38814.98
    },
    {
      name: '<span style="font-size: 18px;color: #B7E2FF;" >' + '塔木托格拉克乡' + '</span>',
      value: 112380.63
    },
    {
      name: '<span style="font-size: 18px;color: #B7E2FF;" >' + '拜什艾日克镇' + '</span>',
      value: 73487.93
    },
    {
      name: '<span style="font-size: 18px;color: #B7E2FF;" >' + '英艾日克镇' + '</span>',
      value: 105000
    },
    {
      name: '<span style="font-size: 18px;color: #B7E2FF;" >' + '乌鲁却勒镇' + '</span>',
      value: 92200
    },
    {
      name: '<span style="font-size: 18px;color: #B7E2FF;" >' + '多浪乡' + '</span>',
      value: 63687.16
    },
    {
      name: '<span style="font-size: 18px;color: #B7E2FF;" >' + '巴格托拉克乡' + '</span>',
      value: 33486.95
    },
    {
      name: '<span style="font-size: 18px;color: #B7E2FF;" >' + '阿瓦提镇' + '</span>',
      value: 1921.07
    },
    {
      name: '<span style="font-size: 18px;color: #B7E2FF;" >' + '三河镇' + '</span>',
      value: 535000
    }
  ],
  sort: true,
  unit: '亩'
}
