<!--
 * @Description: 右上
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-20 21:29:13
-->
<template>
  <div
    flex="dir:left box:mean"
    class="info-top">
    <!-- 柱状图左 开始 -->
    <v-chart class="chart" :option="option" />
    <!-- 柱状图左 结束 -->
    <!-- 柱状图右 开始 -->
    <v-chart class="chart" :option="option1" />
    <!-- 柱状图右 结束 -->
  </div>
</template>

<script>
import option from './option'
import option1 from './option1'
export default {
  name: 'RightBottom',
  data () {
    return {
      option,
      option1
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">

</style>
