<!--
 * @Description: 土地利用中左
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-15 13:02:03
-->
<template>
  <!-- 饼图 开始 -->
  <v-chart class="chart" :option="option" />
  <!-- 饼图 结束 -->
</template>
<script>
import option from './option'
export default {
  name: 'MainLeft',
  data () {
    return {
      option
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.chart {
  margin-top: -15px;
}
</style>
