<!--
 * @Description: 下下中右
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-15 13:19:08
-->
<template>
  <div>
    <!-- 轮播表区域 开始 -->
    <dv-border-box-8 :dur="9">
      <dv-scroll-board :config="option.tableData[0]" class="scroll-board-configTop"/>
      <dv-scroll-board :config="option.tableData[1]" class="scroll-board-config" />
    </dv-border-box-8>
    <!-- 轮播表区域 结束 -->
  </div>

</template>

<script>
import option from './option'

export default {
  name: 'AainBottomBottomCenterRight',
  data () {
    return {
      option,
      configTop: null,
      config: null
    }
  },
  mounted () {

  },
  methods: {
  }
}
</script>

<style scoped>
.scroll-board-configTop{
  width: 100%; height: 37.5px
}
.scroll-board-config{
  width: 100%; height: 75%
}
</style>
