/*
 * @Description: 中上 畜牧场等点位数据
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
export default {
  farmMachineList: [
    {
      tnumber: '245200507075',
      p25: '245200507075',
      p8: '徐世雷',
      p7: '13909970183',
      lng: '80.358094',
      lat: '40.66575',
      p1: '74',
      p2: '28',
      p3: '0',
      p9: '棉花收获',
      p16: '12',
      p17: '20',
      p18: '2.24',
      p22: '15',
      code: '1'
    },
    {
      tnumber: '245200907027',
      p25: '245200907044',
      p8: '雷延钢',
      p7: '15999229666',
      lng: '80.380094',
      lat: '40.67125',
      p1: '74',
      p2: '28',
      p3: '0',
      p9: '残膜回收',
      p16: '0',
      p17: '10.1',
      p18: '2.4',
      p22: '15',
      code: '0'
    },
    {
      tnumber: '245200507043',
      p25: '245200507043',
      p8: '何威',
      p7: '15292331923',
      lng: '80.201394',
      lat: '40.67675',
      p1: '74',
      p2: '28',
      p3: '0',
      p9: '棉花收获',
      p16: '22',
      p17: '19.4',
      p18: '2.24',
      p22: '15',
      code: '1'
    },
    {
      tnumber: '245200915023',
      p25: '245200915023',
      p8: '李博',
      p7: '13565694111',
      lng: '80.222804',
      lat: '40.66575',
      p1: '74',
      p2: '28',
      p3: '0',
      p9: '棉花收获',
      p16: '0',
      p17: '18.8',
      p18: '4.95',
      p22: '18',
      code: '0'
    },
    {
      tnumber: '245200507058',
      p25: '245200507058',
      p8: '高万兵',
      p7: '13070026201',
      p5: '61.4',
      lng: '80.330254',
      lat: '40.67235',
      p1: '74',
      p2: '28',
      p3: '0',
      p9: '棉花收获',
      p16: '0',
      p17: '12.3',
      p18: '2.24',
      p22: '15',
      code: '0'
    },
    {
      tnumber: '245200507070',
      p25: '245200507070',
      p8: '唐俊',
      p7: '15292331923',
      p5: '0',
      lng: '80.201094',
      lat: '40.36925',
      p1: '74',
      p2: '28',
      p3: '0',
      p9: '棉花收获',
      p16: '0',
      p17: '0',
      p18: '2.24',
      p22: '15',
      code: '0'
    },
    {
      tnumber: '245200907041',
      p25: '245200907041',
      p8: '刘彦龙',
      p7: '13999667557',
      p5: '0',
      lng: '80.284094',
      lat: '40.67325',
      p1: '74',
      p2: '28',
      p3: '0',
      p9: '棉花收获',
      p16: '0',
      p17: '19.1',
      p18: '2.25',
      p22: '15',
      code: '0'
    },
    {
      tnumber: '245200506042',
      p25: '245200506042',
      p8: '覃由兵',
      p7: '13150281166',
      p5: '0',
      lng: '80.277594',
      lat: '40.67525',
      p1: '74',
      p2: '28',
      p3: '0',
      p9: '棉花收获',
      p16: '0',
      p17: '0',
      p18: '2.24',
      p22: '15',
      code: '0'
    },
    {
      tnumber: '245200507070',
      p25: '245200507070',
      p8: '芦成祥',
      p7: '15292331923',
      p5: '0',
      lng: '80.294094',
      lat: '40.65325',
      p1: '74',
      p2: '28',
      p3: '0',
      p9: '棉花收获',
      p16: '0',
      p17: '0',
      p18: '2.24',
      p22: '15',
      code: '0'
    },
    {
      tnumber: '245200907041',
      p25: '245200907041',
      p8: '杨国军',
      p7: '13999667557',
      p5: '0',
      lng: '80.292094',
      lat: '40.6125',
      p1: '74',
      p2: '28',
      p3: '0',
      p9: '棉花收获',
      p16: '0',
      p17: '19.1',
      p18: '2.25',
      p22: '15',
      code: '0'
    },
    {
      tnumber: '245200506042',
      p25: '245200506042',
      p8: '史海超',
      p7: '13150281166',
      p5: '0',
      lng: '80.285594',
      lat: '40.60025',
      p1: '74',
      p2: '28',
      p3: '0',
      p9: '棉花收获',
      p16: '9.6',
      p17: '18',
      p18: '2.24',
      p22: '15',
      code: '2'
    },
    {
      tnumber: '245200507075',
      p25: '245200507075',
      p8: '徐世雷',
      p7: '13909970183',
      lng: '80.305940',
      lat: '40.697680',
      p1: '74',
      p2: '28',
      p3: '0',
      p9: '棉花收获',
      p16: '12',
      p17: '20',
      p18: '2.24',
      p22: '15',
      code: '1'
    },
    {
      tnumber: '245200907027',
      p25: '245200907044',
      p8: '雷延钢',
      p7: '15999229666',
      lng: '80.3085370',
      lat: '40.731050',
      p1: '74',
      p2: '28',
      p3: '0',
      p9: '残膜回收',
      p16: '0',
      p17: '10.1',
      p18: '2.4',
      p22: '15',
      code: '0'
    },
    {
      tnumber: '245200507043',
      p25: '245200507043',
      p8: '何威',
      p7: '15292331923',
      lng: '80.271336',
      lat: '40.689940',
      p1: '74',
      p2: '28',
      p3: '0',
      p9: '棉花收获',
      p16: '22',
      p17: '19.4',
      p18: '2.24',
      p22: '15',
      code: '1'
    },
    {
      tnumber: '245200915023',
      p25: '245200915023',
      p8: '李博',
      p7: '13565694111',
      lng: '80.2632060',
      lat: '40.676630',
      p1: '74',
      p2: '28',
      p3: '0',
      p9: '棉花收获',
      p16: '0',
      p17: '18.8',
      p18: '4.95',
      p22: '18',
      code: '0'
    },
    {
      tnumber: '245200507058',
      p25: '245200507058',
      p8: '高万兵',
      p7: '13070026201',
      p5: '61.4',
      lng: '80.3001250',
      lat: '40.742470',
      p1: '74',
      p2: '28',
      p3: '0',
      p9: '棉花收获',
      p16: '0',
      p17: '12.3',
      p18: '2.24',
      p22: '15',
      code: '0'
    },
    {
      tnumber: '245200507070',
      p25: '245200507070',
      p8: '唐俊',
      p7: '15292331923',
      p5: '0',
      lng: '80.3019080',
      lat: '40.684960',
      p1: '74',
      p2: '28',
      p3: '0',
      p9: '棉花收获',
      p16: '0',
      p17: '0',
      p18: '2.24',
      p22: '15',
      code: '0'
    },
    {
      tnumber: '245200506042',
      p25: '245200506042',
      p8: '覃由兵',
      p7: '13150281166',
      p5: '0',
      lng: '80.2560600',
      lat: '40.694040',
      p1: '74',
      p2: '28',
      p3: '0',
      p9: '棉花收获',
      p16: '0',
      p17: '0',
      p18: '2.24',
      p22: '15',
      code: '0'
    },
    {
      tnumber: '245200507070',
      p25: '245200507070',
      p8: '芦成祥',
      p7: '15292331923',
      p5: '0',
      lng: '80.2671110',
      lat: '40.730330',
      p1: '74',
      p2: '28',
      p3: '0',
      p9: '棉花收获',
      p16: '0',
      p17: '0',
      p18: '2.24',
      p22: '15',
      code: '0'
    },
    {
      tnumber: '245200907041',
      p25: '245200907041',
      p8: '杨国军',
      p7: '13999667557',
      p5: '0',
      lng: '80.2288890',
      lat: '40.656910',
      p1: '74',
      p2: '28',
      p3: '0',
      p9: '棉花收获',
      p16: '0',
      p17: '19.1',
      p18: '2.25',
      p22: '15',
      code: '0'
    }
  ]
}
