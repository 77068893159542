/*
 * @Description: 侧左下 轮播表配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2023-01-09 17:11:44
 */
// eslint-disable-next-line camelcase
export default {
  align: 'center',
  headerHeight: 50,
  waitTime: 2000, // 轮播时间
  oddRowBGC: ';background-color:rgba(84,131,197,0.29);font-size: 18px;color: #B7E2FF;', // 奇数行背景色
  evenRowBGC: ';font-size: 18px;color: #B7E2FF;', // 偶数行背景色
  rowNum: 5, // 轮播表行数
  headerBGC: ';background-color:rgba(84,131,197,0.83);font-size: 18px;color: #B7E2FF;', // 表头背景色
  header: ['放款年份', '姓名', '亩数', '实际放款额'],
  columnWidth: [140, 280, 140, 140],
  data: [
    ['2022', '周XX', '235', '200000元'],
    ['2022', '陈X', '1176.4', '100000元'],
    ['2022', '王XX', '5647', '4800000元'],
    ['2022', '周XX', '235', '200000元'],
    ['2022', '陈X', '1176.4', '100000元'],
    ['2022', '王XX', '5647', '4800000元'],
    ['2022', '周XX', '235', '200000元'],
    ['2022', '陈X', '1176.4', '100000元'],
    ['2022', '王XX', '5647', '4800000元'],
    ['2022', '周XX', '235', '200000元'],
    ['2022', '陈X', '1176.4', '100000元'],
    ['2022', '王XX', '5647', '4800000元'],
    ['2022', '周XX', '235', '200000元'],
    ['2022', '陈X', '1176.4', '100000元'],
    ['2022', '王XX', '5647', '4800000元'],
    ['2022', '周XX', '235', '200000元'],
    ['2022', '陈X', '1176.4', '100000元'],
    ['2022', '王XX', '5647', '4800000元']
  ]
}
