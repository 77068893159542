<!--
 * @Description: 上左内容容器
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-22 12:51:11
-->
<template>
  <div>
    <div flex="dir:top box:last"
         class="card-container">
      <div flex="dir:top box:last"
           class="card-container-top">
        <!-- 上容器 开始 -->
        <div flex="box:mean"
             class="card-container-img-panel">
          <div flex="dir:top cross:center box:first"
               class="card-container-img-panel-img">
            <img class="img"
                 src="/image/main-view4/data_pic2.png" />
            <span class="img-title">黄萎病</span>
          </div>
          <div flex="dir:top cross:center box:first"
               class="card-container-img-panel-img">
            <img class="img"
                 src="/image/main-view4/demo_pic.png" />
            <span class="img-title">棉铃疫病</span>
          </div>
        </div>
        <!-- 上容器 结束 -->
        <!-- 中容器 开始 -->
        <div class="card-container-chart"
             flex="box:first">
          <div class="card-container-chart-title">相似度</div>
          <div class="card-container-chart-main">
            <dv-percent-pond :config="option"
                             style="width: 100%; height: 100%" />
          </div>
        </div>
        <!-- 中容器 结束 -->
      </div>
      <!-- 下容器 开始 -->
      <div flex="box:mean"
           class="card-container-bottom">
        <div class="card-container-bottom-content">
          <div class="card-container-bottom-content-title">主要症状</div>
          <div class="card-container-bottom-content-content">一般在3～5片真叶期开始显症,发病初期在植株下部叶片上的叶缘和叶脉间出现浅黄色斑块，后逐渐扩展至叶色变黄褐色，主脉及其四周仍保持绿色，病叶出现掌状斑驳，叶肉变厚，叶缘向下卷曲，叶片由下而上逐渐脱落，仅剩顶部少数小叶。</div>
        </div>
        <div class="card-container-bottom-content right">
          <div class="card-container-bottom-content-title">防治办法</div>
          <div class="card-container-bottom-content-content">选种抗病品种；实行轮作；合理密植，加强田间肥水管理，雨后及时排水，合理控制氮肥，增施磷钾肥，增强植株长势；及时清除病枝残体，减少病源。</div>
        </div>
      </div>
      <!-- 下容器结束 -->
    </div>

  </div>
</template>

<script>
import option from './option'
export default {
  name: 'MainTopLeft',
  data () {
    return {
      option
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.card-container {
  height: 100%;
  .card-container-top {
    .card-container-img-panel {
      .card-container-img-panel-img {
        .img {
          width: 247.5px;
          height: 157.5px;
        }
        .img-title {
          margin-top: 7.5px;
        }
      }
    }
    .card-container-chart {
      height: 75px !important;
      margin-top: 15px;
      .card-container-chart-title {
        width: 60px !important;
        height: 75px;
        line-height: 75px;
        margin-right: 19px;
      }
      .card-container-chart-main {
        margin: 15px 0px;
      }
    }
  }
  .card-container-bottom {
    height: 240px !important;
    margin-top: 11px;
    .card-container-bottom-content {
      text-align: left;
      .card-container-bottom-content-title {
        color: #d8ca06;
      }
      .card-container-bottom-content-content {
        margin-top: 15px;
        text-indent: 2em;
      }
    }
    .right {
      margin-left: 22.5px;
    }
  }
}
</style>
