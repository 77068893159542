export default {
  config: {
    radius: '60%',
    activeRadius: '70%',
    lineWidth: 52.5,
    digitalFlopStyle: {
      fontSize: 18
    },
    color: [
      'rgb(0,149,255)',
      'rgb(246,0,234)',
      'rgb(255,221,0)',
      'rgb(0,255,255)',
      'rgb(61, 231, 201)'
    ],
    data: [
      {
        name: '棉花',
        value: 13.4
      },
      {
        name: '香梨',
        value: 42.3
      },
      {
        name: '肉类',
        value: 2.55
      }
    ]
  }
}
