<!--
 * @Description: 中上主内容
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-03-31 21:57:20
-->
<template>
  <div flex="dir:top box:mean"
       class="video-height">
    <el-carousel height="469px"
                 :autoplay="false"
                 arrow="always">
      <el-carousel-item flex="dir:top box:mean">
        <div flex="dir:left box:mean">
          <div class="video-panel">
            <video ref="videoPlayer1"
                   muted
                   class="video-js"></video>
          </div>
          <div class="video-panel">
            <video ref="videoPlayer2"
                   muted
                   class="video-js"></video>
          </div>
          <div class="video-panel">
            <video ref="videoPlayer3"
                   muted
                   class="video-js"></video>
          </div>
        </div>
        <div flex="dir:left box:mean">
          <div class="video-panel">
            <video ref="videoPlayer4"
                   muted
                   class="video-js"></video>
          </div>
          <div class="video-panel">
            <video ref="videoPlayer5"
                   muted
                   class="video-js"></video>
          </div>
          <div class="video-panel">
            <video ref="videoPlayer6"
                   muted
                   class="video-js"></video>
          </div>
        </div>
      </el-carousel-item>
      <el-carousel-item flex="dir:top box:mean">
        <div flex="dir:left box:mean">
          <div class="video-panel">
            <video ref="videoPlayer7"
                   muted
                   class="video-js"></video>
          </div>
          <div class="video-panel">
            <video ref="videoPlayer8"
                   muted
                   class="video-js"></video>
          </div>
          <div class="video-panel">
            <video ref="videoPlayer9"
                   muted
                   class="video-js"></video>
          </div>
        </div>
        <div flex="dir:left box:mean">
          <div class="video-panel">
            <video ref="videoPlayer10"
                   muted
                   class="video-js"></video>
          </div>
          <div class="video-panel">
            <video ref="videoPlayer11"
                   muted
                   class="video-js"></video>
          </div>
          <div class="video-panel">
            <video ref="videoPlayer12"
                   muted
                   class="video-js"></video>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { loadPreviewURLByCode } from '@/api/mods/camera'
import videojs from 'video.js'
export default {
  name: 'MainTop',
  data () {
    return {
      videos: [
        {
          type: 'application/x-mpegURL',
          src: 'http://117.190.138.168:83/openUrl/N336hdS/live.m3u8'
        }],
      cameraIds: [
        'fcb281be6ad84d83b0ed9b273177d676',
        'cc8a47a7dcf6485e95d8e9fe4a54b084',
        '7f4f2ed9110a45d3af755222a9f353e5',
        '428caae2cdc04dc3a69d6cecb2fcdc0a',
        '0e8eecdbeb3c44b4bd451fcfcf3a4b8e',
        '550374d636734259b47acfdc5acf937d',
        '50907e7620af4b55a80e0afbb9c6883b',
        '464d9a172ea649999741d1a230eb0cfd',
        '4ce9296784ad44f5a6297d3b585112f7',
        '429dd112188749ea89f34d57ee9b60fa',
        '40bb252f528f4d4e8bb7db87b0787868',
        'ce38eb5f97914c07b21458cb45f7f49f'
        // '2403cffe695f4a4fb7e9b3277833b1a2',
        // 'ea1d9d5e220b41bc889a8d78e9561cd9',
        // '9ad239ac7d524dcbb48f630379cf2ef2',
        // 'd06bfa2cbf9946f99741b4fda6cd12d1'
      ],
      playerOptions: {
        fluid: true,
        autoplay: 'any',
        muted: true,
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        controls: true,
        controlBar: {
          durationDisplay: true,
          remainingTimeDisplay: false
        },
        preload: 'auto',
        sources: {
          type: 'application/x-mpegURL',
          src: ''
        }
      }
    }
  },
  methods: {
    initVideo (id, index) {
      const _this = this
      loadPreviewURLByCode(id).then(res => {
        if (res.data) {
          const urlData = JSON.parse(res.data)
          const url = urlData.data.url
          _this.playerOptions.sources.src = url
          _this.player = videojs(this.$refs['videoPlayer' + index], this.playerOptions, function onPlayerReady () {
          })
        }
      })
    }
  },
  mounted () {
    const _this = this
    this.cameraIds.forEach((item, index) => {
      _this.initVideo(item, index + 1)
    })
  }
}
</script>

<style scoped lang="scss">
.video-panel {
}
.video-height {
  height: 100%;
  overflow: hidden;
  background: red;
}
.video-height video {
  height: 100%;
}
.vjs_video_61143-dimensions.vjs-fluid {
  // padding-top: 0px !important;
}
</style>
