/*
 * @Description: 左上
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
export default {
  topData: [
    ['', '年末存栏量/吨', '年末出栏量/万只']
  ],
  data: [
    ['鸡', '146.58', '531.2'],
    ['鸭', '2.12', '5.84'],
    ['鹅', '1.79', '5.77'],
    ['兔', '0', '1.73']
  ],
  header: ['<span style="font-size: 18px;color: #B7E2FF;" >合计</span>',
    '<span style="font-size: 18px;color: #B7E2FF;" >150.49</span>',
    '<span style="font-size: 18px;color: #B7E2FF;" >544.54</span>'],
  configTop: null,
  config: null,
  configNum: null
}
