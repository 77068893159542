/*
 * @Description: 右上下 柱状加折线图配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
import { color } from 'echarts'
// eslint-disable-next-line camelcase
const plan_xAxis = ['深松补贴', '残膜回收补贴', '设施补贴', '牲畜补贴']
// eslint-disable-next-line camelcase
const plan_data = [300, 474, 0, 400] // 已补贴
// eslint-disable-next-line camelcase
const plan_data1 = [300, 474, 0, 400] // 计划补贴
export default {
  title: { show: false },
  toolbox: { show: false },
  legend: {
    top: 'top',
    textStyle: {
      color: '#B7E2FF',
      fontSize: 18,
      fontFamily: '微软雅黑'
    },
    data: ['已补贴', '计划补贴']
  },
  xAxis: {
    data: plan_xAxis,
    axisLabel: {
      interval: 0,
      textStyle: {
        color: '#B7E1FF',
        fontSize: 18
      }
    },
    axisLine: {
      lineStyle: {
        color: '#09F'
      }
    }
  },
  grid: {
    top: '20%',
    left: '15%',
    right: '8%',
    bottom: '20%'
  },
  yAxis: {
    inverse: false,
    name: '补贴额(万元)',
    nameTextStyle: {
      padding: [0, 0, 0, -80], // 四个数字分别为上右下左与原位置距离
      fontSize: 13.5,
      color: '#B7E1FF'
    },
    splitArea: { show: false }, // Y轴横向背景
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: {
      textStyle: { // Y轴字体
        color: '#B7E1FF',
        fontSize: 18,
        fontFamily: 'Arial'
      }
    },
    splitLine: { // Y轴横向轴线
      lineStyle: {
        color: '#09F'
      }
    }
  },
  tooltip: {
    trigger: 'axis',
    backgroundColor: 'rgba(84,131,197,0.7)',
    borderColor: 'rgba(84,131,197,0.55)',
    textStyle: {
      color: '#ffffff',
      fontSize: 18
    }
  },
  series: [
    {
      name: '已补贴',
      type: 'bar',
      itemStyle:
        {
          normal: { color: color[1] },
          emphasis: { color: color[2] }
        },
      barWidth: 30,
      animationDuration: 10000,
      data: plan_data
    },
    {
      name: '计划补贴',
      type: 'line',
      itemStyle: {
        normal: {
          color: '#F90',
          label: {
            show: true,
            position: 'top',
            textStyle: {
              color: '#CCC',
              fontSize: 18
            }
          },
          lineStyle: {
            color: '#F90',
            width: 3
          }
        },
        emphasis: {
          color: '#FF0'
        }
      },
      symbolSize: 18,
      animationDuration: 10000,
      data: plan_data1
    }
  ]
}
