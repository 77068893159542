<!--
 * @Description: 中左
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-16 20:02:19
-->
<template>
  <div style="width: 100%; height: 100%" flex="dir:left">
    <!-- 圆环图 开始 -->
    <dv-active-ring-chart :config="option" style="width:70%;height:100%" />
    <!-- 圆环图 结束 -->
    <!-- 圆环图标签 开始 -->
    <div class="label-tag" flex="dir:top box:mean">
      <div class="label-item" flex="dir:left">
        <span>{{option.data[0].name}}</span>
        <div style="background-color: #00FF33; "></div>
      </div>
      <div class="label-item" flex="dir:left">
        <span>{{option.data[1].name}}</span>
        <div style="background-color: #FFDD00;"></div>
      </div>
      <div class="label-item" flex="dir:left">
        <span>{{option.data[2].name}}</span>
        <div style="background-color: #0095FF;"></div>
      </div>
      <div class="label-item" flex="dir:left">
        <span>{{option.data[3].name}}</span>
        <div style="background-color: #F600EA;"></div>
      </div>
      <div class="label-item" flex="dir:left">
        <span>{{option.data[4].name}}</span>
        <div style="background-color: #ff2a77;"></div>
      </div>
      <div class="label-item" flex="dir:left">
        <span>{{option.data[5].name}}</span>
        <div style="background-color: #fcb28a;"></div>
      </div>
    </div>
    <!-- 圆环图标签 结束 -->
  </div>
</template>

<script>
import option from './option'
export default {
  name: 'MainLeft',
  data () {
    return {
      option
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.label-item span{
  width:100px;
}
.label-item div{
  width: 36px;
  height: 18px;
  margin-left: 7.5px;
}
</style>
