/*
 * @Description: 地块接口
 * @Author: Pancras
 * @Date: 2022-01-15 17:05:32
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-15 19:08:14
 */
import request from '@/api/request'
export function _FindPlaceByDomain (data) {
  return request({
    url: '/farm/kdi/place/search/domain',
    method: 'get',
    params: data
  })
}
export function _SearchByAreaCode (params) {
  return request({
    url: '/farm/kdi/place/searchByAreaCode',
    method: 'post',
    data: params
  })
}
export function _LoadPlaceWithInfo (data) {
  return request({
    url: '/farm/kdi/place/search/findByAnyCondition',
    method: 'get',
    params: data
  })
}

export function _FindPlaceByUuidAll (data) {
  return request({
    url: '/farm/kdi/place/search/findPlaceByUuidAll',
    method: 'get',
    params: data,
    timeout: 200000
  })
}

// API 导出
export default {
  _FindPlaceByDomain, _SearchByAreaCode, _LoadPlaceWithInfo, _FindPlaceByUuidAll
}
