<!--
 * @Description: 品牌建设页面
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-22 20:40:59
-->
<template>
  <!-- 大屏容器 开始 -->
  <div class="container"
       flex="dir:left box:justify">
  </div>
  <!-- 大屏容器 结束 -->
</template>

<script>
export default {
  name: 'MainContainer2',
  components: {
  }
}
</script>

<style lang="scss" scoped>
// 注册样式
@import "~@/assets/style/main-view32.scss";
</style>
