// prettier-ignore
// ['2021/12/31', '开盘', '收盘', '最高', '最低']
const cottonData = [
  // 棉花数据
  ['2023/1/9', '4638.58', '4652.06', '4672.65', '4522.47'],
  ['2023/1/8', '4764.94', '4574.87', '4712.81', '4590.83'],
  ['2023/1/7', '4663.44', '4673.74', '4631.73', '4569.83'],
  ['2023/1/6', '4762.96', '4712.86', '4710.81', '4518.83'],
  ['2023/1/5', '4661.28', '4671.97', '4809.27', '4567.83'],
  ['2023/1/4', '4860.91', '4590.24', '4908.21', '4596.83'],
  ['2023/1/3', '4759.77', '4669.22', '4807.92', '4565.83'],
  ['2023/1/2', '4858.35', '4568.56', '4906.17', '4664.83'],
  ['2023/1/1', '4757.26', '4667.85', '4805.34', '4563.83'],
  ['2022/12/31', '4790.23', '4783.43', '4849.93', '4780.71'],
  ['2022/12/30', '4631.79', '4882.57', '5048.23', '4829.71'],
  ['2022/12/29', '4829.68', '4880.21', '4946.54', '4827.71'],
  ['2022/12/28', '4728.76', '4779.31', '4845', '4726.71'],
  ['2022/12/27', '4970.98', '4895.37', '5074.45', '4899.43'],
  ['2022/12/26', '4869.88', '4934.67', '4993.57', '4998.43'],
  ['2022/12/25', '4968.85', '4893.37', '5072.89', '4797.43'],
  ['2022/12/24', '4817.36', '4932.34', '4971.34', '4896.43'],
  ['2022/12/23', '4956.43', '4891.37', '4870.45', '4795.43'],
  ['2022/12/22', '4925.54', '4830.99', '4969.64', '4894.43'],
  ['2022/12/21', '4812.23', '4726.73', '4853.06', '4723.94'],
  ['2022/12/20', '4763.98', '4828.37', '4967.45', '4992.43'],
  ['2022/12/19', '4911.47', '4826.37', '4965.87', '4890.43'],
  ['2022/12/18', '4960.86', '4925.37', '4864.98', '4789.43'],
  ['2022/12/17', '4759.25', '4824.37', '4963.68', '4988.43'],
  ['2022/12/16', '4858.76', '4923.37', '5062.64', '4887.43'],
  ['2022/12/15', '4957.98', '4822.37', '4961.45', '4786.43'],
  ['2022/12/14', '4724.15', '4946.37', '4865.14', '4884.65'],
  ['2022/12/13', '4923.37', '4845.37', '4964.86', '4983.65'],
  ['2022/12/12', '4822.76', '4964.37', '5063.35', '4882.65'],
  ['2022/12/11', '4721.89', '4993.37', '4992.86', '4781.65'],
  ['2022/12/10', '4920.90 ', '4942.37', '4961.87', '4980.65'],
  ['2022/12/9', '4656.36', '4596.77', '4704.12', '4592.83'],
  ['2022/12/8', '4755.97', '4565.96', '4803.16', '4561.83'],
  ['2022/12/7', '4854.11', '4664.34', '4702.14', '4660.83'],
  ['2022/12/6', '4753.94', '4563.77', '4801.1', '4559.83'],
  ['2022/12/5', '4884.76', '4885.31', '4851.34', '4732.71'],
  ['2022/12/4', '4733.54', '4799.75', '4950.55', '4931.71'],
  ['2022/12/3', '4730.63', '4781.88', '4847.95', '4798.71'],
  ['2022/12/2', '4862.57', '4727.37', '5066.67', '4791.43'],
  ['2022/12/1', '4819.34', '4841.37', '5060.23', '4779.65'],
  ['2022/11/30', '4918.15', '4940.37', '4959.14', '4878.65']
].reverse()
const cottonDatas = cottonData.map(function (item) {
  return [item[1], item[2], item[3], item[4]]
})
// 香梨数据
const pearData = [
  ['2023/1/9', '10.8', '10.82', '11.07', '10.56'],
  ['2023/1/8', '19.84', '19.89', '22.03', '21.21'],
  ['2023/1/7', '20.84', '21.21', '24.03', '20.61'],
  ['2023/1/6', '15.84', '15.34', '20.99', '19.67'],
  ['2023/1/5', '18.84', '19.66', '23.03', '18.66'],
  ['2023/1/4', '21.84', '20.97', '18.73', '14.69'],
  ['2023/1/3', '16.84', '17.14', '17.95', '13.31'],
  ['2023/1/2', '19.84', '19.55', '18.3', '15.16'],
  ['2023/1/1', '14.84', '15.01', '17.83', '14.65'],
  ['2022/12/31', '9.84', '10.23', '18.53', '15.84'],
  ['2022/12/30', '12.84', '13.13', '17.03', '14.73'],
  ['2022/12/29', '16.84', '17.27', '16.03', '14.56'],
  ['2022/12/28', '10.84', '11', '11.03', '10.6'],
  ['2022/12/27', '31.18', '31.08', '31.45', '30.93'],
  ['2022/12/26', '20.18', '20.67', '19.45', '18.93'],
  ['2022/12/25', '29.18', '29.44', '29.45', '28.93'],
  ['2022/12/24', '25.18', '25.08', '28.45', '25.03'],
  ['2022/12/23', '17.18', '18.02', '19.45', '16.93'],
  ['2022/12/22', '26.18', '26.43', '26.45', '25.93'],
  ['2022/12/21', '15.18', '15.92', '25.45', '24.93'],
  ['2022/12/20', '24.18', '24.32', '24.45', '23.93'],
  ['2022/12/19', '13.18', '13.20 ', '23.45', '22.93'],
  ['2022/12/18', '22.18', '22.92', '22.45', '21.93'],
  ['2022/12/17', '11.18', '11.73', '11.45', '20.93'],
  ['2022/12/16', '20.18', '19.08', '20.45', '19.93'],
  ['2022/12/15', '29.18', '29.72', '29.45', '18.93'],
  ['2022/12/14', '18.18', '18.14', '18.45', '17.93'],
  ['2022/12/13', '27.18', '27.08', '27.45', '16.93'],
  ['2022/12/12', '16.18', '15.78', '16.45', '15.93'],
  ['2022/12/11', '25.18', '25.11', '25.45', '14.93'],
  ['2022/12/10', '14.18', '15.08', '14.45', '13.93'],
  ['2022/12/9', '23.18', '24.31', '23.45', '12.93'],
  ['2022/12/8', '12.18', '13.15', '12.45', '11.93'],
  ['2022/12/7', '11.18', '11.08', '11.45', '10.93'],
  ['2022/12/6', '24.61', '24.73', '24.67', '23.7'],
  ['2022/12/5', '13.61', '14.03', '13.67', '22.7'],
  ['2022/12/4', '22.61', '22.96', '22.67', '21.7'],
  ['2022/12/3', '11.61', '11.01', '11.67', '20.7'],
  ['2022/12/2', '20.61', '20.97', '20.67', '19.7'],
  ['2022/12/1', '9.61', '9.58', '9.67', '18.7'],
  ['2022/11/30', '18.61', '18.45', '18.67', '17.7']
].reverse()
const pearDatas = pearData.map(function (item) {
  return [item[1], item[2], item[3], item[4]]
})
const dates = pearData.map(function (item) {
  return item[0]
})
export default {
  tooltip: {
    trigger: 'axis',
    backgroundColor: 'rgba(255,255,255,0.2)',
    // 自定义显示
    formatter: function (params) {
      return params[0].name + '<br/>' +
        '开盘:' + params[0].value[1] + '<br>' +
        '收盘:' + params[0].value[2] + '<br>' +
        '最低:' + params[0].value[3] + '<br>' +
        '最高:' + params[0].value[4] + '<br>'
    },
    textStyle: {
      color: '#FFF',
      fontSize: 18
    },
    axisPointer: {
      animation: false,
      type: 'cross',
      lineStyle: {
        color: '#376df4',
        width: 1,
        opacity: 1
      }
    }
  },
  legend: {
    data: ['棉花', '香梨'],
    selectedMode: 'single',
    top: 'top',
    textStyle: {
      color: '#B7E2FF',
      fontSize: 18,
      fontFamily: '微软雅黑'
    },
    inactiveColor: '#777'
  },
  grid: {
    top: '20%',
    left: '5%',
    right: '5%',
    bottom: '20%'
  },
  xAxis: {
    type: 'category',
    data: dates,
    axisLabel: {
      textStyle: { // X轴文字
        color: '#B7E1FF',
        fontSize: 18
      }
    },
    axisLine: { lineStyle: { color: '#8392A5' } }
  },
  yAxis: {
    name: '点',
    nameTextStyle: {
      padding: [0, 0, 5, -30], // 四个数字分别为上右下左与原位置距离
      fontSize: 18,
      color: '#B7E1FF'
    },
    scale: true,
    axisLine: { lineStyle: { color: '#8392A5' } },
    splitLine: { show: false },
    axisLabel: {
      textStyle: { // Y轴字体
        color: '#B7E1FF',
        fontSize: 18,
        fontFamily: 'Arial'
      }
    }
  },
  dataZoom: [
    {
      textStyle: {
        color: '#8392A5'
      },
      dataBackground: {
        areaStyle: {
          color: '#8392A5'
        },
        lineStyle: {
          opacity: 0.8,
          color: '#8392A5'
        }
      },
      brushSelect: true
    },
    {
      type: 'inside'
    }
  ],
  series: [
    {
      name: '棉花',
      type: 'line',
      data: cottonData,
      smooth: true,
      showSymbol: false,
      lineStyle: {
        width: 1
      }
    },
    {
      name: '香梨',
      type: 'line',
      data: pearData,
      smooth: true,
      showSymbol: false,
      lineStyle: {
        width: 1,
        color: '#328554'
      }
    },
    {
      name: '棉花',
      type: 'candlestick',
      data: cottonDatas,
      itemStyle: {
        color: '#FD1050',
        color0: '#0CF49B',
        borderColor: '#FD1050',
        borderColor0: '#0CF49B'
      }
    },
    {
      name: '香梨',
      type: 'candlestick',
      data: pearDatas,
      itemStyle: {
        color: '#FD1050',
        color0: '#0CF49B',
        borderColor: '#FD1050',
        borderColor0: '#0CF49B'
      }
    }
  ]
}
