/*
 * @Description: 右下 柱状图配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Hlc
 * @LastEditTime: 2022-07-20 10:38:06
 */
import { color } from 'echarts'
// 农机补贴
// eslint-disable-next-line camelcase
const class_xAxis = ['采棉机', '谷物联合收割机', '大中型拖拉机', '耕整机械', '残膜回收机'] // 列标
// eslint-disable-next-line camelcase
const class_data = [729, 365, 516, 425, 414] // 柱图1数据
// eslint-disable-next-line camelcase
const class_data1 = [859, 315, 358, 246, 212] // 柱图1数据
// eslint-disable-next-line camelcase
const classType = ['2021年', '2022年'] // 图例
export default {
  title: { show: false }, // 标题
  toolbox: { show: false }, // 工具栏
  legend: { // 图例
    top: 'top',
    textStyle: {
      color: '#B7E2FF',
      fontSize: 18,
      fontFamily: '微软雅黑'
    },
    data: classType
  },
  grid: { // 定位
    top: '25%',
    left: '10%',
    right: '5%',
    bottom: '12%'
  },
  xAxis: { // 定义X轴
    type: 'category',
    boundaryGap: true, // 起始和结束两端空白
    data: class_xAxis, // 数据
    axisLabel: { // 坐标轴文本标签
      interval: 0,
      textStyle: {
        color: '#B7E1FF',
        fontSize: 15
      }
    },
    axisLine: { // 坐标轴线
      lineStyle: {
        color: '#09F'
      }
    }
  },
  yAxis: {
    inverse: false, // 基于Y轴反转
    name: '万元',
    nameTextStyle: { // Y轴说明
      padding: [0, 0, 0, -65], // 四个数字分别为上右下左与原位置距离
      fontSize: 18,
      color: '#B7E1FF'
    },
    splitArea: { show: false }, // 分隔区域(Y轴背景)
    axisLine: { show: false }, // 坐标轴线
    axisTick: { show: false }, // 轴标记
    axisLabel: { // 坐标轴文本标签
      textStyle: {
        color: '#B7E1FF',
        fontSize: 18,
        fontFamily: 'Arial'
      }
    },
    splitLine: { // 分隔线
      lineStyle: {
        color: '#09F'
      }
    }
  },
  tooltip: { // 提示框
    trigger: 'axis',
    backgroundColor: 'rgba(84,131,197,0.7)',
    borderColor: 'rgba(84,131,197,0.55)',
    textStyle: {
      color: '#ffffff',
      fontSize: 18
    }
  },
  series: [ // 内容数组
    {
      name: classType[0],
      type: 'bar',
      stack: 'one',
      itemStyle:
      {
        normal: { color: color[1] }
      }, // 柱样式
      barWidth: 26, // 柱宽度
      data: class_data
    }, // 内容1
    {
      name: classType[1],
      type: 'bar',
      stack: 'two',
      barGap: '0%',
      itemStyle:
      {
        normal: { color: color[1] }
      },
      barWidth: 26,
      data: class_data1
    } // 内容2,
  ]
}
