/*
 * @Description: 下上左上圆环图配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
const config1 = {
  lineWidth: 23,
  radius: '50%',
  activeRadius: '60%',
  digitalFlopStyle: {
    fontSize: 18
  },
  data: [{
    name: '农资',
    value: 190.64
  },
  {
    name: '农机',
    value: 110.3
  },
  {
    name: '保险',
    value: 13.2
  },
  {
    name: '土地',
    value: 113.2
  },
  {
    name: '农产品',
    value: 1.23
  }
  ]
}
const config2 = {
  lineWidth: 23,
  radius: '50%',
  activeRadius: '60%',
  digitalFlopStyle: {
    fontSize: 18
  },
  data: [{
    name: '尿素',
    value: 21.1
  },
  {
    name: '棉种',
    value: 37.6
  },
  {
    name: '地膜',
    value: 43.7
  },
  {
    name: '滴灌带',
    value: 35.9
  },
  {
    name: '二胺',
    value: 51.7
  }
  ]
}
export default {
  config1,
  config2
}
