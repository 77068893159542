<!--
 * @Description: 下左中内容区域
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-16 19:51:54
-->
<template>
  <div class="grow_data">
    <img src="/image/main-view4/tree_pic.png" alt="">
    <p>生长周期：一般为5到6个月</p>
    <p>土质：砂粘适中</p>
    <p>全年日照时数不小于1300个小时</p>
    <i></i>
    <div class="specialistSuggest">
      <div>专家建议：</div>
      <div style="font-size: 15px;">保障土壤富含氮、磷、钾等常量元素</div>
    </div>
    <div class="fertilizationSuggest">
      <div>该生长周期施肥建议：</div>
      <div>氮肥含量：1.3-1.5kg</div>
      <div>钾肥含量：1.5-1.7kg</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainBottonLeftCenter',
  data () {
    return {
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.grow_data {
  position: relative;
  display: inline-block;
  float: left;
  /* background-color: pink; */
}
.grow_data img {
  display: inline-block;
  width: 25%;
  height: 80%;
  margin-top: .8vw;
}
.grow_data p {
  color: #0EFCFF;
  position: absolute;
}
.grow_data p:nth-of-type(1) {
  top: 8%;
  left: 2%;
}
.grow_data p:nth-of-type(2) {
  top: 40%;
  left: 10%;
}
.grow_data p:nth-of-type(3) {
  top: 70%;
  left: 5%;
}
.grow_data span {
  position: absolute;
  top: 18%;
  left: 13%;
  padding: .15vw .6vw;
  border-radius: 1vw;
  display: inline-block;
  color: #0EFCFF;
  background: rgba(40, 229, 233, .2);
}
.specialistSuggest {
  position: absolute;
  left: 50%;
  top: 10%;
  display: inline-block;
  width: 50%;
  padding: 1vw;
  color: #0EFCFF;
  font-size: 18px;
}

.specialistSuggest div:nth-of-type(1) {
  font-size: 18px;
}

.fertilizationSuggest {
  position: absolute;
  left: 60%;
  top: 45%;
  display: inline-block;
  width: 35%;
  padding: 1vw;
  color: #0EFCFF;
  font-size: 13.5px;
}
.fertilizationSuggest div:nth-of-type(1) {
  font-size: 18px;
}

</style>
