/*
 * @Description: 左 饼图配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
const config1 = {
  title: {
    text: '牲畜类占比',
    left: 'center',
    textStyle: { // 副标题文本样式{"color": "#aaa"}
      fontSize: 18,
      color: '#B7E1FF'
    }
  },
  tooltip: {
    trigger: 'item',
    backgroundColor: 'rgba(84,131,197,0.7)',
    borderColor: 'rgba(84,131,197,0.55)',
    textStyle: {
      color: '#B7E1FF',
      fontSize: 18
    }
  },
  legend: {
    top: '90%',
    textStyle: {
      color: '#B7E1FF',
      fontSize: 18
    }
  },
  series: [
    {
      name: '牲畜类占比（只）',
      type: 'pie',
      radius: '50%',
      // 最小角度
      minAngle: 15,
      startAngle: 315,
      // 防止标签重叠策略
      avoidLabelOverlap: true,
      // 折现样式
      label: {
        alignTo: 'labelLine',
        edgeDistance: '1%',
        margin: '25%',
        fontStyle: 'normal',
        formatter: '{b}：{d}%',
        color: '#B7E1FF',
        fontSize: 15,
        width: 90,
        fontWeight: '200'
      },
      labelLine: {
        length: 15,
        length2: 7.5,
        show: true,
        lineStyle: {
          width: 2
        }
      },
      data: [
        { value: 66800, name: '牛' },
        { value: 2200, name: '马' },
        { value: 3300, name: '驴' },
        { value: 669500, name: '羊' },
        { value: 5400, name: '猪' }
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
}
const config2 = {
  title: {
    text: '家禽类占比（头）',
    left: 'center',
    textStyle: { // 副标题文本样式{"color": "#aaa"}
      fontSize: 18,
      color: '#B7E1FF'
    }
  },
  tooltip: {
    trigger: 'item',
    backgroundColor: 'rgba(84,131,197,0.7)',
    borderColor: 'rgba(84,131,197,0.55)',
    textStyle: {
      color: '#B7E1FF',
      fontSize: 18
    }
  },
  legend: {
    top: '90%',
    textStyle: {
      color: '#B7E1FF',
      fontSize: 18
    }
  },
  series: [
    {
      name: '家禽类占比',
      type: 'pie',
      radius: '50%',
      // 最小角度
      minAngle: 15,
      // startAngle: 300,
      // 防止标签重叠策略
      avoidLabelOverlap: true,
      // 折现样式
      label: {
        alignTo: 'edge',
        edgeDistance: '1%',
        margin: '25%',
        fontStyle: 'normal',
        formatter: '{b}：{d}%',
        color: '#B7E1FF',
        fontSize: 15,
        width: 75,
        fontWeight: '200'
      },
      labelLine: {
        length: 15,
        length2: 7.5,
        show: true,
        lineStyle: {
          width: 2
        }
      },
      data: [
        { value: 1465800, name: '鸡' },
        { value: 21200, name: '鸭' },
        { value: 17900, name: '鹅' },
        { value: 384900, name: '鸽' }
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
}
export default {
  config1,
  config2
}
