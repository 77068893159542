/*
 * @Description: 左上图表1配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
const roseData = [
  '阿依巴格乡',
  '塔木托拉克乡',
  '拜什艾日克镇',
  '英艾日克乡',
  '乌鲁却勒镇',
  '多浪乡',
  '巴格托拉克乡',
  '阿瓦提镇',
  '阿克切克力片区管委会',
  '丰收片区管委会',
  '上游水库管委会',
  '叶南片区管委会'
]
export default {
  tooltip: {
    trigger: 'item',
    backgroundColor: 'rgba(84,131,197,0.7)',
    borderColor: 'rgba(84,131,197,0.55)',
    textStyle: {
      color: '#B7E1FF',
      fontSize: 18
    },
    formatter: '{a} <br/>{b} : {c}亩 ({d}%)'
  },
  legend: {
    left: 'left',
    top: 'center',
    textStyle: {
      color: '#B7E1FF',
      fontSize: 15
    },
    orient: 'vertical',
    data: roseData
  },
  calculable: true, // 手柄拖拽调整选中的范围
  series: [
    {
      name: '各乡镇林果种植面积',
      type: 'pie',
      radius: [15, 135],
      center: ['60%', '50%'],
      roseType: 'radius',
      // 标线的属性设置，以及显示的文字
      itemStyle: {
        normal: {
          label: {
            show: true,
            formatter: '{c}亩',
            textStyle: {
              color: '#B7E1FF',
              fontSize: 15,
              fontWeight: '300'
            }
          },
          // 标线长度，宽度
          labelLine: {
            show: true,
            length: 7.5,
            lineStyle: {
              width: 2
            }
          }
        },
        emphasis: {
          shadowBlur: 7.5,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      },
      data: [
        { value: 52403, name: '阿依巴格乡' },
        { value: 45226, name: '塔木托拉克乡' },
        { value: 63300, name: '拜什艾日克镇' },
        { value: 49296, name: '英艾日克乡' },
        { value: 74580, name: '乌鲁却勒镇' },
        { value: 17500, name: '多浪乡' },
        { value: 15082, name: '巴格托拉克乡' },
        { value: 1467, name: '阿瓦提镇' },
        { value: 26462, name: '阿克切克力片区管委会' },
        { value: 30456, name: '丰收片区管委会' },
        { value: 2798, name: '上游水库管委会' },
        { value: 3520, name: '叶南片区管委会' }
      ]
    }
  ]
}
