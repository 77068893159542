<!--
 * @Description: 上2内容区域
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-16 20:02:06
-->
<template>
  <div flex="box:first">
    <!-- 左按钮区域 开始 -->
    <div flex="dir:top box:mean" class="card-left">
      <div class="card-left-title" @click="handleTitleClick(1)">林果种植</div>
      <div class="card-left-title" @click="handleTitleClick(2)">作物种植</div>
      <div class="card-left-title" @click="handleTitleClick(3)">土地利用</div>
      <div class="card-left-title" @click="handleTitleClick(4)">农机数据</div>
    </div>
    <!-- 左按钮区域 结束 -->
    <!-- 右卡片区域 开始 -->
    <div class="card-right">
      <div flex="dir:left box:mean" style="width: 100%; height: 100%">
        <div flex="dir:top box:mean" style="width: 100%; height: 100%">
          <dv-border-box-3
            style="margin: 7.5px 15px 0 0"
            v-for="(config, index) in configList1"
            :key="index"
          >
            <div style="width: 100%; height: 50%;">
              <dv-digital-flop
                style="width: 40%; height: 25%; margin-left: 25%"
              />
              <div style="color: #66eed5; font-size: 21px; font-weight: bold;">{{ config.number }}
              <span>{{config.content}}</span>
              </div>
              <span>{{ config.textName }}</span>
            </div>
          </dv-border-box-3>
        </div>
        <div flex="dir:top box:mean" style="width: 100%; height: 100%">
          <dv-border-box-3
            style="margin: 7.5px 15px 0 0"
            v-for="(config, index) in configList2"
            :key="index"
          >
            <div style="width: 100%; height: 50%;">
              <dv-digital-flop
                style="width: 40%; height: 25%; margin-left: 25%"
              />
              <div style="color: #66eed5; font-size: 21px; font-weight: bold;">{{ config.number }}
                <span>{{config.content}}</span>
              </div>
              <span>{{ config.textName }}</span>
            </div>
          </dv-border-box-3>
        </div>
      </div>
    </div>
    <!-- 右卡片区域 结束 -->
  </div>
</template>

<script>
export default {
  name: 'MainTop2',
  data () {
    return {
      configList1: [
        {
          textName: '红枣',
          number: 17,
          content: '万亩'
        },
        {
          textName: '核桃',
          number: 16,
          content: '万亩'
        }
      ],
      configList2: [
        {
          textName: '香梨',
          number: 2,
          content: '万亩'
        },
        {
          textName: '其他',
          number: 3,
          content: '万亩'
        }
      ],

      imgIdex: 2
    }
  },
  methods: {
    /**
     * handleTitleClick
     * @description 点击进行页签数据切换
     */
    handleTitleClick (index) {
      if (index === 1) {
        const config1 = {
          textName: '红枣',
          number: 17,
          content: '万亩'
        }
        const config2 = {
          textName: '核桃',
          number: 16,
          content: '万亩'
        }
        const config3 = {
          textName: '香梨',
          number: 2,
          content: '万亩'
        }
        const config4 = {
          textName: '其他',
          number: 3,
          content: '万亩'
        }
        this.replaceList(this.configList1, config1, config2)
        this.replaceList(this.configList2, config3, config4)
      } else if (index === 2) {
        const config1 = {
          textName: '棉花',
          number: 145,
          content: '万亩'
        }
        const config2 = {
          textName: '小麦',
          number: 30,
          content: '万亩'
        }
        const config3 = {
          textName: '玉米',
          number: 28,
          content: '万亩'
        }
        const config4 = {
          textName: '其他',
          number: 8,
          content: '万亩'
        }
        this.replaceList(this.configList1, config1, config2)
        this.replaceList(this.configList2, config3, config4)
      } else if (index === 3) {
        const config1 = {
          textName: '草地',
          number: 186,
          content: '万亩'
        }
        const config2 = {
          textName: '林地',
          number: 32,
          content: '万亩'
        }
        const config3 = {
          textName: '耕地',
          number: 181,
          content: '万亩'
        }
        const config4 = {
          textName: '其他',
          number: 230,
          content: '万亩'
        }
        this.replaceList(this.configList1, config1, config2)
        this.replaceList(this.configList2, config3, config4)
      } else {
        const config1 = {
          textName: '链式挖掘机',
          number: 569,
          content: '辆'
        }
        const config2 = {
          textName: '轮式挖掘机',
          number: 40,
          content: '辆'
        }
        const config3 = {
          textName: '轮式装载机',
          number: 811,
          content: '辆'
        }
        const config4 = {
          textName: '推土机',
          number: 38,
          content: '辆'
        }
        this.replaceList(this.configList1, config1, config2)
        this.replaceList(this.configList2, config3, config4)
      }
    },
    /**
     * replaceList
     * @description list数据替换为config1和config2
     */
    replaceList (list, config1, config2) {
      list.splice(0, 2)
      list.push(config1, config2)
    }
  }

}
</script>

<style scoped lang="scss">
.card-left {
  width: 120px !important;
  padding: 22.5px;
  .card-left-title {
    width: 100%;
    height: 37.5px;
    line-height: 60px;
    margin: 5px 0px;
    cursor: pointer;
    border: 1px solid rgb(13, 67, 130);
  }
}
.card-right {
  padding: 37.5px;
  .img {
    height: 100%;
  }
}
</style>
