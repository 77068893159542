<!--
 * @Description: 下3内容区域
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-16 20:02:10
-->
<template>
  <div flex="dir:top box:first">
    <div flex="dir:top box:mean" style="width:100%; height:50%" class="info-top">
      <div flex="dir:left box:mean">
        <span>牲畜存栏量</span>
        <span>517555头</span>
      </div>
      <div flex="dir:left box:mean">
        <span>草料需求量</span>
        <span>94万吨</span>
      </div>
      <div flex="dir:left box:mean">
        <span>饲草种植量</span>
        <span>1.5万亩</span>
      </div>
      <div flex="dir:left box:mean">
        <span>年末总产量</span>
        <span>4万吨</span>
      </div>
      <div flex="dir:left box:mean">
        <span>草料缺口量</span>
        <span>90万吨</span>
      </div>
    </div>
    <dv-border-box-11 class="info-bottom">
      <div>
        <span>专家建议</span>
        <p>1：提前签署饲草料订单。</p>
        <p>2：调整种植结构增加饲草料种植面积。</p>
      </div>
     </dv-border-box-11>
  </div>
</template>
<script>
export default {
  name: 'MainBottom3',
  data () {
    return {
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.info-top span{
  width: 50%;
  font-weight: bold;
  font-size: 21px;
  line-height: 37.5px;
}
.info-top span:nth-child(2n){
  width: 30%;
  font-weight: bold;
  text-align: left;
  color: aquamarine;
}
.info-bottom{
  margin-top: 5px;
}
.info-bottom span{
  font-weight: bold;
  font-size: 21px;
  line-height: 50.5px;
  //border: solid 1px red;
}
.info-bottom p{
  width: 70%;
  margin-left: 10%;
  text-align: left;
  font-weight: bold;
  font-size: 21px;
  line-height: 18px;
}
</style>
