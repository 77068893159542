<!--
 * @Description: 设施农业
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-21 00:08:54
-->
<template>
  <!-- 主容器 开始 -->
  <div class="main-container" flex="dir:top box:first">
    <div class="main-container-main" flex="dir:top box:last">
      <!-- 中上 结束 -->
      <div class="main-top">
        <div class="main-top-container">
          <main-top></main-top>
        </div>
      </div>
      <!-- 中上 结束 -->
      <!-- 中上 开始 -->
      <div class="main-bottom" flex="box:mean">
        <!-- 中左 开始 -->
        <div class="main-bottom-left-container" flex="dir:top box:first">
          <div class="main-bottom-left-container-title title">蔬菜种植数据</div>
          <div class="main-bottom-left-container-main">
            <main-left></main-left>
          </div>
        </div>
        <!-- 中左 结束 -->
        <!-- 中右 开始 -->
        <div class="main-bottom-right-container" flex="dir:top box:first">
          <div class="main-bottom-right-container-title title">
            蔬菜交易数据
          </div>
          <div class="main-bottom-right-container-main">
            <main-right></main-right>
          </div>
        </div>
        <!-- 中右 结束 -->
      </div>
      <!-- 中上 结束 -->
    </div>
  </div>
  <!-- 主容器 结束 -->
</template>

<script>
import MainTop from './components/main-top/index.vue'
import MainLeft from './components/main-left/index.vue'
import MainRight from './components/main-right/index.vue'
export default {
  name: 'MainContainer3',
  components: {
    MainTop,
    MainLeft,
    MainRight
  }
}
</script>

<style lang="scss" scoped>
// 注册样式
@import '~@/assets/style/main-view1.scss';
</style>
