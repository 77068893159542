<!--
 * @Description: 左上
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-21 05:47:52
-->
<template>
  <div class="count-card" flex="box:mean">
    <!-- 左 开始 -->
    <div flex="dir:top box:first">
      <div class="count-card-title">农机</div>
      <!-- 计数器 开始 -->
      <dv-digital-flop :config="option.config1" />
      <!-- 技术器 结束 -->
    </div>
    <!-- 左 结束 -->
    <!-- 中 开始 -->
    <div flex="dir:top box:first">
      <div class="count-card-title">合作社</div>
      <!-- 计数器 开始 -->
      <dv-digital-flop :config="option.config2" />
      <!-- 计数器 结束 -->
    </div>
    <!-- 中 结束 -->
    <!-- 右开始 -->
    <div flex="dir:top box:first">
      <div class="count-card-title">工程机械</div>
      <!-- 计数器 开始 -->
      <dv-digital-flop :config="option.config3" />
      <!-- 计数器 结束 -->
    </div>
    <!-- 右 结束 -->
  </div>
</template>

<script>
import option from './option'
export default {
  name: 'LeftTopTop',
  data () {
    return {
      option
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.count-card {
  .count-card-title {
    font-size: 16.5px;
    height: 22.5px !important;
  }
}
</style>
