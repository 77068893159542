<!--
 * @Description: 上右上主体区域
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-22 12:43:42
-->
<template>
  <!-- 主体区域 开始 -->
  <div flex="box:first">
    <div flex="dir:top box:mean" class="card-left">
      <div class="card-left-title" @click="handleTitleClick(1)">农业无人机</div>
      <div class="card-left-title" @click="handleTitleClick(2)">田间控制器</div>
      <div class="card-left-title" @click="handleTitleClick(3)">监控摄像头</div>
      <div class="card-left-title" @click="handleTitleClick(4)">土壤检测仪</div>
    </div>
    <div class="card-right">
      <img :src="`/image/equp/${imgIdex}.png`" class="img" />
    </div>
  </div>
  <!-- 主体区域 结束 -->
</template>

<script>
export default {
  name: 'MainTopRightTop',
  data () {
    return {
      imgIdex: 1
    }
  },
  methods: {
    /**
     * handleTitleClick
     * @description 图片切换
     */
    handleTitleClick (index) {
      this.imgIdex = index
    }
  }
}
</script>

<style scoped lang="scss">
.card-left {
  width: 120px !important;
  padding: 22.5px;
  .card-left-title {
    width: 100%;
    height: 37.5px;
    line-height: 37.5px;
    margin: 5px 0px;
    cursor: pointer;
    border: 1px solid rgb(13, 67, 130);
  }
}
.card-right {
  padding: 37.5px;
  .img {
    height: 100%;
  }
}
</style>
