<!--
 * @Description: 产业化运营大屏内容区域
 * @Author: Pancras
 * @Date: 2022-01-21 01:27:42
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-21 01:28:19
-->
<template>
  <div>
    <!-- 大屏主体 开始 -->
    <keep-alive>
      <component :is="currentMainView"></component>
    </keep-alive>
    <!-- 大屏主体 结束 -->
  </div>
</template>

<script>
import MainContainer1 from './components/main-container1/index.vue'
import MainContainer2 from './components/main-container2/index.vue'
import MainContainer3 from './components/main-container3/index.vue'
import MainContainer4 from './components/main-container4/index.vue'

export default {
  name: 'MainView3',
  components: {
    MainContainer1,
    MainContainer2,
    MainContainer3,
    MainContainer4
  },
  props: {
    menuIndex: {
      default: 1,
      require: true
    }
  },
  data () {
    return {
      currentMainView: 'MainContainer1'
    }
  },
  watch: {
    /**
     * menuIndex
     * @description 监听父组件传递的数据
     */
    menuIndex (val) { // 表单状态
      this.currentMainView = 'MainContainer' + val
    }
  }
}
</script>

<style scoped></style>
