<!--
 * @Description: 下上中主体区域
 * @Author: Pancras
 * @Date: 2022-01-21 01:27:42
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-21 04:17:13
-->
<template>
  <!-- 折线图区域 开始 -->
  <v-chart class="chart" :option="option"/>
  <!-- 折线图区域 结束 -->
</template>

<script>
import option from './option'
export default {
  name: 'MainBottomTopCenterMain',
  data () {
    return {
      option
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
