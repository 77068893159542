<!--
 * @Description: 中下
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-20 21:29:36
-->
<template>
  <div flex="dir:left box:mean">
    <!-- 左1图表 开始 -->
    <div class="label-total" flex="dir:top box:justify">
      <span class="label-total-name">虫情测报仪</span>
      <dv-active-ring-chart :config="option.config1"/>
      <!-- 图表标签 开始 -->
      <div class="label-total-tip" flex="dir:left box:mean">
       <div class="label-item" flex="dir:left">
          <span>关闭</span>
          <div style="background-color: rgb(18,104,133);"></div>
        </div>
        <div class="label-item" flex="dir:left">
          <span>开启</span>
          <div style="background-color: rgb(61, 231, 201);"></div>
        </div>
      </div>
      <!-- 图表标签 结束 -->
    </div>
    <!-- 左1图表 结束 -->
    <!-- 左2图表 开始 -->
     <div class="label-total" flex="dir:top box:justify">
      <span class="label-total-name">土壤墒情仪</span>
      <dv-active-ring-chart :config="option.config2"/>
       <!-- 图表标签 开始 -->
      <div class="label-total-tip" flex="dir:left box:mean">
        <div class="label-item" flex="dir:left">
          <span>关闭</span>
          <div style="background-color: rgb(18,104,133);"></div>
        </div>
        <div class="label-item" flex="dir:left">
          <span>开启</span>
          <div style="background-color: rgb(61, 231, 201);"></div>
        </div>
      </div>
      <!-- 图表标签 结束 -->
    </div>
    <!-- 左3图表 开始 -->
     <div class="label-total" flex="dir:top box:justify">
      <span class="label-total-name">井电控制器</span>
      <dv-active-ring-chart :config="option.config3"/>
      <!-- 图表标签 开始 -->
      <div class="label-total-tip" flex="dir:left box:mean">
        <div class="label-item" flex="dir:left">
          <span>关闭</span>
          <div style="background-color: rgb(18,104,133);"></div>
        </div>
        <div class="label-item" flex="dir:left">
          <span>开启</span>
          <div style="background-color: rgb(61, 231, 201);"></div>
        </div>
      </div>
      <!-- 图表标签 结束 -->
    </div>
    <!-- 左3图表 结束 -->
    <!-- 左4图表 开始 -->
     <div class="label-total" flex="dir:top box:justify">
      <span class="label-total-name">水肥一体机</span>
      <dv-active-ring-chart :config="option.config4"/>
      <!-- 图表标签 开始 -->
      <div class="label-total-tip" flex="dir:left box:mean">
        <div class="label-item" flex="dir:left">
          <span>关闭</span>
          <div style="background-color: rgb(18,104,133);"></div>
        </div>
        <div class="label-item" flex="dir:left">
          <span>开启</span>
          <div style="background-color: rgb(61, 231, 201);"></div>
        </div>
      </div>
      <!-- 图表标签 结束 -->
    </div>
    <!-- 左4图表 结束 -->
    <!-- 左5图表 开始 -->
     <div class="label-total" flex="dir:top box:justify">
      <span class="label-total-name">智能电磁阀</span>
      <dv-active-ring-chart :config="option.config5"/>
      <!-- 图表标签 开始 -->
      <div class="label-total-tip" flex="dir:left box:mean">
        <div class="label-item" flex="dir:left">
          <span>关闭</span>
          <div style="background-color: rgb(18,104,133);"></div>
        </div>
        <div class="label-item" flex="dir:left">
          <span>开启</span>
          <div style="background-color: rgb(61, 231, 201);"></div>
        </div>
      </div>
      <!-- 图表标签 结束 -->
    </div>
    <!-- 左5图表 结束 -->
  </div>
</template>

<script>
import option from './option'
export default {
  name: 'MainBottom',
  data () {
    return {
      option
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.label-total-tip {
height: 22.5px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
        margin-left: 34px;
}
.label-item div{
  width: 36px;margin-left: 7.5px;
}

</style>
