<!--
 * @Description: 智能化决策
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-20 21:56:11
-->
<template>
  <!-- 大屏主体 开始 -->
  <div>
    <keep-alive>
      <component :is="currentMainView"></component>
    </keep-alive>
  </div>
  <!-- 大屏主体 结束 -->
</template>

<script>
import MainContainer1 from './components/main-container1/index.vue'
import MainContainer2 from './components/main-container2/index.vue'

export default {
  name: 'MainView4',
  props: {
    menuIndex: {
      default: 1,
      require: true
    }
  },
  components: {
    MainContainer1,
    MainContainer2
  },
  data () {
    return {
      currentMainView: 'MainContainer1'
    }
  },
  methods: {

  },
  watch: {
    /**
     * menuIndex
     * @description 监听父组件传递的数据
     */
    menuIndex (val) { // 表单状态
      this.currentMainView = 'MainContainer' + val
    }
  }
}
</script>

<style lang="scss"></style>
