<!--
 * @Description: 左下
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-16 20:02:38
-->
<template>
  <!-- 轮播表 开始 -->
  <div>
    <dv-scroll-board :config="option"/>
  </div>
  <!-- 轮播表 结束 -->
</template>

<script>
import option from './option'
export default {
  name: 'AsideLeftBottom',
  data () {
    return {
      option
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
</style>
