<!--
 * @Description: 数据一张图左上下
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-15 13:19:08
-->
<template>
  <!-- 折线图 开始 -->
  <v-chart class="chart" :option="option" />
  <!-- 折线图 结束 -->
</template>

<script>
import option from './option'

export default {
  name: 'AsideLeftTopBottom',
  data () {
    return {
      option
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
</style>
