<!--
 * @Description: 农机管理下左
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-16 20:03:14
-->
<template>
  <dv-border-box-8 :dur="2" style="width:100%;height:100%;">
    <dv-scroll-board :config="configTop" style="width:100%;height:37.5px;"/>
    <dv-scroll-board :config="config" style="width:100%;height:85%;"/>
  </dv-border-box-8>
</template>
<script>
import option from './option'
export default {
  name: 'MainLeft',
  data () {
    return {
      configTop: null,
      config: null,
      option
    }
  },

  mounted () {
    this.tableData()
  },
  methods: {
    /**
     * tableData
     * @description 轮播表样式设置
     */
    tableData () {
      this.configTop = {
        data: this.dataUtil(option.topData),
        rowNum: 1,
        columnWidth: option.columnWidth,
        evenRowBGC: ';border:1px solid #069;' // 偶数行背景色

      }
      this.config = {
        data: this.dataUtil(option.data),
        rowNum: 5,
        columnWidth: option.columnWidth,
        oddRowBGC: ';border:1px solid #069;', // 奇数行背景色
        evenRowBGC: ';border:1px solid #069;' // 偶数行背景色
      }
    },

    /**
     * dataUtil
     * @description 轮播表数据填充加样式
     */
    dataUtil (dataInfo) {
      const tableData = []
      dataInfo.forEach(data => {
        tableData.push([
          '<span style="font-size: 18px;color: #B7E2FF;" >' + data[0] + '</span>',
          '<span style="font-size: 18px;color: #B7E2FF;" >' + data[1] + '</span>',
          '<span style="font-size: 18px;color: #B7E2FF;" >' + data[2] + '</span>',
          '<span style="font-size: 18px;color: #B7E2FF;" >' + data[3] + '</span>',
          '<span style="font-size: 18px;color: #B7E2FF;" >' + data[4] + '</span>',
          '<span style="font-size: 18px;color: #B7E2FF;" >' + data[5] + '</span>'
        ])
      })
      return tableData
    }
  }
}
</script>

<style scoped lang="scss">
</style>
