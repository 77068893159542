/*
 * @Description: 左上图表1配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-21 07:01:49
 */
const config1 = {
  title: {
    text: '分类销售占比',
    left: 'center',
    textStyle: { // 副标题文本样式{"color": "#aaa"}
      fontSize: 18,
      color: '#B7E1FF'
    }
  },
  tooltip: {
    trigger: 'item',
    backgroundColor: 'rgba(84,131,197,0.7)',
    borderColor: 'rgba(84,131,197,0.55)',
    formatter: '{a} <br> {b}: {c} 万亩 ({d}%)',
    textStyle: {
      color: '#B7E1FF',
      fontSize: 18
    }
  },
  legend: {
    top: '85%',
    textStyle: {
      color: '#B7E1FF',
      fontSize: 14
    }
  },
  grid: {
    top: '20%',
    left: '15%',
    right: '5%',
    bottom: '20%'
  },
  series: [
    {
      name: '分类销售占比',
      type: 'pie',
      radius: '50%',
      // 标线的属性设置，以及显示的文字
      itemStyle: {
        normal: {
          label: {
            show: true,
            formatter: '{b}',
            textStyle: {
              color: '#B7E1FF',
              fontSize: 15,
              fontWeight: '300'
            }
          },
          // 标线长度，宽度
          labelLine: {
            show: true,
            length: 7.5,
            lineStyle: {
              width: 2
            }
          }
        },
        emphasis: {
          shadowBlur: 7.5,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      },
      data: [
        { value: 15.4, name: '犁地' },
        { value: 32, name: '耙地' },
        { value: 16.8, name: '播种' },
        { value: 46.1, name: '收获' }
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 7.5,
          shadowOffsetX: 0,
          shadowColor: 'rgb(246,0,0)'
        }
      }
    }
  ]
}
const config2 = {
  title: {
    text: '农产品分类销售占比',
    left: 'center',
    textStyle: { // 副标题文本样式{"color": "#aaa"}
      fontSize: 18,
      color: '#B7E1FF'
    }
  },
  tooltip: {
    trigger: 'item',
    backgroundColor: 'rgba(84,131,197,0.7)',
    borderColor: 'rgba(84,131,197,0.55)',
    formatter: '{a} <br> {b}: {c} 吨 ({d}%)',
    textStyle: {
      color: '#B7E1FF',
      fontSize: 18
    }
  },
  legend: {
    top: '85%',
    textStyle: {
      color: '#B7E1FF',
      fontSize: 14
    }
  },
  grid: {
    top: '20%',
    left: '15%',
    right: '5%',
    bottom: '20%'
  },
  series: [
    {
      name: '农产品分类销售占比',
      type: 'pie',
      radius: '50%',
      // 标线的属性设置，以及显示的文字
      itemStyle: {
        normal: {
          label: {
            show: true,
            formatter: '{b}',
            textStyle: {
              color: '#B7E1FF',
              fontSize: 15,
              fontWeight: '300'
            }
          },
          // 标线长度，宽度
          labelLine: {
            show: true,
            length: 7.5,
            lineStyle: {
              width: 2
            }
          }
        },
        emphasis: {
          shadowBlur: 7.5,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      },
      data: [
        { value: 1, name: '棉花' },
        { value: 0.21, name: '香梨' },
        { value: 0.02, name: '红枣' }
      ],
      emphasis: {
        itemStyle: {
          shadowBlur: 7.5,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
}
export default {
  config1,
  config2
}
