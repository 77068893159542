<!--
 * @Description: 侧右中
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-23 12:10:46
-->
<template>
  <!-- 折线图 开始 -->
  <v-chart class="chart" :option="option" />
  <!-- 折线图 结束 -->
</template>

<script>
import option from './option'
export default {
  name: 'AsideRightMiddle',
  data () {
    return {
      option
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss"></style>
