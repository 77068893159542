<!--
 * @Description: 数字化管理-自动化管理
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-21 05:50:48
 * @LastEditTime: 2022-01-23 22:31:26
-->
<template>
  <!-- 布局容器 开始 -->
  <div class="container"
       flex="box:justify">
    <!-- 左侧容器 开始 -->
    <div flex="dir:top box:last"
         class="aside-left">
      <div class="aside-left-top"
           flex="dir:top box:first">
        <div class="aside-left-top-title title link"
             @click="goUrl ('http://39.96.170.117:89/xxdz/loginXX.html')">
          农机管理数据·
        </div>
        <div class="aside-left-top-main"
             flex="dir:top box:first">
          <left-top-top class='aside-left-top-main-top'></left-top-top>
          <left-top-bottom class="aside-left-top-main-bottom"></left-top-bottom>
        </div>
      </div>
      <div class="aside-left-bottom"
           flex="dir:top box:first">
        <div class="aside-left-bottom-title title link"
             @click="goUrl ('http://161.189.124.231:8081/lvzhoutuoling/#/report?id=123')">
          监测数据列表·</div>
        <left-bottom class="aside-left-bottom-main"></left-bottom>
      </div>
    </div>
    <!-- 左侧容器 结束 -->
    <!-- 中间容器 开始 -->
    <div class="main"
         flex="dir:top box:last">
      <div class="main-top">
        <main-top></main-top>
      </div>
      <div flex="dir:top box:first"
           class="main-bottom">
        <div class="main-bottom-title title link"
             @click="goUrl ('http://yypt.kenyte.com/itect/devMgr')">
          设备数据监测·</div>
        <main-bottom class="main-bottom-main"></main-bottom>
      </div>
    </div>
    <!-- 中间容器 结束 -->
    <!-- 右侧容器 开始 -->
    <div flex="dir:top box:last"
         class="aside-right">
      <div class="aside-right-top"
           flex="dir:top box:first">
        <div class="aside-right-top-title title link"
             @click="goUrl ('http://yypt.kenyte.com/source/traceabilityList')">
          溯源·</div>
        <div class="aside-right-top-main"
             flex="dir:top box:first">
          <right-top-top class="aside-right-top-main-top"></right-top-top>
          <right-top-bottom class="aside-right-top-main-bottom"></right-top-bottom>
        </div>
      </div>
      <div class="aside-right-bottom link"
           flex="dir:top box:first">
        <div class="aside-right-bottom-title title link"
             @click="goUrl ('http://gxjs.kenyte.com/data-view/homePage')">
          鉴定/检测数据·</div>
        <right-bottom class="aside-right-bottom-main"></right-bottom>
      </div>
    </div>
    <!-- 右侧容器 结束 -->
  </div>
  <!-- 布局容器 结束 -->
</template>

<script>
import LeftTopTop from './components/left-top-top/index.vue'
import LeftTopBottom from './components/left-top-bottom/index.vue'
import LeftBottom from './components/left-bottom/index.vue'
import MainTop from './components/main-top/index.vue'
import MainBottom from './components/main-bottom/index.vue'
import RightTopTop from './components/right-top-top/index.vue'
import RightTopBottom from './components/right-top-bottom/index.vue'
import RightBottom from './components/right-bottom/index.vue'

export default {
  name: 'MainAnalyse',
  props: {
    menuIndex: {
      default: 1,
      require: true
    }
  },
  components: {
    LeftTopTop,
    LeftTopBottom,
    LeftBottom,
    MainTop,
    MainBottom,
    RightTopTop,
    RightTopBottom,
    RightBottom
  },
  data () {
    return {
      currentMainView: 'MainContainer1'
    }
  },
  methods: {
    /**
     * goUrl
     * @description 跳转地址
     */
    goUrl (url) {
      window.open(
        url,
        '_blank'
      )
    }
  },
  watch: {
    /**
     * menuIndex
     * @description 监听父组件传递的数据
     */
    menuIndex (val) { // 表单状态
      this.currentMainView = 'MainContainer' + val
    }
  }
}
</script>

<style lang="scss" scoped>
// 注册样式
@import "~@/assets/style/main-view2.scss";
</style>
