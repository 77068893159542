<!--
 * @Description: 上右下内容区域
 * @Author: Pancras
 * @Date: 2022-01-14 15:52:23
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-22 12:34:13
-->
<template>
<!-- 主体内容区域 开始 -->
  <div>
    <div class="irrigate_top">
      <div class="centerList">
        <div class="centerListFont">累计灌溉水量（m2）</div>
        <div class="centerListNum">
          <span class="">23678</span>
        </div>
      </div>
      <div class="centerList">
        <div class="centerListFont">灌溉压力（MPa）</div>
        <div class="centerListNum">
          <span class="">0.29</span>
        </div>
      </div>
    </div>
    <div class="irrigate_bottom">
      <div class="every_line">
        <span>当前灌溉流量（m²/h）</span>
        <i class="">0.78</i>
      </div>
      <div class="every_line">
        <span>当前灌溉阀门数量（个）</span>
        <i class="counter-value">49</i>
      </div>
      <div class="every_line">
        <span>田边水池液位（L）</span>
        <i>2.30</i>
      </div>
    </div>
  </div>
  <!-- 主体内容区域 结束 -->
</template>

<script>
export default {
  name: 'MainTopRightBottom',
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
/* 灌溉数据start--- */
.centerList {
  /* float: left; */
  display: inline-block;
  width: 37%;
  height: 80%;
  padding-top: 3%;
  text-align: center;
  margin-left: 2vw;
}

.centerListFont {
  font-size: 18px;
  color: #0efcff;
}

.centerListNum {
  font-size: 18px;
  color: white;
  margin-top: 0.2vw;
}
.irrigate_bottom {
  width: 100%;
  height: 50%;
  margin-top: 3%;
}
.irrigate_bottom .every_line {
  width: 90%;
  height: 25%;
  margin-left: 5%;
  margin-bottom: 3%;
  padding-top: 1%;
  background-color: rgba(11, 50, 87, 0.9);
}
.every_line span {
  color: #0efcff;
  margin-left: 0.7vw;
  font-size: 18px;
  text-align: left;
  float: left;
}
.every_line i {
  color: #fff;
  float: right;
  margin-right: 0.8vw;
  font-size: 18px;
}
/* 灌溉数据over--- */
</style>
