/*
 * @Description: 中右 柱状加折线图配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
// eslint-disable-next-line camelcase
import { color } from 'echarts'

// 初始填充数据
// eslint-disable-next-line camelcase
const plan_xAxis = [2017]
// eslint-disable-next-line camelcase
const plan_data1 = [9.7]
// eslint-disable-next-line camelcase
const plan_data2 = [9.7]
// 高标准农田建设数据 year: 年份, line: 完成建设规模/万亩数据, bar: 计划建设规模/万亩数据
export const newShowData = [
  {
    year: 2017,
    line: 9.7,
    bar: 5.883
  },
  {
    year: 2018,
    line: 1.5,
    bar: 1.5
  },
  {
    year: 2019,
    line: 26,
    bar: 26
  },
  {
    year: 2020,
    line: 14,
    bar: 14
  },
  {
    year: 2021,
    line: 16,
    bar: 16
  },
  {
    year: 2022,
    line: 20,
    bar: 20
  }
]

// 柱状加折线图配置信息
export const optionConfig = {
  title: { show: false },
  toolbox: { show: false },
  legend: {
    top: 'top',
    textStyle: {
      color: '#B7E2FF',
      fontSize: 18,
      fontFamily: '微软雅黑'
    },
    data: ['计划建设规模/万亩', '完成建设规模/万亩']
  },
  grid: {
    top: '25%',
    left: '10%',
    right: '5%',
    bottom: '12%'
  },
  xAxis: {
    data: plan_xAxis,
    max: 5,
    axisLabel: {
      interval: 0,
      textStyle: {
        color: '#B7E1FF',
        fontSize: 18
      }
    },
    axisLine: {
      lineStyle: {
        color: '#09F'
      }
    }
  },
  yAxis: {
    name: '万亩',
    nameTextStyle: {
      padding: [0, 0, 10, -45], // 四个数字分别为上右下左与原位置距离
      fontSize: 18,
      color: '#B7E1FF'
    },
    inverse: false,
    splitArea: { show: false }, // Y轴横向背景
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: {
      textStyle: { // Y轴字体
        color: '#B7E1FF',
        fontSize: 18,
        fontFamily: 'Arial'
      }
    },
    splitLine: { // Y轴横向轴线
      lineStyle: {
        color: '#09F'
      }
    }
  },
  tooltip: {
    trigger: 'axis',
    backgroundColor: 'rgba(84,131,197,0.7)',
    borderColor: 'rgba(84,131,197,0.55)',
    textStyle: {
      color: '#ffffff',
      fontSize: 18
    }
  },
  series: [
    {
      name: '计划建设规模/万亩',
      type: 'bar',
      itemStyle:
      {
        normal: { color: color[1] },
        emphasis: { color: color[2] }
      },
      barWidth: 40,
      data: plan_data1
    },
    {
      name: '完成建设规模/万亩',
      type: 'line',
      itemStyle: {
        normal: {
          color: '#F90',
          label: {
            show: true,
            position: 'top',
            textStyle: {
              color: '#CCC',
              fontSize: 18
            }
          },
          lineStyle: {
            color: '#F90',
            width: 3
          }
        },
        emphasis: {
          color: '#FF0'
        }
      },
      symbolSize: 18,
      data: plan_data2
    }
  ]
}

export default {
  optionConfig,
  newShowData
}
