<!--
 * @Description: 上右
 * @Author: Pancras
 * @Date: 2022-01-21 01:27:42
 * @LastEditors: Pancras
 * @LastEditTime: 2023-01-09 12:27:47
-->
<template>
  <div flex="box:mean">
    <!--  左一-->
    <div class="card"
         flex="box:first cross:center">
      <img class="img2"
           src="/image/main-view3/monthuser2.png" />
      <div class="card-main">
        <!-- <div class="card-main-title">月活跃用户</div>
        <div class="card-main-count">{{ option.countList[0] }}</div> -->
        <div class="card-main-title">成交笔数</div>
        <div class="card-main-count">12573笔</div>
      </div>
    </div>
    <!--  左二-->
    <div class="card"
         flex="box:first cross:center">
      <img class="img"
           src="/image/main-view3/dayuser.png" />
      <div class="card-main">
        <!-- <div class="card-main-title">日活跃用户</div>
        <div class="card-main-count">{{ option.countList[1] }}</div> -->
        <div class="card-main-title">月活跃用户</div>
        <div class="card-main-count">6038人</div>
      </div>
    </div>
  </div>
</template>

<script>
import option from './option'
export default {
  name: 'MainTopRight',
  data () {
    return {
      option
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  text-align: left;
  .img {
    width: 50px;
    height: 50px;
  }

  .img2 {
    width: 43px;
    height: 43px;
  }
  .card-main {
    margin-left: 22.5px;
    .card-main-title {
      font-size: 12px;
    }
    .card-main-count {
      font-size: 27px;
      color: rgb(216, 202, 6);
    }
  }
}
</style>
