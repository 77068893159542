export default {
  config: {
    radius: '60%',
    activeRadius: '70%',
    lineWidth: 52.5,
    digitalFlopStyle: {
      fontSize: 18
    },
    color: [
      'rgb(0,149,255)',
      'rgb(246,0,234)',
      'rgb(255,221,0)',
      'rgb(0,255,255)',
      'rgb(61, 231, 201)'
    ],
    data: [
      {
        name: '农业',
        value: 23178
      },
      {
        name: '软件',
        value: 20134
      },
      {
        name: '工业',
        value: 15691
      },
      {
        name: '制造业',
        value: 10062
      },
      {
        name: '其他产业',
        value: 9535
      }
    ]
  },
  configNum1: {
    number: [72],
    content: '{nt}个'
  },
  configNum2: {
    number: [16],
    content: '{nt}个'
  },
  configNum3: {
    number: [57],
    content: '{nt}个'
  },
  configNum4: {
    number: [338],
    content: '{nt}个'
  },
  configNum5: {
    number: [76],
    content: '{nt}个'
  },
  configColumn: {
    data: [
      {
        name: '新疆农业大学',
        value: 62
      },
      {
        name: '石河子大学',
        value: 45
      },
      {
        name: '新疆大学',
        value: 78
      },
      {
        name: '昌吉学院',
        value: 38
      },
      {
        name: '塔里木大学',
        value: 67
      },
      {
        name: '新疆财经大学',
        value: 34
      }
    ],
    unit: '项',
    showValue: true
  }
}
