/**
 * dataUtil
 * @description 图表数据填充加样式
 */
function dataUtil (dataInfo) {
  const tableData = []
  dataInfo.forEach(data => {
    tableData.push([
      '<span style="font-size: 18px;color: #B7E2FF;" >' + data[0] + '</span>',
      '<span style="font-size: 18px;color: #B7E2FF;" >' + data[1] + '</span>',
      '<span style="font-size: 18px;color: #B7E2FF;" >' + data[2] + '</span>',
      '<span style="font-size: 18px;color: #B7E2FF;" >' + data[3] + '</span>',
      '<span style="font-size: 18px;color: #B7E2FF;" >' + data[4] + '</span>'
    ])
  })
  return tableData
}

const topData = [['高校ID', '高校名称', '科研成果', '科目分类', '孵化状态']]

const data = [
  ['45315', '新疆农业大学', '关于智慧农业网络安全问题', '农业', '推广中'],
  ['21559', '石河子大学', '关于智慧农业网络安全问题', '农业', '推广中'],
  ['45315', '新疆农业大学', '关于智慧农业网络安全问题', '农业', '推广中'],
  ['21559', '石河子大学', '关于智慧农业网络安全问题', '农业', '孵化中'],
  ['45315', '新疆农业大学', '关于智慧农业网络安全问题', '农业', '孵化中'],
  ['21559', '石河子大学', '关于智慧农业网络安全问题', '农业', '孵化中'],
  ['45315', '新疆农业大学', '关于智慧农业网络安全问题', '农业', '推广中'],
  ['21559', '石河子大学', '关于智慧农业网络安全问题', '农业', '孵化中'],
  ['45315', '新疆农业大学', '关于智慧农业网络安全问题', '农业', '孵化中'],
  ['21559', '石河子大学', '关于智慧农业网络安全问题', '农业', '推广中'],
  ['45315', '新疆农业大学', '关于智慧农业网络安全问题', '农业', '推广中'],
  ['21559', '石河子大学', '关于智慧农业网络安全问题', '农业', '孵化中'],
  ['45315', '新疆农业大学', '关于智慧农业网络安全问题', '农业', '推广中'],
  ['21559', '石河子大学', '关于智慧农业网络安全问题', '农业', '孵化中'],
  ['45315', '新疆农业大学', '关于智慧农业网络安全问题', '农业', '孵化中'],
  ['21559', '石河子大学', '关于智慧农业网络安全问题', '农业', '孵化中'],
  ['45315', '新疆农业大学', '关于智慧农业网络安全问题', '农业', '推广中'],
  ['21559', '石河子大学', '关于智慧农业网络安全问题', '农业', '孵化中'],
  ['45315', '新疆农业大学', '关于智慧农业网络安全问题', '农业', '孵化中'],
  ['21559', '石河子大学', '关于智慧农业网络安全问题', '农业', '推广中'],
  ['45315', '新疆农业大学', '关于智慧农业网络安全问题', '农业', '推广中'],
  ['21559', '石河子大学', '关于智慧农业网络安全问题', '农业', '孵化中'],
  ['45315', '新疆农业大学', '关于智慧农业网络安全问题', '农业', '推广中'],
  ['21559', '石河子大学', '关于智慧农业网络安全问题', '农业', '推广中'],
  ['45315', '新疆农业大学', '关于智慧农业网络安全问题', '农业', '孵化中'],
  ['21559', '石河子大学', '关于智慧农业网络安全问题', '农业', '推广中']
]

export default {
  /**
   * tableData
   * @description 图表样式设置
   */
  tableData: [
    {
      data: dataUtil(topData),
      rowNum: 1,
      evenRowBGC: 'rgba(84,131,197,0.83)', // 偶数行背景色
      columnWidth: ['100', '120', '320', '80', '100']
    },
    {
      data: dataUtil(data),
      rowNum: 15,
      oddRowBGC: 'rgba(84,131,197,0.29)', // 奇数行背景色
      evenRowBGC: '', // 偶数行背景色
      columnWidth: ['100', '120', '', '80', '100']
    }
  ]
}
