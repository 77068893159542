/*
 * @Description: 中左 饼图配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2022-01-14 23:03:21
 */
const data = [
  {
    name: '林地',
    value: 32
  },
  {
    name: '耕地',
    value: 181
  },
  {
    name: '草地',
    value: 186
  },
  {
    name: '基本农田',
    value: 89
  },
  {
    name: '其他用地',
    value: 141
  }
]
export default {
  legend: {
    top: 'center',
    orient: 'vertical',
    left: 'left',
    textStyle: {
      color: '#B7E1FF',
      fontSize: 18
    }
  },
  tooltip: {
    trigger: 'item',
    formatter: '{b} : {c}万亩 ({d}%)',
    backgroundColor: 'rgba(84,131,197,0.7)',
    borderColor: 'rgba(84,131,197,0.55)',
    textStyle: {
      color: '#B7E1FF',
      fontSize: 18
    }
  },
  series: [
    {
      type: 'pie',
      radius: '80%',
      data: data,
      // 折现样式
      label: {
        fontStyle: 'normal',
        formatter: '{b}：{c}万亩',
        color: '#B7E1FF',
        fontSize: 20,
        fontWeight: '300'
      },
      labelLine: {
        show: true,
        lineStyle: {
          width: 2
        }
      },
      left: '20%'
    }
  ]
}
