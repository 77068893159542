/*
 * @Description: 侧右上 柱图配置
 * @Author: Pancras
 * @Date: 2022-01-14 21:34:51
 * @LastEditors: Pancras
 * @LastEditTime: 2023-01-09 17:17:05
 */
import { color } from 'echarts'
// 农机补贴
// eslint-disable-next-line camelcase
const class_xAxis = ['2019', '2020', '2021', '2022'] // 列标
// eslint-disable-next-line camelcase
const class_data = [11.79, 12.5, 13.3, 13.4] // 柱图1数据
// eslint-disable-next-line camelcase
const class_data1 = [38.15, 39.7, 40.26, 42.3] // 柱图2数据
// eslint-disable-next-line camelcase
const class_data3 = [1.24, 1.44, 1.49, 2.55] // 柱图2数据
const classType = ['棉花', '香梨', '肉类'] // 图例
export default {
  title: { show: false },
  toolbox: { show: false },
  legend: {
    top: 'top',
    textStyle: {
      color: '#B7E2FF',
      fontSize: 15,
      fontFamily: '微软雅黑'
    },
    data: classType
  },
  grid: {
    top: '20%',
    left: '10%',
    right: '5%',
    bottom: '15%'
  },
  xAxis: {
    type: 'category',
    boundaryGap: true,
    data: class_xAxis, // 数据
    axisLabel: {
      interval: 0,
      textStyle: {
        color: '#B7E1FF',
        fontSize: 13.5
      }
    },
    axisLine: {
      lineStyle: {
        color: '#09F'
      }
    }
  },
  yAxis: {
    inverse: false,
    name: '万吨',
    nameTextStyle: {
      padding: [0, 0, 0, -50], // 四个数字分别为上右下左与原位置距离
      fontSize: 13.5,
      color: '#B7E1FF'
    },
    splitArea: { show: false },
    axisLine: { show: false },
    axisTick: { show: false },
    axisLabel: {
      textStyle: {
        color: '#B7E1FF',
        fontSize: 18,
        fontFamily: 'Arial'
      }
    },
    splitLine: {
      lineStyle: {
        color: '#09F'
      }
    }
  },
  tooltip: {
    trigger: 'axis',
    backgroundColor: 'rgba(84,131,197,0.7)',
    borderColor: 'rgba(84,131,197,0.55)',
    textStyle: {
      color: '#ffffff',
      fontSize: 18
    }
  }, // 提示框
  series: [
    {
      name: classType[0],
      type: 'bar',
      stack: 'one',
      itemStyle:
      {
        normal: { color: color[1] }
      },
      barWidth: 30,
      data: class_data
    },
    {
      name: classType[1],
      type: 'bar',
      stack: 'two',
      barGap: '0%',
      itemStyle:
      {
        normal: { color: color[1] }
      },
      barWidth: 30,
      data: class_data1
    },
    {
      name: classType[2],
      type: 'bar',
      stack: 'three',
      barGap: '0%',
      itemStyle:
      {
        normal: { color: color[1] }
      },
      barWidth: 30,
      data: class_data3
    }
  ]
}
